import { Component, OnInit } from "@angular/core";
import { AuthService } from "src/app/Common/authentication/auth.service/auth.service";
import { DatePipe } from "@angular/common";
import { filterData } from "./model";
import Swal from "sweetalert2";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
@Component({
  selector: "app-pro-man-approved-show",
  templateUrl: "./pro-man-approved-show.component.html",
  styleUrls: ["./pro-man-approved-show.component.scss"],
})
export class ProManApprovedShowComponent implements OnInit {
  date = new Date();
  constructor(private authServce: AuthService, private datePipe: DatePipe,private modalService: NgbModal,
    ) {}

  data: any;
  allUser: any;
  projectName: any;
  emptyData = false;
  loader:boolean;
  pageIndex=1;
  pageSize=50;
  hidePreviousButton: boolean;
  hideNextButton: boolean;
  ngOnInit(): void {
    // this.authServce.getProManApprovedTimesheet().subscribe((data: any) => {
    //   this.data = data;
    //   console.log(this.data);
    //   if (data.length == 0) {
    //     this.emptyData = true;
    //   }
    // });
    this.TableShow(this.pageIndex,this.pageSize)

    this.authServce.getAllUser().subscribe((data: any) => {
      this.allUser = data;
      console.log(this.allUser, "all users");
    });

    this.authServce.getAllProjectName().subscribe((data: any) => {
      console.log(data);
      this.projectName = data;
    });
  }

  DropdownSubModule: any;
  ngmodulename="";
  ngsubmodulename="";
  ngprojectname="";
  

  ModuleOptionSelected(selectedValue: any) {
    this.validationSubmodule=false;
    console.log("submodulename", this.ngsubmodulename);
    this.ngsubmodulename = "";
    console.log("submodulename", this.ngsubmodulename);
    this.authServce.getSubModule(selectedValue).subscribe((data: any) => {
      this.DropdownSubModule = data;
      console.log(this.DropdownSubModule);
    });
  }

  DropdownModule: any;
  ProjectOptionSelected(selectedValue: any) {
    this.validationModule=false
    console.log("modulename", this.ngmodulename);
    this.ngmodulename = "";
    console.log("modulename", this.ngmodulename);
    this.authServce.getModuleName(selectedValue).subscribe((data: any) => {
      this.DropdownModule = data;
      console.log(this.DropdownModule);
      console.log(selectedValue);
    });
  }

  validationModule=false;
  validationSubmodule=false;
  validationForModule(){
    if(this.ngprojectname==''){
      this.validationModule=true
      console.log('validation',this.ngmodulename);
    }
    else if(this.ngprojectname!='')
    this.validationModule=false;
  }

  validationForSubmodule(){
    if(this.ngmodulename==''){
      this.validationSubmodule=true
      console.log('validation',this.ngsubmodulename);
    }
    else if(this.ngmodulename!='')
    this.validationSubmodule=false
  }

  filterdata: filterData;
  userId = "";
  projectId = "";
  from = "";
  to = "";
  filterTable: any;
  isStatus="";
  Filter() {
    this.loader=true;
    this.pageIndex=1;
    this.emptyData=false;
    this.filterdata = {
      ProjectId: this.ngprojectname,
      From: this.from,
      To: this.to,
      UserId: this.userId,
      ModuleId:this.ngmodulename,
      SubModuleId:this.ngsubmodulename,
      Is_Status:this.isStatus,
      PageSize: this.pageSize,
      PageIndex: this.pageIndex,
    };
    console.log("filterdata", this.filterdata);
    this.authServce
      .getProManTimesheetHistoryFilter(this.filterdata)
      .subscribe((data: any) => {
        this.loader=false;
        this.filterTable = data;
        console.log(data)
        console.log(data.length,'data length')
        console.log(this.filterTable, "filter data");
        if (data.length==0) {
          console.log("if condition");
          this.emptyData = true;
          this.hideNextButton = true;
          this.hidePreviousButton = true;
        } else if (data != "") {
          this.emptyData = false;
          this.hideNextButton = false;
          this.hidePreviousButton = false;
        }
        if (this.pageIndex == 1  && data != "") {
          this.hidePreviousButton = true;
          this.hideNextButton = false;
        } else if (this.pageIndex != 1 && data == "") {
          this.hideNextButton = true;
          this.hidePreviousButton = false;
        }
      });
  }

  TableShow(pageIndex, pageSize) {
    this.loader=true;
    this.emptyData=false;

    this.filterdata = {
      ProjectId: this.ngprojectname,
      From: this.from,
      To: this.to,
      UserId: this.userId,
      ModuleId:this.ngmodulename,
      SubModuleId:this.ngsubmodulename,
      Is_Status:this.isStatus,
      PageSize: pageSize,
      PageIndex: pageIndex,      
    };
    console.log("filterdata", this.filterdata);
    this.authServce
      .getProManTimesheetHistoryFilter(this.filterdata)
      .subscribe((data: any) => {
        this.loader=false;
        this.filterTable = data;
        console.log(data)
        console.log(data.length,'data length')
        console.log(this.filterTable, "filter data");
        if (data.length==0) {
          console.log("if condition");
          this.emptyData = true;
          this.hideNextButton = true;
          this.hidePreviousButton = true;
        } else if (data != "") {
          this.emptyData = false;
          this.hideNextButton = false;
          this.hidePreviousButton = false;
        }
        if (this.pageIndex == 1  && data != "") {
          this.hidePreviousButton = true;
          this.hideNextButton = false;
        } else if (this.pageIndex != 1 && data == "") {
          this.hideNextButton = true;
          this.hidePreviousButton = false;
        } 
      });
  }

  next() {
    this.pageIndex = this.pageIndex + 1;
    this.TableShow(this.pageIndex, this.pageSize);
  }

  previous() {
    this.pageIndex = this.pageIndex - 1;
    this.TableShow(this.pageIndex, this.pageSize);
  }
  ClearFilter() {
    this.emptyData = false;
    this.to = "";
    this.from = "";
    this.projectId = "";
    this.userId = "";
    this.ngprojectname='';
    this.ngsubmodulename="";
    this.ngmodulename="";
    this.Filter();
    this.validationModule=false;
    this.validationSubmodule=false;
  }

  page = 1;
  count = 0;
  tableSize = 10;
  tableSizes = [3, 6, 9, 12];

  onTableDataChange(event) {
    this.page = event;
    // this.TimesheetTable();
  }

  popupViewDetails(content: any) {
    this.modalService.open(content, { size: "md", centered: true });
  }

  element:any;
  days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  viewDetailsforFilterData(id){
    console.log(id,'filter id')
    this.filterTable.forEach(elements => {
      if(id==elements.timeSheetId){
          this.element=elements;
          console.log(this.element,'reimbursement details for filter data')
      }
    });
  }
}
