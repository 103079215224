<div class="main">
  <div class="vertical-menu">
    <div data-simplebar="init" class="h-100">
      <div class="simplebar-wrapper" style="margin: 0px">
        <div class="simplebar-height-auto-observer-wrapper">
          <div class="simplebar-height-auto-observer"></div>
        </div>
        <div class="simplebar-mask">
          <div class="simplebar-offset" style="right: 0px; bottom: 0px">
            <div
              class="simplebar-content-wrapper"
              style="height: 100%; overflow: hidden scroll"
            >
              <div class="simplebar-content" style="padding: 0px">
                <div>
                  <div id="sidebar-menu">
                    <!-- employee sidebar start -->
                    <ul class="metismenu list-unstyled" *ngIf="Employee">
                      <li class="menu-title h2">Menu</li>

                      <div *ngIf="showValue2">
                        <li *ngFor="let value of empMenulists">
                          <a
                            class="side-nav-link-ref"
                            [routerLink]="'../' + value.Link"
                            ><i class="{{ value.icon }}"></i
                            ><span> {{ value.text }}</span></a
                          >
                          <br />
                        </li>
                        <br />
                      </div>
                    </ul>

                    <!-- employee sidebar ends -->
                    <!-- project manager sidebar start -->
                    <ul class="metismenu list-unstyled" *ngIf="Manager">
                      <li class="menu-title h2" >As Employee</li>
                      <div *ngIf="showValue2" >
                        <li *ngFor="let value of proManMenulists" (click)="onSelect()"  >
                          <a 
                            [routerLink]="'../' + value.Link"
                            ><i  class="{{ value.icon }}"></i
                            ><span [ngClass]="{'selected':true}" > {{ value.text }}</span></a
                          >
                          <br />
                        </li>

                        <li class="menu-title h2">As Manager</li>
                        <li>
                          <a
                            (click)="ShowManageTimesheet()"
                            class="side-nav-link-ref"
                          >
                            <i class="bx bx-calendar"></i>
                            <span>Manage Time Sheet </span></a
                          >
                          <ul *ngIf="showManageTimesheet" >
                            <li class="iconMargin">
                              <a
                                class="side-nav-link-ref"
                                [routerLink]="['../home/project-manager-timesheeet-request']"
                              >
                              <i class='bx bxs-book-open' ></i>
                                <span>Requests </span></a
                              >
                            </li>
                            <li class="iconMargin">
                              <a
                                class="side-nav-link-ref"
                                [routerLink]="['../home/project-manager-timesheet-history']"
                              >
                              <i class='bx bx-history'></i>
                                <span>History </span></a
                              >
                            </li>
                          </ul>
                        </li>
                        <br />

                        <li>
                          <a
                            (click)="ShowManageReimbursement()"
                            class="side-nav-link-ref"
                            ><i class="bx bx-rupee"></i
                            ><span>Manage Reimbursement </span></a
                          >
                          <ul *ngIf="showManageReimbursement">
                            <li class="iconMargin">
                              <a
                                class="side-nav-link-ref"
                                [routerLink]="['../home/project-manager-reimbursement-request']"
                              >
                              <i class='bx bxs-book-open' ></i>
                                <span>Requests </span></a
                              >
                            </li>
                            <li class="iconMargin">
                              <a
                                class="side-nav-link-ref"
                                [routerLink]="['../home/project-manager-reimbursement-history']"
                              >
                              <i class='bx bx-history'></i>
                                <span>History </span></a
                              >
                            </li>
                          </ul>
                        </li>
                        <br />
                        <li>
                          <a
                            (click)="ShowManageLeave()"
                            class="side-nav-link-ref"
                            ><i class="bx bxs-user-x"></i
                            ><span>Manage Leave/WFH </span></a
                          >
                          <ul *ngIf="showManageLeave">
                            <li class="iconMargin">
                              <a
                                class="side-nav-link-ref"
                                [routerLink]="['../home/project-manager-leave-request']"
                              >
                              <i class='bx bxs-book-open' ></i>
                                <span>Requests</span></a
                              >
                            </li>
                            <li class="iconMargin">
                              <a
                                class="side-nav-link-ref"
                                [routerLink]="['../home/project-manager-leave-history']"
                              >
                              <i class='bx bx-history'></i>
                                <span>History</span></a
                              >
                              
                            </li>
                          </ul>
                        </li>

                        <br />
                      </div>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
