<!-- <div *ngIf="loader">
  <app-loader></app-loader>
</div> -->

<div class="container-fluid">
  <h3 class="text-center mb-3 mt-1">Leave / Work Form Home</h3>
  <div class="d-flex justify-content-around row mb-4 g-3">
    <div class="col-xxl-2 col-lg-2 col-md-4 col-sm-4">
      <select class="form-control select2" [(ngModel)]="typeOnFilter">
        <option value="" disabled selected>Type</option>
        <option [ngStyle]="{ color: 'red' }" value="Casual">
          Casual Leave
        </option>
        <option [ngStyle]="{ color: 'red' }" value="Sickleave">
          Sick Leave
        </option>
        <option [ngStyle]="{ color: 'red' }" value="HalfDaySick">
          Half-day Sick Leave
        </option>
        <option [ngStyle]="{ color: 'red' }" value="HalfDay">
          Half-day Casual Leave
        </option>
        <option [ngStyle]="{ color: 'green' }" value="WFH">
          Work From Home
        </option>
      </select>
    </div>
    <div class="col-xxl-2 col-lg-2 col-md-4 col-sm-4">
      <div id="datepicker1">
        <input
          class="form-control"
          type="text"
          id="example-date-input"
          placeholder="From"
          onfocus="(this.type='date')"
          [(ngModel)]="dateFrom"
        />
      </div>
    </div>
    <div class="col-xxl-2 col-lg-2 col-md-4 col-sm-4">
      <div id="datepicker1">
        <input
          class="form-control"
          type="text"
          id="example-date-input"
          placeholder="To"
          onfocus="(this.type='date')"
          [(ngModel)]="dateTo"
          onfocus="(this.type='date')"
        />
      </div>
    </div>
    <div class="col-xxl-2 col-lg-2 col-md-4 col-sm-4">
      <button (click)="Filter()" type="button" class="btn btn-secondary w-100">
        <i class="mdi mdi-filter-outline align-middle"></i> Filter
      </button>
    </div>
    <div class="col-xxl-2 col-lg-2 col-md-4 col-sm-4">
      <button
        (click)="ClearFilter()"
        type="button"
        class="btn btn-secondary w-100"
      >
        <i class=""></i>Clear Filter
      </button>
    </div>

    <div class="col-xxl-2 col-lg-2 col-md-4 col-sm-4">
      <button
        type="button"
        class="btn btn-success btn-rounded"
        (click)="leavePopup(content)"
      >
        <i class="mdi mdi-plus mr-1"></i> Apply Leave / WFH
      </button>
    </div>
    <br />
  </div>
</div>

<div class="row g-3">
  <div class="col-md-9">
    <div *ngIf="!emptyData" class="table-responsive">
      <table *ngIf="!loader" class="table table-bordered align-middle nowrap">
        <thead>
          <tr class="text-center">
            <th scope="col">No.</th>
            <th scope="col">On leave</th>
            <th scope="col">Requested On</th>
            <th scope="col">Type</th>
            <th scope="col">Remarks</th>
            <th scope="col">Status</th>
            <th scope="col">Action</th>
          </tr>
        </thead>

        <tbody>
          <tr *ngFor="let data of filterData; index as i" class="text-center">
            <td scope="row">
              {{ this.pageSize * this.pageIndex - this.pageSize + i + 1 }}
            </td>
            <td>
              <h5>
                <span class="badge bg-dark">{{
                  data.dateOfLeave | date : "dd-MMM-yyyy"
                }}</span>
              </h5>
            </td>
            <td>{{ data.dateOfRequest | date : "dd-MMM-yyyy" }}</td>

            <td>
              <h5>
                <span
                  class="badge badge-soft-{{ data.type_color }}"
                  [ngClass]="{
                    'bg-success': data.typeOfLeave == 'WFH',
                    'bg-danger':
                      data.typeOfLeave == 'Sickleave' ||
                      data.typeOfLeave == 'HalfDaySick' ||
                      data.typeOfLeave == 'Casual' ||
                      data.typeOfLeave == 'HalfDay'
                  }"
                >
                  <span *ngIf="data.typeOfLeave == 'Sickleave'">
                    Sick Leave</span
                  >
                  <span *ngIf="data.typeOfLeave == 'HalfDaySick'">
                    Half-day Sick Leave</span
                  >
                  <span *ngIf="data.typeOfLeave == 'HalfDay'">
                    Half-day Casual Leave</span
                  >
                  <span *ngIf="data.typeOfLeave == 'Casual'">
                    Casual Leave</span
                  >
                  <span *ngIf="data.typeOfLeave == 'WFH'"> Work From Home</span>
                </span>
              </h5>
            </td>
            <td>
              <span *ngIf="data.remarks != ''">{{ data.remarks }}</span>
              <span *ngIf="data.remarks == ''">-nil-</span>
              <span *ngIf="data.remarks == null">-nil-</span>
            </td>
            <td>
              <h5>
                <span
                  class="badge badge-soft-{{ data.type_color }}"
                  [ngClass]="{
                    'badge-soft-success': data.isStatus == 'APPROVED',
                    'bg-warning': data.isStatus == 'PENDING',
                    'badge-soft-danger': data.isStatus == 'DECLINED'
                  }"
                >
                  <span *ngIf="data.isStatus == 'PENDING'">Pending</span>
                  <span *ngIf="data.isStatus == 'APPROVED'">Approved</span>
                  <span *ngIf="data.isStatus == 'DECLINED'">Rejected</span>
                </span>
              </h5>
            </td>
            <td>
              <div *ngIf="data.isStatus == 'PENDING'">
                <a
                  (click)="cancelOption(data.leaveRequestId)"
                  data-bs-toggle="modal"
                  class="btn btn-sm btn-soft-danger"
                  ><i class="bx bx-x bx-xs"></i>
                </a>
              </div>
              <div *ngIf="data.isStatus != 'PENDING'">
                <strong>N/A</strong>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div *ngIf="emptyData" class="no-data-cell">No Data Available</div>
      <div id="" *ngIf="loader">
        <div class="loader" id="status">
          <div class="spinner-chase">
            <div class="chase-dot"></div>
            <div class="chase-dot"></div>
            <div class="chase-dot"></div>
            <div class="chase-dot"></div>
            <div class="chase-dot"></div>
            <div class="chase-dot"></div>
          </div>
        </div>
      </div>
    <div *ngIf="!loader" class="d-flex justify-content-center mb-3">
      <!-- <pagination-controls
        responsive="true"
        previousLabel="Prev"
        nextLabel="Next"
        (pageChange)="onTableDataChange($event)"
      >
      </pagination-controls> -->
      <div class="mr-4">
        <button
          *ngIf="!hidePreviousButton"
          (click)="previous()"
          class="btn btn-light btn-sm"
        >
          << Prev
        </button>
      </div>
      <div></div>
      <div>
        <button
          *ngIf="!hideNextButton"
          (click)="next()"
          class="btn btn-light btn-sm"
        >
          Next >>
        </button>
      </div>
    </div>
  </div>

  <div class="col-md-3">
    <div class="d-flex flex-column RemainingLabels">
      <div class="card mini-stats-wid bg-info text-light">
        <div class="card-body">
          <h4 class="">Casual Leave</h4>
          <div class="d-flex justify-content-between">
            <div>
              <p class="fw-medium mb-2 text-center">Remaining</p>
            </div>
            <div>
              <h6 class="mb-0 text-center">
                {{ casualLimit - casualCount }}/{{ casualLimit }}
              </h6>
            </div>
          </div>
        </div>
      </div>

      <div class="card mini-stats-wid bg-info text-light">
        <div class="card-body">
          <h4 class="">Sick Leave</h4>
          <div class="d-flex justify-content-between">
            <div>
              <p class="fw-medium mb-2 text-center">Remaining</p>
            </div>
            <div>
              <h6 class="mb-0 text-center">
                {{ sickLimit - sickCount }}/{{ sickLimit }}
              </h6>
            </div>
          </div>
        </div>
      </div>

      <div class="card mini-stats-wid bg-info text-light">
        <div class="card-body">
          <h4 class="">Work From Home</h4>
          <div class="d-flex justify-content-between">
            <div>
              <p class="fw-medium mb-2 text-center">Remaining</p>
            </div>
            <div>
              <h6 class="mb-0 text-center">
                {{ wfhLimit - wfhCount }}/{{ wfhLimit }}
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #content role="document" let-modal>
  <div class="modal-header border-bottom-0">
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click'); close()"
    ></button>
  </div>
  <div class="card">
    <h4 class="card-title text-center mt-0 mb-2">Apply Leave / WFH</h4>
    <div class="card-body">
      <form [formGroup]="form" (ngSubmit)="Requestleave()">
        <div class="mb-3 row">
          <label
            for="example-date-input"
            class="col-md-3 col-form-label asterisk"
            >Date</label
          >
          <div class="col-md-9">
            <input
              class="form-control"
              type="date"
              value="2019-08-19"
              id="example-date-input"
              formControlName="date"
            />
          </div>
        </div>
        <div class="mb-3 row">
          <label class="col-md-3 col-form-label asterisk">Type</label>
          <div class="col-md-9">
            <select class="form-select" formControlName="type">
              <option
                [ngStyle]="{ color: 'red', 'font-weight': 'bold' }"
                value="Sickleave"
              >
                Sick Leave
              </option>
              <option
                [ngStyle]="{ color: 'red', 'font-weight': 'bold' }"
                value="HalfDaySick"
              >
                Half-day Sick Leave
              </option>
              <option
                [ngStyle]="{ color: 'red', 'font-weight': 'bold' }"
                value="HalfDay"
              >
                Half-day Casual Leave
              </option>
              <option
                [ngStyle]="{ color: 'red', 'font-weight': 'bold' }"
                value="Casual"
              >
                Casual Leave
              </option>
              <option
                [ngStyle]="{ color: 'green', 'font-weight': 'bold' }"
                value="WFH"
              >
                Work From Home
              </option>
            </select>
          </div>
        </div>

        <div class="mb-3 row">
          <label for="example-email-input" class="col-md-3 col-form-label"
            >Remarks</label
          >
          <div class="col-md-9">
            <input
              class="form-control"
              type=""
              value=" "
              id="example-email-input"
              formControlName="remarks"
            />
          </div>
        </div>

        <div class="text-center mt-4">
          <button
            (click)="modal.close()"
            type="submit"
            class="btn btn-primary"
            [disabled]="!form.valid"
          >
            Apply
          </button>
        </div>
      </form>
    </div>
  </div>
</ng-template>
