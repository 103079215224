import { Component } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { AuthService } from "src/app/Common/authentication/auth.service/auth.service";
import { AuthInput } from "src/app/Common/authentication/models/AuthInput";
import { ConstantService } from "src/app/Common/service/constant.service";
import { SharedService } from "src/app/Common/service/shared.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-qr2",
  templateUrl: "./qr2.component.html",
  styleUrls: ["./qr2.component.scss"],
})
export class Qr2Component {
  popup: Boolean = false;

  imageSource: any;
  qrImage: any;
  msalToken: any;
  getTokenInput: AuthInput;
  url: any;
  authInput: any;
  userRole: any;
  loader=true;

  constructor(
    private sanitizer: DomSanitizer,
    private authService: AuthService,
    private router: Router,
    private constantService: ConstantService,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    this.qrImage = localStorage.getItem("QR");
    this.msalToken = localStorage.getItem("PUB_KEY");
    this.loader=false;
    console.log(this.qrImage);
    this.imageSource = this.sanitizer.bypassSecurityTrustResourceUrl(
      ` ${this.qrImage}`
    );
  }

  displayStyle2 = "block";
  num = "";
  qrCode = "";

  flagVerified: boolean;
  onSubmit() {
    // console.log(data.digit);
    console.log(this.qrCode.toString());
    // this.num=data.digit;

    this.getTokenInput = {
      pubKey: this.msalToken,
      otp: this.qrCode.toString(),
    };
    this.authService
      .getAuthToken(this.getTokenInput)
      .subscribe((data1: any) => {
        console.log(data1);
        console.log(data1.token);

        if (data1.success) {
          // Swal.fire("Verified", "", "success");
          this.flagVerified = true;
          if (this.flagVerified == true) {
            if (data1.roles == "Admin") {
              localStorage.setItem(this.constantService.LOCAL_AUTH_TOKEN,data1.token);
              localStorage.setItem(this.constantService.USER_ROLE,'Admin')
              this.router.navigate(["/home/project-manager-home"]);
              this.sharedService.UserRole(data1.roles);
            } else {
              localStorage.setItem(this.constantService.LOCAL_AUTH_TOKEN,  data1.token);
              this.router.navigate(["/home/employee-home"]);
              localStorage.setItem(this.constantService.USER_ROLE,'Employee')

              this.sharedService.UserRole(data1.roles);
            }
          }
        }

        // this.router.navigate(['/invalid'])
        else {
          Swal.fire({
            title: "Invalid OTP",
            text: "",
            icon: "warning",
            cancelButtonColor: "#f46a6a",
          });
        }
      });
  }

  // confirm() {
  //   Swal.fire({
  //     title: "Are you sure?",
  //     text: "You won't be able to revert this!",
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#34c38f",
  //     cancelButtonColor: "#f46a6a",
  //     confirmButtonText: "Yes, delete it!",
  //   }).then((result) => {
  //     if (result.value) {
  //       Swal.fire("Deleted!", "Your file has been deleted.", "success");
  //     }
  //   });
  // }

  onCodeCompleted(code: string) {
    this.qrCode = code;
  }
  onCodeChanged(code: string) {
    this.qrCode = code;
  }
}
