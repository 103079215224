<!-- <div *ngIf="loader">
  <app-loader></app-loader>
</div> -->
<div class="card-body">
  <h3 class="text-center mb-3 mt-1">TimeSheet</h3>
  <div class="row g-3">
    <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-2">
      <select
        class="form-control select2"
        [(ngModel)]="projectId"
        placeholder="aaa"
      >
        <option value="" disabled selected>Project Name</option>
        <!-- <option value="" >None</option> -->
        <option *ngFor="let d of projectName" value="{{ d.projectId }}">
          {{ d.projectName }}
        </option>
      </select>
    </div>

    <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-2">
      <div id="datepicker1">
        <input
          type="text"
          class="form-control"
          placeholder="From"
          onfocus="(this.type='date')"
          [(ngModel)]="from"
        />
      </div>
      <!-- input-group -->
    </div>
    <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-2">
      <div id="datepicker1">
        <input
          type="text"
          class="form-control"
          placeholder="To"
          onfocus="(this.type='date')"
          [(ngModel)]="to"
        />
      </div>
      <!-- input-group -->
    </div>
    <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-2">
      <select
        class="form-control select2"
        [(ngModel)]="isStatus"
        placeholder="aaa"
      >
        <option value="" disabled selected>Status</option>
        <!-- <option value="" >None</option> -->
        <option value="APPROVED">Approved</option>
        <option value="DECLINED">Rejected</option>
        <option value="PENDING">Pending</option>
      </select>
    </div>
    <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-2">
      <button (click)="Filter()" type="button" class="btn btn-secondary w-100">
        <i class="mdi mdi-filter-outline align-middle"></i> Filter
      </button>
    </div>
    <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-2">
      <button
        (click)="ClearFilter()"
        type="button"
        class="btn btn-secondary w-100"
      >
        <i class=""></i>Clear Filter
      </button>
    </div>
    <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-2 text-center">
      <button
        type="button"
        class="btn btn-success btn-rounded mb-2 me-2"
        (click)="addExpense(content)"
      >
        <i class="mdi mdi-plus mr-1"></i> Add Time sheet
      </button>
    </div>
  </div>
  <div *ngIf="!emptyData" class="table-responsive">
    <br />
    <table *ngIf="!loader" class="table table-bordered align-middle nowrap">
      <thead>
        <tr class="text-center">
          <th style="width: 5%" scope="col">No.</th>
          <th style="width: 15%" scope="col">Date</th>
          <th style="width: 10%" scope="col">Project</th>
          <th style="width: 10%" scope="col">Module</th>
          <th style="width: 10%" scope="col">Submodule</th>
          <th style="width: 10%" scope="col">Details</th>
          <th style="width: 5%" scope="col">Hours</th>
          <th style="width: 10%" scope="col">Billable</th>
          <th style="width: 10%" scope="col">Status</th>
          <th style="width: 10%" scope="col">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="
            let data of data3
              
            index as a
          "
          class="text-center"
        >
          <td>{{  ((this.pageSize*this.pageIndex)-this.pageSize)+a+1 }}</td>
          <td>{{ data.dateOfTimeSheet | date : "dd-MMM-yyyy" }}</td>
          <td>
            <h5>
              <span class="badge badge-soft-info">{{ data.project }}</span>
            </h5>
          </td>
          <td>{{ data.module }}</td>
          <td>{{ data.submodule }}</td>
          <td>{{ data.details }}</td>
          <td>{{ data.hours }}</td>
          <td>
            <h5>
              <span
                class="badge"
                [ngClass]="{
                  'bg-success': data.isBillable === true,
                  'bg-info': data.isBillable === false
                }"
              >
                <span *ngIf="data.isBillable == true">Yes</span>
                <span *ngIf="data.isBillable == false">No</span>
              </span>
            </h5>
          </td>
          <td>
            <h5>
              <span
                class="badge"
                [ngClass]="{
                  'rounded-pill bg-info': data.is_Status === 'APPROVED',
                  'rounded-pill bg-warning': data.is_Status === 'PENDING',
                  'rounded-pill bg-danger': data.is_Status === 'DECLINED'
                }"
              >
                <span *ngIf="data.is_Status == 'APPROVED'">Approved</span>
                <span *ngIf="data.is_Status == 'PENDING'">Pending</span>
                <span *ngIf="data.is_Status == 'DECLINED'">Rejected</span>
              </span>
            </h5>
          </td>
          <td>
            <button
              type="button"
              class="btn btn-primary btn-sm btn-rounded"
              (click)="viewDetails(data); popupViewDetails(viewDetailsContent)"
            >
              View Details
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    
    <!-- <pagination-controls previousLabel="Prev" nextLabel="Next" (pageChange)="config.currentPage = $event"></pagination-controls> -->
  </div>
  <div *ngIf="emptyData" class="no-data-cell">No Data Available</div>

  <!--end row-->
</div>
<div id="" *ngIf="loader">
  <div >
    <div class="" id="status">
      <div class="spinner-chase">
        <div class="chase-dot"></div>
        <div class="chase-dot"></div>
        <div class="chase-dot"></div>
        <div class="chase-dot"></div>
        <div class="chase-dot"></div>
        <div class="chase-dot"></div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="!loader" class="d-flex justify-content-center mb-3">
  <!-- <pagination-controls
    responsive="true"
    previousLabel="Prev"
    nextLabel="Next"
    (pageChange)="onTableDataChange($event)"
  >
  </pagination-controls> -->
  <div class="mr-4">
    <button *ngIf="!hidePreviousButton" (click)="previous()" class="btn btn-light btn-sm"><< Prev </button>
  </div>
  <div>  </div>
  <div>
    <button *ngIf="!hideNextButton" (click)="next()" class="btn btn-light btn-sm">Next >></button>
  </div>
</div>
<!--add TimeSheet popup start-->
<ng-template #content role="document" let-modal>
  <div class="modal-header border-bottom-0">
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click'); close()"
    ></button>
  </div>
  <div class="card">
    <div class="card-body">
      <h4 class="card-title text-center mb-3">Add Time Sheet</h4>

      <!-- first -->
      <form [formGroup]="form">
        <div class="mb-3 row">
          <label
            for="example-date-input"
            class="col-md-2 col-form-label asterisk"
            >Date</label
          >
          <div class="col-md-10">
            <!-- (input)="HoursAndDate(nghours, $event.target.value)" -->
            <input
              class="form-control"
              type="date"
              value="2019-08-19"
              id="example-date-input"
              formControlName="date"
              required
              [(ngModel)]="ngdate"
            />
          </div>
        </div>
        <!-- third -->
        <div class="mb-3 row d-flex">
          <label
            for="example-search-input"
            class="col-md-2 col-form-label asterisk"
            >Project</label
          >
          <div class="col-md-10">
            <select
              class="form-select asterisk"
              (change)="ProjectOptionSelected($event.target.value)"
              formControlName="projectname"
              required
              [(ngModel)]="ngprojectname"
            >
              <option [value]="" selected>Choose Project Name</option>
              <option *ngFor="let p of proname" [value]="p.projectId">
                {{ p.projectName }}
              </option>
            </select>
          </div>
        </div>
        <!-- fourth -->

        <div class="mb-3 row d-flex">
          <label
            for="example-search-input"
            class="col-md-2 col-form-label asterisk"
            >Module</label
          >
          <div class="col-md-10">
            <select
              class="form-select"
              (change)="ModuleOptionSelected($event.target.value)"
              formControlName="modulename"
              required
              [(ngModel)]="ngmodulename"
            >
              <option [value]="" selected>Choose Module Name:</option>
              <option *ngFor="let p of DropdownModule" [value]="p.moduleId">
                {{ p.module }}
              </option>
            </select>
          </div>
        </div>
        <div class="mb-3 row">
          <label
            for="example-search-input"
            class="col-md-2 col-form-label asterisk"
            >Sub-Module</label
          >
          <div class="col-md-10">
            <select
              class="form-select"
              formControlName="submodulename"
              required
              [(ngModel)]="ngsubmodulename"
            >
              <!-- <option  [value]='' selected disabled>Choose Submodule Name:</option> -->
              <option [value]="" disabled></option>
              <option
                *ngFor="let p of DropdownSubModule"
                [value]="p.subModuleId"
              >
                {{ p.subModule }}
              </option>
            </select>
          </div>
        </div>
        <div class="mb-3 row">
          <label
            for="example-search-input"
            class="col-md-2 col-form-label asterisk"
            >Hours</label
          >
          <div class="col-md-10">
            <!-- (input)="HoursAndDate($event.target.value, ngdate)" -->
            <input
              class="form-control"
              type="number"
              min="1"
              max="24"
              oninput="validity.valid||(value='');"
              value=" "
              formControlName="hours"
              required
              [(ngModel)]="nghours"
            />
          </div>
        </div>
        <!-- first -->
        <div class="mb-3 row">
          <label class="col-md-2 col-form-label asterisk">Billable</label>
          <div class="col-md-10">
            <select
              class="form-select"
              formControlName="billable"
              required
              [(ngModel)]="ngbillable"
            >
              <option *ngIf="Billable" value="" selected="{{ billableValue }}">
                Choose an option:
              </option>
              <option value="yes">Yes</option>
              <!-- <option *ngIf="!billableValue" value=""></option> -->
              <option value="no">No</option>
            </select>
          </div>
        </div>
        <!-- fourth -->

        <div class="mb-3 row">
          <label
            for="example-email-input"
            class="col-md-2 col-form-label asterisk"
            >Description</label
          >
          <div class="col-md-10">
            <textarea
              class="form-control"
              type="textarea"
              value=" "
              id="example-email-input"
              formControlName="details"
              required
              [(ngModel)]="ngdetails"
            >
            </textarea>
          </div>
        </div>
        <div class="text-center mt-4">
          <button
            (click)="addTimeSheet(); modal.close(); close()"
            type="submit"
            class="btn btn-primary"
            [disabled]="
              !ngdetails ||
              !ngbillable ||
              !ngprojectname ||
              !nghours ||
              !ngdate ||
              !ngsubmodulename ||
              !ngmodulename
            "
          >
            Add Time Sheet
          </button>
          <!-- <button
           (click)="console()"
            class="btn btn-primary text-end"
          >
            Console
          </button> -->
        </div>
      </form>
    </div>
  </div>
</ng-template>
<!-- popup ends-->

<!-- View Details popup start -->
<ng-template #viewDetailsContent role="document" let-modal>
  <div class="modal-header border-bottom-0">
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="close(); modal.dismiss('Cross click')"
    ></button>
  </div>
  <div class="card">
    <div class="card-body">
      <h4 class="card-title text-center mb-3">Time Sheet Details</h4>
      <div class="table-responsive">
        <table class="table table-centered table-nowrap">
          <thead class="viewDetails">
            <tr>
              <td>TimeSheet ID</td>
              <td>{{ value[0].timeSheetId }}</td>
            </tr>
            <tr>
              <td>Reviewed by</td>
              <td *ngIf="value[0].approvedByName != null">
                {{ value[0].approvedByName }}
              </td>
              <td *ngIf="value[0].approvedByName == null">Not reviewed yet</td>
            </tr>
            <tr>
              <td>Entered Date</td>
              <td>{{ value[0].dateOfTimeSheet | date : "dd-MMM-yyyy" }}</td>
            </tr>
            <tr>
              <td>Reviewed Date</td>
              <td *ngIf="value[0].approvedDate != null">
                {{ value[0].approvedDate | date : "dd-MMM-yyyy" }}
              </td>
              <td *ngIf="value[0].approvedDate == null">Not reviewed yet</td>
            </tr>
            <tr>
              <td>Project</td>
              <td>{{ value[0].project }}</td>
            </tr>
            <tr>
              <td>Module</td>
              <td>{{ value[0].module }}</td>
            </tr>
            <tr>
              <td>SubModule</td>
              <td>{{ value[0].submodule }}</td>
            </tr>
            <tr>
              <td>Description</td>
              <td>{{ value[0].details }}</td>
            </tr>
            <tr>
              <td>Hours</td>
              <td>{{ value[0].hours }}</td>
            </tr>
            <tr>
              <td>Billable</td>
              <td *ngIf="value[0].isBillable == true">Yes</td>
              <td *ngIf="value[0].isBillable == false">No</td>
            </tr>
            <tr>
              <td>Status</td>
              <td *ngIf="value[0].is_Status == 'APPROVED'">Approved</td>
              <td *ngIf="value[0].is_Status == 'PENDING'">Pending</td>
              <td *ngIf="value[0].is_Status == 'DECLINED'">Rejected</td>
            </tr>
          </thead>
        </table>
      </div>

      <!-- first -->
    </div>
  </div>
</ng-template>
<!-- View Details popup ends -->
