<app-page404 _nghost-ygo-c112=""
  ><div _ngcontent-ygo-c112="" class="account-pages my-5 pt-5">
    <div _ngcontent-ygo-c112="" class="container">
      <div _ngcontent-ygo-c112="" class="row">
        <div _ngcontent-ygo-c112="" class="col-lg-12">
          <div _ngcontent-ygo-c112="" class="text-center mb-5">
            <!-- <h1 _ngcontent-ygo-c112="" class="display-2 fw-medium">
              4<i
                _ngcontent-ygo-c112=""
                class="bx bx-buoy bx-spin text-primary display-3"
              ></i
              >4
            </h1> -->
            <h4 _ngcontent-ygo-c112="" class="text-uppercase">
              Sorry, Invalid OTP
            </h4>
            <div _ngcontent-ygo-c112="" class="mt-5 text-center">
              <a
              routerLink="/qr2"
                class="btn btn-primary"
                >Back </a
              >
            </div>
          </div>
        </div>
      </div>
      <div _ngcontent-ygo-c112="" class="row justify-content-center">
        <div _ngcontent-ygo-c112="" class="col-md-8 col-xl-6">
          <div _ngcontent-ygo-c112="">
            <img
              _ngcontent-ygo-c112=""
              src="assets/images/error-img.png"
              alt=""
              class="img-fluid"
            />
          </div>
        </div>
      </div>
    </div></div
></app-page404>
