import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpClient,
} from "@angular/common/http";
import {
  NgbNavModule,
  NgbAccordionModule,
  NgbTooltipModule,
  NgbModule,
} from "@ng-bootstrap/ng-bootstrap";
import { CarouselModule } from "ngx-owl-carousel-o";
import { ScrollToModule } from "@nicky-lenaers/ngx-scroll-to";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { MsalModule, MsalService, MSAL_INSTANCE } from "@azure/msal-angular";
import {
  IPublicClientApplication,
  PublicClientApplication,
} from "@azure/msal-browser";
// new 

import { CommonModule, DatePipe } from '@angular/common';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { ArchwizardModule } from 'angular-archwizard';
import { NgxMaskModule } from 'ngx-mask';
import { NgSelectModule } from '@ng-select/ng-select';
import { UiSwitchModule } from 'ngx-ui-switch';
import { ColorPickerModule } from 'ngx-color-picker';
import { DropzoneModule } from 'ngx-dropzone-wrapper';

// FlatPicker
import { FlatpickrModule } from 'angularx-flatpickr';
// ends 
import { HomeComponent } from "./Pages/home/home.component";
import { LoginComponent } from "./Pages/login/login.component";
import { QrComponent } from './Pages/qr/qr.component';
import { EmployeehomeComponent } from './Pages/employeehome/employeehome.component';
import { HeaderComponent } from './pages/header/header.component';
import { Qr2Component } from './Pages/qr2/qr2.component';
import { InvalidOtpComponent } from './Pages/invalid-otp/invalid-otp.component';
import { AuthInterceptor } from "./Common/authentication/interceptor/auth.interceptor";
// import { SidebarComponent } from './layout/sidebar/sidebar.component';
// import { TopbarComponent } from './layout/topbar/topbar.component';
// import { LayoutComponent } from './layout/layout.component';
import {  LayoutsModule} from './Common/components/layout/layouts.module';
import { EmphomeComponent } from './Pages/emphome/emphome.component';
import { LoaderComponent } from './shared/loader/loader.component';
import { ChartsModule } from 'ng2-charts';
// import { EmpReimburshmentComponent } from './Pages/emp-reimburshment/emp-reimburshment.component';
import { EmpTimeSheetComponent } from './Pages/emp-time-sheet/emp-time-sheet.component';
import { EmpReimburshmentShowComponent } from './Pages/emp-reimburshment-show/emp-reimburshment-show.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FullCalendarModule } from '@fullcalendar/angular';
import { ProManagerHomeComponent } from './Pages/project_manager/pro-manager-home/pro-manager-home.component';
import { ChartistModule } from "ng-chartist";
import {NgxChartistModule} from 'ngx-chartist';
// import { EmpLeaveComponent } from './Pages/emp-leave/emp-leave.component';
import { ProManagerTimesheeetComponent } from './Pages/project_manager/pro-manager-timesheeet/pro-manager-timesheeet.component';
import { ProManagerProjectsComponent } from './Pages/project_manager/pro-manager-projects/pro-manager-projects.component';
import { ProManagerReimbursementComponent } from './Pages/project_manager/pro-manager-reimbursement/pro-manager-reimbursement.component';
import { ProManagerLeaveComponent } from './Pages/project_manager/pro-manager-leave/pro-manager-leave.component';
import { ProManagerTimeSheetRequestComponent } from './Pages/project_manager/pro-manager-timesheeet/pro-manager-time-sheet-request/pro-manager-time-sheet-request.component';
import { ProManagerReimbursementRequestComponent } from './Pages/project_manager/pro-manager-reimbursement/pro-manager-reimbursement-request/pro-manager-reimbursement-request.component';
import { ProManagerLeaveRequestComponent } from './Pages/project_manager/pro-manager-leave/pro-manager-leave-request/pro-manager-leave-request.component';
import { ProManagerProjectAddComponent } from './Pages/project_manager/pro-manager-projects/pro-manager-project-add/pro-manager-project-add.component';
import { ProManApprovedShowComponent } from './Pages/project_manager/pro-manager-timesheeet/pro-man-approved-show/pro-man-approved-show.component';
import { ProManDeclinedShowComponent } from './Pages/project_manager/pro-manager-timesheeet/pro-man-declined-show/pro-man-declined-show.component';
import { ProManReimbursementDeclinedComponent } from './Pages/project_manager/pro-manager-reimbursement/pro-man-reimbursement-declined/pro-man-reimbursement-declined.component';
import { ProManReimbursementApprovedComponent } from './Pages/project_manager/pro-manager-reimbursement/pro-man-reimbursement-approved/pro-man-reimbursement-approved.component';
import { ProManLeaveApprovedComponent } from './Pages/project_manager/pro-manager-leave/pro-man-leave-approved/pro-man-leave-approved.component';
import { ProManLeaveDeclinedComponent } from './Pages/project_manager/pro-manager-leave/pro-man-leave-declined/pro-man-leave-declined.component';
import { EmpLeaveComponent } from './Pages/employee-leave/employee-leave.component';
// import { AuthGuardGuard } from '../app/guards/auth-guard.guard'
import { CodeInputModule } from 'angular-code-input';
import { NgxPaginationModule } from 'ngx-pagination'; 
import { AuthGuardGuard } from "./guards/auth-guard.guard";
import { AuthGuard2Guard } from "./guards/auth-guard2.guard";


export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: "cb43aceb-8b0e-4e35-9882-6fe642666808",
      // redirectUri: "https://localhost:4200/ad-signin",
      // redirectUri: "https://b.test.tngone.com/tng-opscape-web/ad-signin",
      redirectUri: "https://opscape.orderstack.io/ad-signin",
      
      // postLogoutRedirectUri: "https://localhost:4200",
      // postLogoutRedirectUri: "https://b.test.tngone.com/tng-opscape-web/",
      postLogoutRedirectUri: "https://opscape.orderstack.io/",

      authority:"https://login.microsoftonline.com/c18a2dc0-ba9f-4d30-a3c0-57735c229588"
          },
  });
}

@NgModule({

  declarations: [AppComponent, HomeComponent, LoginComponent, QrComponent, EmpLeaveComponent,  HeaderComponent, Qr2Component, InvalidOtpComponent, EmphomeComponent, LoaderComponent, EmpTimeSheetComponent, EmpReimburshmentShowComponent, ProManagerHomeComponent, EmpLeaveComponent, ProManagerTimesheeetComponent, ProManagerProjectsComponent, ProManagerReimbursementComponent, ProManagerLeaveComponent, ProManagerTimeSheetRequestComponent, ProManagerReimbursementRequestComponent, ProManagerLeaveRequestComponent, ProManagerProjectAddComponent, ProManApprovedShowComponent, ProManDeclinedShowComponent, ProManReimbursementDeclinedComponent, ProManReimbursementApprovedComponent, ProManLeaveApprovedComponent, ProManLeaveDeclinedComponent, EmpLeaveComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    CarouselModule,
    NgbAccordionModule,
    NgbNavModule,
    NgbTooltipModule,
    ScrollToModule.forRoot(),
    NgbModule,
    MsalModule,
    FormsModule,
    // LayoutsModule,
    ChartsModule,
    FormsModule,
    ReactiveFormsModule,
    FullCalendarModule,
    ChartistModule,
    NgxChartistModule,
    DropzoneModule,
    ColorPickerModule,
    UiSwitchModule,
    NgSelectModule,
    NgxMaskModule,
    ArchwizardModule,
    CKEditorModule,
    NgbDatepickerModule,
    CommonModule,
    CodeInputModule,
    NgxPaginationModule
  ],
  bootstrap: [AppComponent],
  providers: [
    { provide: MSAL_INSTANCE, useFactory: MSALInstanceFactory },
    MsalService,
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    DatePipe,
    AuthGuardGuard,
    AuthGuard2Guard
  ],
})
export class AppModule {}
