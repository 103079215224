import { Component, OnInit } from "@angular/core";
import { AuthService } from "src/app/Common/authentication/auth.service/auth.service";
import { filterData, multipleApproveAndReject, statusModels } from "./models";
import Swal from "sweetalert2";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DatePipe } from "@angular/common";

@Component({
  selector: "app-pro-manager-time-sheet-request",
  templateUrl: "./pro-manager-time-sheet-request.component.html",
  styleUrls: ["./pro-manager-time-sheet-request.component.scss"],
})
export class ProManagerTimeSheetRequestComponent implements OnInit {
  days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  tabledata: any;
  data2: any;
  allUser: any;
  emptyData = false;
  isChecked: boolean;
  checkBoxArray = [];
  multipleValueCheckbox: any[] = [];
  numOfRows: number;
  totalNumOfRows: number;
  loader: boolean;
  pageIndex = 1;
  pageSize = 50;
  hidePreviousButton: boolean;
  hideNextButton: boolean;

  ngOnInit(): void {
    // this.authService.getTimesheetApprovalView().subscribe((data: any) => {
    //   this.tabledata = data;
    //   console.log(this.tabledata);
    //   if (data.length == 0) {
    //     this.emptyData = true;
    //     // Swal.fire("No more Request", "", "warning");
    //   }
    // });

    this.TableShow(this.pageIndex, this.pageSize);

    this.authService.getAllProjectName().subscribe((data: any) => {
      console.log(data);
      this.data2 = data;
    });

    this.authService.getAllUser().subscribe((data: any) => {
      this.allUser = data;
      console.log(this.allUser, "all users");
    });
  }

  date = new Date();
  constructor(
    private authService: AuthService,
    private modalService: NgbModal,
    private datePipe: DatePipe
  ) {}

  DropdownSubModule: any;
  ngmodulename = "";
  ngsubmodulename = "";
  ngprojectname = "";
  approveRejectShowButton = false;
  validationModule = false;
  validationSubmodule = false;

  ModuleOptionSelected(selectedValue: any) {
    this.validationSubmodule = false;
    console.log("submodulename", this.ngsubmodulename);
    this.ngsubmodulename = "";
    console.log("submodulename", this.ngsubmodulename);
    this.authService.getSubModule(selectedValue).subscribe((data: any) => {
      this.DropdownSubModule = data;
      console.log(this.DropdownSubModule);
    });
  }

  DropdownModule: any;
  ProjectOptionSelected(selectedValue: any) {
    this.validationModule = false;
    console.log("modulename", this.ngmodulename);
    this.ngmodulename = "";
    this.ngsubmodulename = "";
    console.log("modulename", this.ngmodulename);
    this.authService.getModuleName(selectedValue).subscribe((data: any) => {
      this.DropdownModule = data;
      console.log(this.DropdownModule);
      console.log(selectedValue);
    });
  }

  validationForModule() {
    if (this.ngprojectname == "") {
      this.validationModule = true;
      console.log("validation", this.ngmodulename);
    } else if (this.ngprojectname != "") this.validationModule = false;
  }

  validationForSubmodule() {
    if (this.ngmodulename == "") {
      this.validationSubmodule = true;
      console.log("validation", this.ngsubmodulename);
    } else if (this.ngmodulename != "") this.validationSubmodule = false;
  }
  // decline(timeSheetId: string) {
  //   this.timeSheetId = timeSheetId;

  //   this.statusmodel = {
  //     IsProManagerStat: false,
  //     TimeSheetId: this.timeSheetId,
  //   };
  //   console.log(this.statusmodel);
  //   this.authService
  //     .getTimeSheetApproval(this.statusmodel)
  //     .subscribe((data: any) => {
  //       console.log(data);
  //       this.ngOnInit();
  //     });
  //   Swal.fire("Declined", "You declined one request!","success");

  // }

  decline(timeSheetId: string) {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger ms-2",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        confirmButtonText: "Yes, reject it!",
        cancelButtonText: "No, cancel!",
        showCancelButton: true,
      })
      .then((result) => {
        if (result.value) {
          this.timeSheetId = timeSheetId;

          this.statusmodel = {
            Is_Status: "DECLINED",
            TimeSheetId: this.timeSheetId,
          };
          console.log(this.statusmodel);
          this.authService
            .getTimeSheetApproval(this.statusmodel)
            .subscribe((data: any) => {
              console.log(data);
              this.TableShow(this.pageIndex, this.pageSize);
            });
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Rejected",
              showConfirmButton: false,
              timer: 1300,
            });
        }
      });
  }

  statusmodel: statusModels;
  // status:boolean=false

  timeSheetId: string;

  approved(timeSheetId: string) {
    this.timeSheetId = timeSheetId;

    this.statusmodel = {
      Is_Status: "APPROVED",
      TimeSheetId: this.timeSheetId,
    };
    console.log(this.statusmodel);
    this.authService
      .getTimeSheetApproval(this.statusmodel)
      .subscribe((data: any) => {
        if (data.message == "Approved Leave for current date") {
          Swal.fire({
            title: "",
            text: "This action cannot be performed as the employee has a leave approved for this day ",
            icon: "warning",
            cancelButtonColor: "#f46a6a",
          });
        } else if ((data.message = "Approved")) {
          console.log(data);
          this.TableShow(this.pageIndex, this.pageSize);
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Approved",
            showConfirmButton: false,
            timer: 1300,
          });
        }
      });
  }

  popupViewDetails(content: any) {
    this.modalService.open(content, { size: "md", centered: true });
  }

  value: any;
  viewDetails(data) {
    console.log(data);
    // console.log(data.timeSheetId);
    this.authService
      .getProManagerTimesheetViewById(data)
      .subscribe((data: any) => {
        this.value = data;
        console.log(this.value, "view details");
        console.log(this.value, "view details");
        console.log(this.value[0].dateOfTimeSheet, "view details Date");
      });
  }

  filterdata: filterData;
  from = "";
  from2 = "";
  to = "";
  to2 = "";
  projectId2 = "";
  userId = "";
  filterData: any;
  filterView: boolean = false;
  allTimesheetId: any[] = [];

  Filter() {
    this.pageIndex = 1;
    this.loader = true;
    this.selectAllngModel = false;
    this.approveRejectShowButton = false;
    this.checkBoxArray.splice(0, this.checkBoxArray.length);

    for (var i = 0; i < this.totalNumOfRows; i++) {
      this.multipleValueCheckbox[i] = false;
    }
    this.allTimesheetId.splice(0, this.allTimesheetId.length);

    this.pageIndex = 1;
    console.log("from and to values", this.from, this.to);
    this.filterView = true;

    this.filterdata = {
      ProjectId: this.ngprojectname,
      From: this.from,
      To: this.to,
      UserId: this.userId,
      SubModuleId: this.ngsubmodulename,
      ModuleId: this.ngmodulename,
      PageIndex: this.pageIndex,
      PageSize: this.pageSize,
    };
    console.log(this.filterdata, "filter d");
    this.authService
      .getProTimesheetFilter(this.filterdata)
      .subscribe((data: any) => {
        this.loader = false;
        this.filterData = data;
        console.log(this.filterData);
        this.filterData.forEach((element) => {
          this.allTimesheetId.push(element.timeSheetId);
        });
        console.log(this.allTimesheetId, "All timesheet id");
        this.numOfRows = data.length;
        console.log(this.filterData, "filter data");
        if (data == "") {
          console.log("if condition");
          this.emptyData = true;
          this.approveRejectShowButton = false;
          this.hideNextButton = true;
          this.hidePreviousButton = true;
        } else if (data != "") {
          this.emptyData = false;
          this.hideNextButton = false;
          this.hidePreviousButton = false;
        }
        if (this.pageIndex == 1 && data != "") {
          this.hidePreviousButton = true;
          this.hideNextButton = false;
        } else if (this.pageIndex != 1 && data == "") {
          this.hideNextButton = true;
          this.hidePreviousButton = false;
        }
      });
  }

  TableShow(pageIndex, pageSize) {
    this.loader = true;
    this.emptyData = false;
    this.allTimesheetId.splice(0, this.allTimesheetId.length);
    console.log("from and to values", this.from, this.to);
    this.filterView = true;

    this.filterdata = {
      ProjectId: this.ngprojectname,
      From: this.from,
      To: this.to,
      UserId: this.userId,
      SubModuleId: this.ngsubmodulename,
      ModuleId: this.ngmodulename,
      PageIndex: pageIndex,
      PageSize: pageSize,
    };
    console.log(this.filterdata, "filter d");
    this.authService
      .getProTimesheetFilter(this.filterdata)
      .subscribe((data: any) => {
        this.loader = false;
        this.filterData = data;
        console.log(this.filterData);
        this.filterData.forEach((element) => {
          this.allTimesheetId.push(element.timeSheetId);
        });
        console.log(this.allTimesheetId, "All timesheet id");
        this.numOfRows = data.length;
        this.totalNumOfRows = data.length;
        console.log(this.filterData, "filter data");
        if (data == "") {
          console.log("if condition");
          this.emptyData = true;
          this.approveRejectShowButton = false;
          this.hideNextButton = true;
          this.hidePreviousButton = true;
        } else if (data != "") {
          this.emptyData = false;
          this.hideNextButton = false;
          this.hidePreviousButton = false;
        }
        if (this.pageIndex == 1 && data != "") {
          this.hidePreviousButton = true;
          this.hideNextButton = false;
        } else if (this.pageIndex != 1 && data == "") {
          this.hideNextButton = true;
          this.hidePreviousButton = false;
        }
      });
  }

  next() {
    this.pageIndex = this.pageIndex + 1;
    this.TableShow(this.pageIndex, this.pageSize);
  }

  previous() {
    this.pageIndex = this.pageIndex - 1;
    this.TableShow(this.pageIndex, this.pageSize);
  }

  ClearFilter() {
    this.emptyData = false;
    this.to = "";
    this.from = "";
    this.projectId2 = "";
    this.userId = "";
    this.ngprojectname = "";
    this.ngmodulename = "";
    this.ngsubmodulename = "";
    this.validationModule = false;
    this.validationSubmodule = false;
    this.selectAllngModel = false;
    this.approveRejectShowButton = false;
    this.checkBoxArray.splice(0, this.checkBoxArray.length);

    for (var i = 0; i < this.totalNumOfRows; i++) {
      this.multipleValueCheckbox[i] = false;
    }
    this.Filter();
  }

  // page = 1;
  // count = 0;
  // tableSize = 100;
  // tableSizes = [3, 6, 9, 12];

  onTableDataChange(event) {
    // this.page = event;
    // this.TimesheetTable();
  }

  multipleApproveAndReject: multipleApproveAndReject;
  checkBoxArrayLength: number;
  processing: boolean;
  multipleApprove() {
    this.checkBoxArrayLength = this.checkBoxArray.length;
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger ms-2",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: `You are approving ${this.checkBoxArrayLength} request(s)`,
        icon: "warning",
        confirmButtonText: "Yes, approve it!",
        cancelButtonText: "No, cancel!",
        showCancelButton: true,
      })
      .then((result) => {
        if (result.value) {
          // this.timeSheetId = timeSheetId;
          this.processing = true;

          this.multipleApproveAndReject = {
            TimesheetId: this.checkBoxArray,
            Is_Status: "APPROVED",
          };
          this.authService
            .getSelectTimeSheetApproval(this.multipleApproveAndReject)
            .subscribe((data: any) => {
              this.processing = false;
              this.selectAllngModel = false;
              this.TableShow(this.pageIndex, this.pageSize);
              this.checkBoxArray.splice(0, this.checkBoxArray.length);
              for (var i = 0; i < this.numOfRows; i++) {
                this.multipleValueCheckbox[i] = false;
              }
              this.approveRejectShowButton = false;
              if (data.message == "Approved Leave for current date") {
                Swal.fire({
                  title: "",
                  text: "This action cannot be performed as the employee has a leave approved for this day ",
                  icon: "warning",
                  cancelButtonColor: "#f46a6a",
                });
              } else if (data.isSuccess == true) {
                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: "Approved",
                  showConfirmButton: false,
                  timer: 1300,
                });
              }
            });
        }
      });
  }

  multipleReject() {
    this.checkBoxArrayLength = this.checkBoxArray.length;
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger ms-2",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: `You are rejecting ${this.checkBoxArrayLength} request(s)`,
        icon: "warning",
        confirmButtonText: "Yes, reject it!",
        cancelButtonText: "No, cancel!",
        showCancelButton: true,
      })
      .then((result) => {
        if (result.value) {
          // this.timeSheetId = timeSheetId;
          this.processing = true;
          this.selectAllngModel = false;

          this.multipleApproveAndReject = {
            TimesheetId: this.checkBoxArray,
            Is_Status: "DECLINED",
          };
          this.authService
            .getSelectTimeSheetApproval(this.multipleApproveAndReject)
            .subscribe((data: any) => {
              this.processing = false;
              console.log(data);
              this.TableShow(this.pageIndex, this.pageSize);
              this.checkBoxArray.splice(0, this.checkBoxArray.length);
              for (var i = 0; i < this.numOfRows; i++) {
                this.multipleValueCheckbox[i] = false;
              }
              this.approveRejectShowButton = false;
              if (data.message == "Declined") {
                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: "Rejected",
                  showConfirmButton: false,
                  timer: 1300,
                });
              }
            });
        }
      });
  }

  checkBox(checked: boolean, value: any) {
    if (checked) {
      this.checkBoxArray.push(value);
    } else {
      this.selectAllngModel = false;
      const index = this.checkBoxArray.indexOf(value);
      if (index > -1) {
        this.checkBoxArray.splice(index, 1);
      }
    }
    if (this.checkBoxArray.length > 0) {
      this.approveRejectShowButton = true;
      console.log(this.checkBoxArray.length);
    } else {
      this.approveRejectShowButton = false;
    }
    console.log(this.checkBoxArray, "checkbox array");
  }

  bool = false;
  allSelect = false;
  selectAllngModel: boolean;
  // checkBoxSelectAll() {
  //   this.bool = !this.bool;
  //   this.allSelect = true;
  //   for (var i = 0; i < this.numOfRows; i++) {
  //     this.multipleValueCheckbox[i] = this.bool;
  //   }
  //   if (this.bool) {
  //     console.log("iff");
  //     this.checkBoxArray.splice(0, this.checkBoxArray.length);
  //     this.allTimesheetId.forEach((element) => {
  //       this.checkBoxArray.push(element);
  //     });
  //     console.log(this.checkBoxArray);
  //   } else if (!this.bool) {
  //     console.log("else");
  //     this.checkBoxArray.splice(0, this.checkBoxArray.length);
  //     console.log(this.checkBoxArray);
  //   }
  //   // this.checkBox(true, this.allTimesheetId);
  // }

  allSelectCheckbox(checked: boolean) {
    // this.bool = !this.bool;
    this.checkBoxArray.splice(0, this.checkBoxArray.length);
    console.log(this.checkBoxArray, "first");
    console.log(this.allTimesheetId, "all timeshhet id");
    this.allSelect = true;
    for (var i = 0; i < this.numOfRows; i++) {
      this.multipleValueCheckbox[i] = this.selectAllngModel;
      console.log(this.multipleValueCheckbox[i]);
    }

    if (checked) {
      this.checkBoxArray.splice(0, this.checkBoxArray.length);
      this.allTimesheetId.forEach((element) => {
        this.checkBoxArray.push(element);
      });
    } else {
      this.checkBoxArray.splice(0, this.checkBoxArray.length);
      // const index = this.checkBoxArray.indexOf(value);
      // if (index > -1) {
      //   this.checkBoxArray.splice(index, 1);
      // }
    }
    if (this.checkBoxArray.length > 0) {
      this.approveRejectShowButton = true;
      console.log(this.checkBoxArray.length);
    } else {
      this.approveRejectShowButton = false;
    }
    console.log(this.checkBoxArray, "All checkbox array...");
  }
}
