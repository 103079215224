<!-- <div *ngIf="loader">
<app-loader></app-loader>
</div> -->
<div class="container-fluid">
  <h3 class="text-center mb-3 mt-1">Reimbursement Requests</h3>
  <div class="row d-flex justify-content-evenly mb-2 g-3">
    <div class="col-xxl-2 col-xl-2 col-lg-2 col-md-3 col-sm-3">
      <select
        class="form-control select2"
        [(ngModel)]="userId"
        placeholder="aaa"
      >
        <option value="" disabled selected>Employee Name</option>
        <option *ngFor="let d of allUser" value="{{ d.userID }}">
          {{ d.userName }}
        </option>
      </select>
    </div>
    <div class="col-xxl-2 col-xl-2 col-lg-2 col-md-3 col-sm-3">
      <select class="form-control select2" [(ngModel)]="reimbursemenType">
        <option value="" disabled selected>Type</option>
        <option value="Food">Food</option>
        <option value="Travel Allowance">Travel Allowance</option>
        <option value="Others">Others</option>
      </select>
    </div>
    <div class="col-xxl-2 col-xl-2 col-lg-2 col-md-3 col-sm-3">
      <div id="datepicker1">
        <input
          type="text"
          class="form-control"
          placeholder="From"
          onfocus="(this.type='date')"
          [(ngModel)]="from"
        />
      </div>
      <!-- input-group -->
    </div>
    <div class="col-xxl-2 col-xl-2 col-lg-2 col-md-3 col-sm-3">
      <div id="datepicker1">
        <input
          type="text"
          class="form-control"
          placeholder="To"
          onfocus="(this.type='date')"
          [(ngModel)]="to"
        />
      </div>
      <!-- input-group -->
    </div>
    <div class="col-xxl-2 col-xl-2 col-lg-2 col-md-6 col-sm-6">
      <button (click)="Filter()" type="button" class="btn btn-secondary w-100">
        <i class="mdi mdi-filter-outline align-middle"></i> Filter
      </button>
    </div>
    <div class="col-xxl-2 col-xl-2 col-lg-2 col-md-6 col-sm-6">
      <button
        (click)="ClearFilter()"
        type="button"
        class="btn btn-secondary w-100"
      >
        <i class="mdi mdi-filter-outline align-middle"></i> Clear Filter
      </button>
    </div>
  </div>
  <div *ngIf="!emptyData" class="table-responsive">
    <table *ngIf="!loader" class="table table-bordered align-middle nowrap">
      <thead>
        <tr class="text-center">
          <th scope="col">No</th>
          <th scope="col">Employee Name</th>
          <th scope="col">Reimbursement Date</th>
          <th scope="col">Requested On</th>
          <th scope="col">Type</th>
          <th scope="col">Details</th>
          <th scope="col">Project</th>
          <th scope="col">Attachment</th>
          <th scope="col">Amount</th>
          <th scope="col">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of filterData; index as i" class="text-center">
          <td scope="row">{{  ((this.pageSize*this.pageIndex)-this.pageSize)+i+1 }}</td>
          <td scope="row">{{ data.userName }}</td>
          <td>{{ data.dateOfReimbursement | date : "dd-MMM-yyyy" }}</td>
          <td>{{ data.dateOfRequest | date : "dd-MMM-yyyy" }}</td>
          <td>
            <h5>
              <span
                class="badge"
                [ngClass]="{
                  'bg-success': data.reimbursementType === 'Travel Allowance',
                  'bg-info': data.reimbursementType === 'Food',
                  'bg-primary': data.reimbursementType === 'Others'
                }"
                >{{ data.reimbursementType }}</span
              >
            </h5>
          </td>
          <td>
            <span *ngIf="data.details != ''">{{ data.details }}</span>
            <span *ngIf="data.details == ''">-nil-</span>
            <span *ngIf="data.details == null">-nil-</span>
          </td>
          <td>{{ data.project }}</td>
          <td *ngIf="data.attachment != null">
            <a (click)="Docshow(data.reimbursmentId)"
              ><i class="bx bxs-file-pdf bx-md"></i
            ></a>
          </td>
          <td *ngIf="data.attachment == null">No Attachment</td>
          <td>{{ data.amount | currency : "INR" }}</td>
          <td class="d-flex justify-content-around">
            <div>
              <a
                (click)="approve(data.reimbursmentId)"
                class="btn btn-sm btn-soft-info"
                ><i class="bx bx-check bx-xs"></i
              ></a>
            </div>
            <div>
              <a
                (click)="decline(data.reimbursmentId)"
                data-bs-toggle="modal"
                class="btn btn-sm btn-soft-danger"
                ><i class="bx bx-x bx-xs"></i
              ></a>
            </div>
            <div>
              <a
                (click)="
                  popupViewDetails(viewDetailsContent);
                  viewDetailsforFilterData(data.reimbursmentId)
                "
                data-bs-toggle="modal"
                class="btn btn-sm btn-soft-info"
                ><i class="bx bx-show bx-xs"></i>
              </a>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div *ngIf="emptyData" class="no-data-cell">No Request to Show</div>
  <div id="" *ngIf="loader">
    <div class="loader" id="status">
      <div class="spinner-chase">
        <div class="chase-dot"></div>
        <div class="chase-dot"></div>
        <div class="chase-dot"></div>
        <div class="chase-dot"></div>
        <div class="chase-dot"></div>
        <div class="chase-dot"></div>
      </div>
    </div>
  </div>
  <div *ngIf="!loader" class="d-flex justify-content-center mb-3">
    <div class="mr-4">
      <button
        *ngIf="!hidePreviousButton"
        (click)="previous()"
        class="btn btn-light btn-sm"
      >
        << Prev
      </button>
    </div>
    <div></div>
    <div>
      <button
        *ngIf="!hideNextButton"
        (click)="next()"
        class="btn btn-light btn-sm"
      >
        Next >>
      </button>
    </div>
  </div>
</div>

<!-- popup for view details  -->
<ng-template #viewDetailsContent role="document" let-modal>
  <div class="modal-header border-bottom-0">
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    ></button>
  </div>
  <div class="card">
    <div class="card-body">
      <h4 class="card-title text-center mb-3">Reimbursement Details</h4>
      <div class="table-responsive">
        <table class="table table-centered table-nowrap">
          <thead class="viewDetails">
            <tr>
              <td>Reimbursement ID</td>
              <td>{{ element.reimbursmentId }}</td>
            </tr>
            <tr>
              <td>Reviewed by</td>
              <td *ngIf="element.approvedByName != null">
                {{ element.approvedByName }}
              </td>
              <td *ngIf="element.approvedByName == null">Not reviewed yet</td>
            </tr>
            <tr>
              <td>Entered Date</td>
              <td>{{ element.dateOfRequest | date : "dd-MMM-yyyy" }}</td>
            </tr>
            <tr>
              <td>Reviewed Date</td>
              <td *ngIf="element.approvedDate != '0001-01-01T00:00:00'">
                {{ element.approvedDate | date : "dd-MMM-yyyy" }}
              </td>
              <td *ngIf="element.approvedDate == '0001-01-01T00:00:00'">
                Not reviewed yet
              </td>
            </tr>
            <tr>
              <td>Project</td>
              <td>{{ element.project }}</td>
            </tr>
            <tr>
              <td>Amount</td>
              <td>{{ element.amount | currency : "INR" }}</td>
            </tr>
            <tr>
              <td>Status</td>
              <td
                *ngIf="
                  element.isStatus == 'APPROVED'
                "
              >
                Approved
              </td>
              <td
                *ngIf="
                  element.isStatus == 'PENDING'
                "
              >
                Pending
              </td>
              <td
                *ngIf="
                  element.isStatus == 'DECLINED'
                "
              >
                Rejected
              </td>
            </tr>
          </thead>
        </table>
      </div>
    </div>
  </div>
</ng-template>
<!-- View Details popup ends -->
