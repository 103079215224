import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { MsalService } from "@azure/msal-angular";
import { AuthenticationResult } from "@azure/msal-browser";
import { AuthService } from "src/app/Common/authentication/auth.service/auth.service";
import { ConstantService } from "src/app/Common/service/constant.service";
import { SharedService } from "src/app/Common/service/shared.service";
import Swal from 'sweetalert2';

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {

  public qrData:any;
  constructor(
    private router: Router,
    private msalService: MsalService,
    private constant: ConstantService,
    private authService:AuthService,
    private sharedService:SharedService
  ) {}
  ngOnInit(): void {

    if(this.sharedService.IsLoggedIn){
      console.log('1st if')
      if(this.sharedService.getRole()=='Employee'){
        console.log(this.sharedService.getRole(),'Role')
        this.router.navigate(['/home/employee-home'])
      }
      else if(this.sharedService.getRole()=='Admin')
      this.router.navigate(['/home/project-manager-home'])
    }
   
    this.msalService.instance.handleRedirectPromise().then((res) => {
      if (res != null && res.account != null) {
        this.msalService.instance.setActiveAccount(res.account);
      }
    });
  }

  logIn() {
    this.msalService
      .loginPopup()
      .subscribe((response: AuthenticationResult) => {
        this.msalService.instance.setActiveAccount(response.account);
        console.log(response.idToken);

        localStorage.setItem(this.constant.LOCAL_STORE_TOKEN, response.idToken);
       

        this.authService.getTokenV1(response.idToken).subscribe((data: any) => {
          console.log(data);
          if (data) {

            if(data.success)
            {
              console.log(data.token)
              this.qrData=data.Token;
              this.router.navigate(['/setup-2fa']);
              localStorage.setItem('QR',data.token)
            }
            else if(data.error=='OPT')
            {
              this.router.navigate(['/2fa-roadblock'])
            }

          }
        });
        
        // this.router.navigate(["/qr"]);
      });
  }


  ForgetPassword(){
    Swal.fire({
      title: '<strong>Contact Administrator</strong>',
      icon: 'info',
      // html:
      //   'You can use <b>bold text</b>, ' +
      //   '<a href="//sweetalert2.github.io">links</a> ' +
      //   'and other HTML tags',
      // showCloseButton: true,
      // showCancelButton: true,
      // focusConfirm: false,
      // confirmButtonText: '<i class="fa fa-thumbs-up"></i> Great!',
      // confirmButtonAriaLabel: 'Thumbs up, great!',
      // cancelButtonText: '<i class="fa fa-thumbs-down"></i>',
      // cancelButtonAriaLabel: 'Thumbs down'
    });
  }
}
