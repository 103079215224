import { NgClass } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { AuthService } from 'src/app/Common/authentication/auth.service/auth.service';
import { SharedService } from 'src/app/Common/service/shared.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit{
  constructor(private msalService:MsalService ,private router:Router ,private sharedService:SharedService,private authService:AuthService){}
  sub:any;
  Manager:boolean;
  Employee:boolean;
  ngOnInit(){
    this.sub=this.sharedService.getEmittedValue().subscribe(item=>this.showManagerSidebar=item);

    this.authService.getUserDetails().subscribe((data:any)=>{console.log("sidebar loading",data);
      console.log(data[0].userType)
    if(data[0].userType=="Admin")
     this.Manager=true;
     else if(data[0].userType=="Employee")
      this.Employee=true;
    })
  }
  logout() {
    this.msalService.logout();
    this.router.navigate(['/']);
  }

  showManagerSidebar=false;

  showValue:boolean=true;
  showValue2:boolean=true;
  
  // hide(){
  //   this.showValue=!this.showValue;
  // }

  // hideProjectManager(){
  //   {
  //     this.showValue2=!this.showValue2;
  //   }
  // }

  empMenulists:any=[
    {Link:'/home/employee-home',icon:'bx bx-home-circle',text:'Dashboard'},
    {Link:'/home/employee-timesheet',icon:'bx bx-calendar ',text:' Time Sheet'},
    {Link:'/home/employee-reimburshment',icon:'bx bx-rupee',text:'Reimbursement'},
    {Link:'/home/employee-leave',icon:'bx bxs-user-x',text:'Leave/WFH'},
  ]

  proManMenulists:any=[
    {Link:'/home/project-manager-home',icon:'bx bx-home-circle',text:'Dashboard'},
    {Link:'/home/project-manager-timesheeet',icon:'bx bx-calendar',text:' Time Sheet'},
    // {Link:'/home/projectmanagerproject',icon:'bx bx-briefcase',text:' Projects'},
    {Link:'/home/project-manager-reimbursement',icon:'bx bx-rupee',text:'Reimbursement'},
    {Link:'/home/project-manager-leave',icon:'bx bxs-user-x',text:'Leave/WFH'},
    
  ]

  showManageTimesheet=false;
  ShowManageTimesheet(){
    this.showManageTimesheet=!this.showManageTimesheet
  }

  showManageReimbursement=false;
  ShowManageReimbursement(){
    this.showManageReimbursement=!this.showManageReimbursement
  }

  showManageLeave=false;
  ShowManageLeave(){
    this.showManageLeave=!this.showManageLeave
  }

  selected=false;
  onSelect() {
    this.selected = !this.selected;
  }
}
