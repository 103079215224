
<!-- start -->
<div class="container-fluid">
  <div class="account-pages my-5 pt-md-5">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-8 col-lg-6 col-xl-5">
          <div class="card overflow-hidden">
            <div class="bg-soft bg-primary">
              <div class="row">
                <div class="col-7">
                  <div class="text-primary p-4">
                    <h5 class="text-primary">Welcome Back !</h5>
                  </div>
                </div>
                <div class="col-5 align-self-end">
                  <img
                    src="assets/images/profile-img.png"
                    alt=""
                    class="img-fluid"
                  />
                </div>
              </div>
            </div>

            <div class="text-center align-items-center card-body pt-5 bg-info text-light">
              <div class="text-center">
                <h1 class="text-light">Scan the QR code</h1>
                <p>
                  Use the Microsoft Authenticator app to scan the QR code.This
                  will connect the Microsoft Authenticator app with your
                  account.
                </p>
                <a class="text-dark"
                  href="https://play.google.com/store/apps/details?id=com.azure.authenticator"
                  >Click here to download app</a
                ><br /><br />
              </div>

              <div class="text-center  ml-5">
                <div>
                  <img [src]="imageSource" alt="Image Source" /></div>
                <br />
                <br />
              </div>
              <div>
                <p>
                  A six digit OTP will be send to your Microsoft Authenticator app.
                  Click proceed and copy the OTP
                </p>
              </div>
              <div class="text-center">
              <button type="button" class="btn btn-primary" (click)="Proceed()">
                Proceed
              </button>
            </div>
            
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
