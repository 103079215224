<div class="container"></div>
<header id="page-topbar">
  <div class="navbar-header">
    <div class="d-flex">
      <div class="navbar-brand-box">
        <a class="logo logo-dark"
          ><span class="logo-sm"
            ><img src="assets/images/logo.svg" alt="" height="22" /></span
          ><span class="logo-lg"
            ><img
              src="../../../assets/images/org-logos_orderstack.png"
              alt=""
              height="27" /></span
        ></a>
      </div>
      <!-- <button
        (click)="sideNavToggle()"
        type="button"
        id="vertical-menu-btn"
        class="btn btn-sm px-3 font-size-16 header-item"
      >
        <i class="fa fa-fw fa-bars"></i>
      </button> -->

      <div
        ngbdropdown=""
        class="dropdown-mega d-none d-lg-block ms-2 dropdown"
      ></div>
    </div>

    <div class="d-flex">
      <div class="dropdown mt-3">
        <button
          class="btn btn-secondary dropdown-toggle btn bg-transparent btn btn-link"
          type="button"
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <img
            src="../../../assets/images/opscape_avatar.png"
            alt="Header Avatar"
            class="rounded-circle header-profile-user"
          />{{userdetails[0].username}}
          
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <a class="dropdown-item" href="#">Action</a>
          <a class="dropdown-item" href="#">Another action</a>
          <a class="dropdown-item" href="#">Something else here</a>
        </div>

        <!-- <button
          type="button"
          
          id="page-header-user-dropdown"
          class="dropdown-toggle btn header-item"
          aria-expanded="false"
        >
          <img
            
            src="../../../assets/images/opscape_avatar.png"
            alt="Header Avatar"
            class="rounded-circle header-profile-user"
          /><span _ngcontent-vpc-c134="" class="d-none d-xl-inline-block ms-1"
            >Arjun</span
          >
        </button> -->
      </div>
      <div  class="mt-1">
        <button
          (click)="logout()"
          _ngcontent-vpc-c134=""
          type="button"
          class="btn header-item noti-icon right-bar-toggle"
        >
           logout
          <i class="bx bx-log-out bx-fade-right mt-0 "></i>
        </button>
      </div>
    </div>
  </div>
</header>
