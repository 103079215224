import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { AuthService } from 'src/app/Common/authentication/auth.service/auth.service';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit{
  
  userdetails:any;
  ngOnInit(): void {
    this.authService.getUserDetails().subscribe((data:any)=>{this.userdetails=data;console.log(this.userdetails)})
    
  }

  constructor(private msalService:MsalService,private router:Router,private authService:AuthService){}
  
  @Output() settingsButtonClicked = new EventEmitter();
  @Output() mobileMenuButtonClicked = new EventEmitter();


  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  toggleRightSidebar() {
    this.settingsButtonClicked.emit();
  }

  logout() {
    this.msalService.logout();
    localStorage.clear();
    }


  
  sideNavToggle(){

  }
}
