import { EventEmitter, Injectable, Output } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  @Output() fire: EventEmitter<any> = new EventEmitter();
  constructor() { }
  
  change() {
     this.fire.emit(true);
   }

   getEmittedValue() {
     return this.fire;
   }

   userRole:any;
   UserRole(role:any){
    if(role=='Admin'){
      console.log(role,'role')
      this.userRole='Admin'
    }
    else if(role=='Employee'){
      this.userRole='Employee';
    }
   }

   SampleMethod(){
    return true;
   }

   IsLoggedIn(){
    return !!localStorage.getItem('AUTH_KEY')
   }

   userrole:any;
   getRole(){
    this.userrole=localStorage.getItem('USER_ROLE')
    return this.userrole
  }
}
