<div class="col-lg-12">
  <div class="card">
    <div class="card-body">
      <h4 class="card-title mb-4">Add Project Details</h4>
      <form [formGroup]="form" (ngSubmit)="AddProjectDetails(form)">
        <aw-wizard [navBarLayout]="'large-empty-symbols'">
          <aw-wizard-step stepTitle="Name">
            <ng-template awWizardStepSymbol>1</ng-template>
            <form></form>
            <div class="row mt-3">
              <div class="col-12">
                <div class="row mb-3">
                  <label class="col-md-3 col-form-label">Project Name</label>
                  <div class="col-md-9">
                    <input
                      class="form-control"
                      formControlName="projectName"
                      type="text"
                      id="txtFirstNameBilling"
                    />
                  </div>
                </div>
                <div class="row mb-3">
                  <label class="col-md-3 col-form-label">Project Owner</label>
                  <div class="col-md-9">
                    <input
                      class="form-control"
                      formControlName="projectOwner"
                      type="text"
                      id="txtLastNameBilling"
                    />
                  </div>
                </div>
                <div class="row mb-3">
                  <label class="col-md-3 col-form-label">Project Manager</label>
                  <div class="col-md-9">
                    <input
                      class="form-control"
                      formControlName="projectName"
                      type="text"
                      id="txtCompanyBilling"
                    />
                  </div>
                </div>
                <div class="row mb-3">
                  <label class="col-md-3 col-form-label">Client Address</label>
                  <div class="col-md-9">
                    <input
                      class="form-control"
                      formControlName="clientAddress"
                      type="text"
                      id="txtCompanyBilling"
                    />
                  </div>
                </div>
                <div class="row mb-3">
                  <label class="col-md-3 col-form-label"
                    >Client Contact Address</label
                  >
                  <div class="col-md-9">
                    <input
                      class="form-control"
                      formControlName="clientContactAddress"
                      type="text"
                      id="txtCompanyBilling"
                    />
                  </div>
                </div>
                <div class="row mb-3">
                  <label class="col-md-3 col-form-label">Country</label>
                  <div class="col-md-9">
                    <input
                      class="form-control"
                      formControlName="country"
                      type="text"
                      id="txtCompanyBilling"
                    />
                  </div>
                </div>
              </div>
            </div>
            <ul class="list-inline wizard mb-0">
              <li class="next list-inline-item float-end">
                <a class="btn btn-primary" awNextStep>Next</a>
              </li>
            </ul>
          </aw-wizard-step>

          <aw-wizard-step stepTitle="Date">
            <ng-template awWizardStepSymbol>2</ng-template>

            <div class="row mt-3">
              <div class="col-12">
                <div class="row mb-3">
                  <label class="col-md-3 col-form-label">Start date</label>
                  <div class="col-md-9">
                    <input
                      class="form-control"
                      formControlName="startDate"
                      type="date"
                    />
                  </div>
                </div>
                <div class="row mb-3">
                  <label class="col-md-3 col-form-label"
                    >Expected End date</label
                  >
                  <div class="col-md-9">
                    <input
                      class="form-control"
                      name="txtNameCard"
                      type="date"
                    />
                  </div>
                </div>
                <div class="row mb-3">
                  <label class="col-md-3 col-form-label">Add Module Name</label>
                  <!-- <div class="col-md-9">
                    <div class="row">
                      <div class="col-md-9">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Enter Module Name..."
                        />
                      </div>
                      <div class="col-md-3 mt-2 mt-md-0 d-grid">
                        <input
                          type="button"
                          class="btn btn-primary inner"
                          value="Remove"
                        />
                      </div>
                    </div>
                  </div> -->
                </div>

                <div class="" formArrayName="items">
                  <div *ngFor="let data of moduleArray; index as i" class="">
                    <div formArrayName="items">
                      <div
                        class="row justify-content-end mb-3"
                      >
                        <label class="col-md-3 col-form-label"></label>
                        <div class="col-md-9">
                          <div class="row">
                            <div class="col-md-9">
                              <input
                                type="text"
                                class="form-control" (input)="AddValue($event)"
                                placeholder="Enter Module Name..."
                                formControlName="moduleElement"
                              />
                            </div>
                            <div class="col-md-3 mt-2 mt-md-0 d-grid">
                              <input
                                type="button"
                                class="btn btn-primary inner"
                                value="Remove"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-md-3"></div>
                  <div class="col-md-9">
                    <input
                      data-repeater-create
                      type="button"
                      class="btn btn-success inner"
                      value="Add More..."
                      (click)="addMore()"
                    />
                  </div>
                </div>
                <!-- <div class="inner mb-0 row" formArrayName="member">
                  <label class="col-form-label col-lg-2">Add Team Member</label>
                  <div class="inner col-lg-10 ms-md-auto" *ngFor="let data of member.controls; index as i;">
                    <div class="mb-3 row align-items-center">
                      <div class="col-md-6">
                        <input type="text" [formControlName]="i" class="inner form-control"
                          placeholder="Enter Name..." />
                      </div>
                      <div class="col-md-4">
                        <div class="mt-4 mt-md-0">
                          <input class="form-control" type="file">
                        </div>
                      </div>
                      <div class="col-md-2">
                        <div class="mt-2 mt-md-0 d-grid">
                          <input type="button" class="btn btn-primary inner" value="Delete"
                            (click)="deleteMember(i)" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div> -->

                <div class="row mb-3">
                  <label class="col-md-3 col-form-label">Sub-module Name</label>
                  <div class="col-md-9">
                    <input
                      class="form-control"
                      formControlName="submoduleName"
                      type="text"
                      id="txtCompanyBilling"
                    />
                  </div>
                </div>
                <div class="row mb-3">
                  <label class="col-md-3 col-form-label">Billable</label>
                  <div class="col-md-3">
                    <div class="input-group">
                      <select
                        class="form-control select2"
                        [(ngModel)]="selectedTeam"
                      >
                        <option value="Clos"></option>
                        <option value="yes">YES</option>
                        <option value="no">NO</option>
                      </select>
                    </div>
                  </div>
                  <label class="col-md-3 col-form-label text-end">Budget</label>
                  <div class="col-md-3">
                    <div class="input-group" *ngIf="selectedTeam === 'yes'">
                      <input
                        type="text"
                        class="form-control"
                        aria-label="Text input with dropdown button"
                      />
                      <select class="form-control select2">
                        <option value="New">INR</option>
                        <option value="New">CAD</option>
                        <option value="Close">USD</option>
                      </select>
                    </div>
                  </div>
                </div>
                <!-- <div class="row mb-3">
                  <label class="col-md-3 col-form-label">Expiration Date</label>
                  <div class="col-md-9">
                    <input id="txtExpirationDate" class="form-control" name="txtExpirationDate" type="text">
                  </div>
                </div> -->
              </div>
            </div>

            <ul class="list-inline wizard mb-0">
              <li class="previous list-inline-item">
                <a class="btn btn-primary" awPreviousStep>Previous</a>
              </li>
              <li class="next list-inline-item float-end">
                <a class="btn btn-primary" awNextStep>Next</a>
              </li>
            </ul>
          </aw-wizard-step>

          <aw-wizard-step stepTitle="Confirm">
            <ng-template awWizardStepSymbol>3</ng-template>
            <div class="row justify-content-center">
              <div class="col-lg-6">
                <div class="text-center">
                  <div class="mb-4">
                    <i
                      class="mdi mdi-check-circle-outline text-success display-4"
                    ></i>
                  </div>
                  <div>
                    <h5>Confirm Detail</h5>
                    <p class="text-muted">
                      Project Details are send to the admin,wait for
                      confirmation
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <ul class="list-inline wizard mb-0">
              <li class="previous list-inline-item">
                <a class="btn btn-primary" awPreviousStep>Previous</a>
              </li>
              <li class="next list-inline-item float-end">
                <button type="submit" class="btn btn-success" awNextStep>
                  Confirm
                </button>
              </li>
            </ul>
          </aw-wizard-step>
        </aw-wizard>
      </form>
    </div>
  </div>
</div>
