import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-pro-manager-project-add',
  templateUrl: './pro-manager-project-add.component.html',
  styleUrls: ['./pro-manager-project-add.component.scss']
})
export class ProManagerProjectAddComponent {
  form:FormGroup
  showLabel = false
  constructor(private fb:FormBuilder){
    this.form=this.fb.group({
      projectName:['',Validators.required],
      projectOwner:['',Validators.required],
      projectManager:['',Validators.required],
      moduleElement:[''],
      moduleArray:this.fb.array([]),
      subModule:['',Validators.required],
      country:['',Validators.required],
      clientAddress:['',Validators.required],
      clientContactAddress:['',Validators.required],
      teamMembers:['',Validators.required],
      startDate:['',Validators.required],
      endDate:['',Validators.required],
      isBillable:['',Validators.required],
      budget:['',Validators.required],
    })
  }

showval=false;
  show(){
    this.showval=true;
  }

  selectedTeam=''
  

  AddProjectDetails(form){
    console.log(form.value,'form value')
    
  }

  // form = new UntypedFormGroup({
  //   member: new UntypedFormArray([
  //     new UntypedFormControl(''),
  //   ]),
  // });
  get member(): UntypedFormArray { return this.form.get('member') as UntypedFormArray; }

  deleteMember(i: number) {
    this.member.removeAt(i);
  }

  moduleArray:any[]=[];
  addMore(){
    this.showLabel = true
    this.moduleArray.push({ value: '', visible: true });
    if(this.form.value.moduleElement != '')
    {
      this.form.value.moduleArray.push(this.form.value.moduleElement);
      console.log(this.form.value.moduleArray)
    }
    
  }
  AddValue(event:any){
     this.form.value.moduleElement = event.target.value
    this.form.value.moduleArray.push(this.form.value.moduleElement);

  }

}
