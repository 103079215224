import { Component } from '@angular/core';

@Component({
  selector: 'app-pro-man-leave-declined',
  templateUrl: './pro-man-leave-declined.component.html',
  styleUrls: ['./pro-man-leave-declined.component.scss']
})
export class ProManLeaveDeclinedComponent {

}
