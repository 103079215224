import { Component } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { AuthService } from "src/app/Common/authentication/auth.service/auth.service";
import { AuthInput } from "src/app/Common/authentication/models/AuthInput";

@Component({
  selector: "app-qr",
  templateUrl: "./qr.component.html",
  styleUrls: ["./qr.component.scss"],
})
export class QrComponent {
  imageSource: any;
  qrImage: any;
  msalToken:any;
  getTokenInput:AuthInput;
  qrCode:any;
  //  image ='iVBORw0KGgoAAAANSUhEUgAAAKsAAACrAQAAAAAxk1G0AAAC2UlEQVR4nOWXP66jMBDGB7lwRy5gyddw5yvBBfhzAbiSO18DyReAzoXF7OdA9r1iizdIW6wWRUn0CzL2zHzfTIj/dG30f+OdqI8b+bRGemV7BGrk+OAyeDWHtBMvRE0EkeNgRi59UJPn3fEcqX+Gg1q0XbQ6ncI2n+E+lIYN0TZm5meYS0dbS3jCNrht/Dq8AL/jbb5e39PwY/y+SpNpjAg8dvetIH6MT6LWgRFpNWeekXw5ZlYzU+d5cVg44a57gyIcqdVpoTIi6rkW0SzHR+TdI+GWs525oJYf4FNDCoqZWoIasFle5Zg5Hdm0ZPE78KnVA7x7nLJAmiPzHOgV7vIRYWa7RmzQkMfLnj49wlvrsLZpsHxWK5L/AIfEKN5MTTAD9O3NS47xObI6Qpo8QpVmPOQJhruYjtSuE2eo6sq8FEOXBUckpxZnJypvh5Dhk3iN5QVZM86Kakq3LiWYM8GlSMOreNH4jjIU4yPaI2P5DZLqM3b3EYkEI959VJPGWZF2e0SEX4yPbOH9kPgYCNGaGccVYyiywdq+wKs44qz2VpoMI0LqYNOhF3Eh/9tMJRimiz7gUIZbq80rpGttEUawJ413qKGMOZ36NjwRxto9pEA0UIIoT9ou6xXho5oc/AkuxWuGnq4alOHTlzGqneyc0RXhfOi0YszVuQuRobdrrmG7Mi/C0OWYzRjt5NAY7X6rQYaPnBaHXmQ6X5O/66t8ZPjUFYwxYUk0E9KX9cowGhpa0IQCRPm4+pzbNSX49Fvna7A7B8vE2leLFuK6R+QcPlFxp+9civCuS+cBoEvcAl3eaRBhXNUhuE6Fc0Ta7UeuAlynsJAgSrS1wdk53o8U4ToPakwKNW8rBsyPmcowCgdDZfWq7RVwyts1pbjPhmoVYwqDMmodPcAdGqPDrIGzlpYeYcz7qBrUMuwBdhXhoGKMeA8omYAewmtAM7n7vAj/zT9k/yb+BeszT8/Lz2tvAAAAAElFTkSuQmCC';
  url: any;
  constructor(
    private sanitizer: DomSanitizer,
    private authService: AuthService,
    private router: Router
  ) { }
  ngOnInit() {
    this.qrImage = localStorage.getItem("QR");
    this.msalToken = localStorage.getItem("PUB_KEY");
    console.log(this.qrImage);
    this.imageSource = this.sanitizer.bypassSecurityTrustResourceUrl(
      ` ${this.qrImage}`
    );
  }

  // displayStyle = "block";

  // displayStyle2 = "none";

  Proceed() {
    this.router.navigate(['/2fa-roadblock'])
    // this.displayStyle2 = "block";
    // this.displayStyle = "none";

  }

  num = '700441'
  // onSubmit(data: any) {
  //   console.log(data.digit)
  // console.log(this.qrCode.toString());
  //   // this.num=data.digit;

  //   this.getTokenInput={pubKey:this.msalToken,otp:this.qrCode.toString()};
  //   this.authService.getAuthToken(this.getTokenInput).subscribe((data1:any)=>{
  //     console.log(data1.token);
  //   if(data1.success){
  //     this.router.navigate(['/home'])
  //   }
  //   else
  //   this.router.navigate(['/invalid'])



  //   });
  // }
}
