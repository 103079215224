import { Component, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AuthService } from "src/app/Common/authentication/auth.service/auth.service";
import Swal from "sweetalert2";
import { IReimbursementStatus } from "./models";
import { IFilterData } from "./models";

@Component({
  selector: "app-pro-manager-reimbursement-request",
  templateUrl: "./pro-manager-reimbursement-request.component.html",
  styleUrls: ["./pro-manager-reimbursement-request.component.scss"],
})
export class ProManagerReimbursementRequestComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private modalService: NgbModal
  ) {}
  ViewReimbursementRequestTable: any;
  projectName: any;
  allUser: any;
  emptyData = false;
  loader:boolean;
  hidePreviousButton: boolean;
  hideNextButton: boolean;
  pageIndex = 1;
  pageSize = 10;

  ngOnInit(): void {
    // this.authService.getReimbursementRequestView().subscribe((data: any) => {
    //   console.log(data);
    //   if (data.length == 0) {
    //     // Swal.fire("Empty", "request!", "warning");
    //     this.emptyData = true;
    //   }
    //   this.ViewReimbursementRequestTable = data;
    //   console.log(this.ViewReimbursementRequestTable, "table reimbursement");
    // });
    this.FilterforApprovedorRejected(this.pageIndex,this.pageSize);

    this.authService.getAllUser().subscribe((data: any) => {
      this.allUser = data;
      console.log(this.allUser, "all users");
    });
  }

  ReimbursementId: string;
  ReimbursementApprovalStatus: IReimbursementStatus;

  decline(reimbursmentId: string) {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger ms-2",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        confirmButtonText: "Yes, reject it!",
        cancelButtonText: "No, cancel!",
        showCancelButton: true,
      })
      .then((result) => {
        if (result.value) {
          this.ReimbursementId = reimbursmentId;

          this.ReimbursementApprovalStatus = {
            isStatus: 'DECLINED',
            ReimbursementId: this.ReimbursementId,
          };
          this.authService
            .getReimbursementApproval(this.ReimbursementApprovalStatus)
            .subscribe((data: any) => {
              console.log(data);
              // this.ReimbursementRequestTable();
              this.FilterforApprovedorRejected(this.pageIndex,this.pageSize);
            });
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Rejected",
              showConfirmButton: false,
              timer: 1300,
            });
        }
      });
  }

  approve(reimbursmentId: string) {
    this.ReimbursementId = reimbursmentId;

    this.ReimbursementApprovalStatus = {
      isStatus: 'APPROVED',
      ReimbursementId: this.ReimbursementId,
    };
    this.authService
      .getReimbursementApproval(this.ReimbursementApprovalStatus)
      .subscribe((data: any) => {
        console.log(data);
        // this.ReimbursementRequestTable();
        this.FilterforApprovedorRejected(this.pageIndex,this.pageSize);
      });
    Swal.fire({
      position: "center",
      icon: "success",
      title: "Approved",
      showConfirmButton: false,
      timer: 1300,
    });
  }

  from = "";
  to = "";
  userId = "";
  reimbursemenType = "";
  filterdata: IFilterData;
  filterView: boolean = false;
  filterData: any;

  Filter() {
    this.loader=true;
    this.pageIndex=1;
    this.filterView = true;

    this.filterdata = {
      From: this.from,
      To: this.to,
      UserId: this.userId,
      ReimbursementType: this.reimbursemenType,
      PageIndex:this.pageIndex,
      PageSize:this.pageSize
    };
    console.log(this.filterdata, "filterdata");
    console.log(this.from, "from");
    this.authService
      .getReimbursmentApprovalViewFilter(this.filterdata)
      .subscribe((data: any) => {
        this.filterData = data;
        this.loader=false;
        if (data.length == 0) {
          Swal.fire({
            title: "No Data Available",
            text: " ",
            icon: "warning",
            cancelButtonColor: "#f46a6a",
          });
          this.emptyData = true;
          this.hideNextButton = true;
          this.hidePreviousButton = true;
        } else if (data != "") {
          this.emptyData = false;
          this.hideNextButton = false;
          this.hidePreviousButton = false;
        }
        if (this.pageIndex == 1  && data != "") {
          this.hidePreviousButton = true;
          this.hideNextButton = false;
        } else if (this.pageIndex != 1 && data == "") {
          this.hideNextButton = true;
          this.hidePreviousButton = false;
        }
      });
  }



  FilterforApprovedorRejected(pageIndex, pageSize) {
    this.loader=true;
    this.filterView = true;
    this.emptyData=false;

    this.filterdata = {
      From: this.from,
      To: this.to,
      UserId: this.userId,
      ReimbursementType: this.reimbursemenType,      
      PageIndex:pageIndex,
      PageSize:pageSize
    };
    console.log(this.filterdata, "filterdata");
    console.log(this.from, "from");
    this.authService
      .getReimbursmentApprovalViewFilter(this.filterdata)
      .subscribe((data: any) => {
        this.filterData = data;
        this.loader=false
        if (data.length == 0) {
          this.emptyData = true;
          this.hideNextButton = true;
          this.hidePreviousButton = true;
        } else if (data != "") {
          this.emptyData = false;
          this.hideNextButton = false;
          this.hidePreviousButton = false;
        }
        if (this.pageIndex == 1  && data != "") {
          this.hidePreviousButton = true;
          this.hideNextButton = false;
        } else if (this.pageIndex != 1 && data == "") {
          this.hideNextButton = true;
          this.hidePreviousButton = false;
        }
      });
  }

  next() {
    this.pageIndex = this.pageIndex + 1;
    this.FilterforApprovedorRejected(this.pageIndex, this.pageSize);
  }

  previous() {
    this.pageIndex = this.pageIndex - 1;
    this.FilterforApprovedorRejected(this.pageIndex, this.pageSize);
  }

  ClearFilter() {
    this.to = "";
    this.from = "";
    this.userId = "";
    this.reimbursemenType = "";
    this.emptyData = false;
    this.Filter();
  }

  base64String: any;
  Document: any;
  Docshow(id) {
    console.log(id, "parameter value");
    // for (var i = 0; i < data1.length; i++) {
    //   if (id == this.data1[i].reimbursmentId) {
    //     this.Document = this.data1[i].attachment;
    //   }
    // }
    this.filterData.forEach((element) => {
      if (id == element.reimbursmentId) {
        this.Document = element.attachment;
      }
    });
    console.log(this.Document);

    this.base64String = this.Document.split(",");
    this.base64String = this.base64String[1];
    console.log("this.base^$", this.base64String);
    var blob = this.b64toBlob(this.base64String, "application/pdf");
    console.log("blob", blob);
    let a = document.createElement("a");
    document.body.appendChild(a);
    // var url = window.URL.createObjectURL(blob);
    var url = URL.createObjectURL(
      new Blob([blob], { type: "application/pdf" })
    );
    a.href = url;
    a.download = String("Download.pdf");
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }
  public b64toBlob(b64Data, contentType) {
    contentType = contentType || "";
    let sliceSize = 512;
    console.log(b64Data);
    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  popupViewDetails(content: any) {
    this.modalService.open(content, { size: "md", centered: true });
  }

  Id: any;
  element: any;
  viewDetails(id) {
    this.ViewReimbursementRequestTable.forEach((elements) => {
      if (id == elements.reimbursmentId) {
        this.element = elements;
        console.log(this.element, "reimbursement details");
      }
    });
  }

  viewDetailsforFilterData(id) {
    console.log(id, "filter id");
    this.filterData.forEach((elements) => {
      if (id == elements.reimbursmentId) {
        this.element = elements;
        console.log(this.element, "reimbursement details for filter data");
      }
    });
  }

  page = 1;
  count = 0;
  tableSize = 10;
  tableSizes = [3, 6, 9, 12];

  onTableDataChange(event) {
    this.page = event;
    // this.TimesheetTable();
  }
}
