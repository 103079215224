import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/Common/authentication/auth.service/auth.service';
import { FormBuilder, FormGroup, Validators , FormControl} from '@angular/forms';

@Component({
  selector: 'app-pro-man-declined-show',
  templateUrl: './pro-man-declined-show.component.html',
  styleUrls: ['./pro-man-declined-show.component.scss']
})
export class ProManDeclinedShowComponent implements OnInit {
  constructor(private authServce:AuthService,private formBuilder: FormBuilder){}

  data:any;
  
  ngOnInit(): void {
    this.authServce.getProManDeclinedTimesheet().subscribe((data:any)=>{this.data=data;
    console.log(this.data)})
      this.submitForm = this.formBuilder.group({
        email: [null, [Validators.required, Validators.email]],
        password: [null, Validators.required],
      });
  }

  date2=''
  project=''
  moduleId=''
  submoduleId=''
  hours=''
  billable=''
  details=''
  submitForm: FormGroup;

  submitted=false
  submit(){
    this.submitted = true;
    this.submitForm.markAllAsTouched();
  }
  
  name=''
  onSubmit(){
    console.log('aaaaaaaaaaaaaaa')
  }
}
