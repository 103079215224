<!-- <div *ngIf="loader">
  <app-loader></app-loader>
</div> -->
<div class="container-fluid">
  <h3 class="text-center mb-3 mt-1">TimeSheet History</h3>
  <div class="row d-flex justify-content-evenly g-3">
    <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3">
      <select
        class="form-control select2"
        [(ngModel)]="userId"
        placeholder="aaa"
      >
        <option value="" selected>Employee Name</option>
        <option *ngFor="let d of allUser" value="{{ d.userID }}">
          {{ d.userName }}
        </option>
      </select>
    </div>
    <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3">
      <select
      (change)="ProjectOptionSelected($event.target.value)"
        class="form-control select2"
        [(ngModel)]="ngprojectname"
        placeholder="aaa"
      >
        <option value="" selected>Project Name</option>
        <option *ngFor="let d of projectName" value="{{ d.projectId }}">
          {{ d.projectName }}
        </option>
      </select>
      <span class="validation" *ngIf="validationModule" >Please select a project.</span>
    </div>
    <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3">
      <select
      (change)="ModuleOptionSelected($event.target.value)"
      (click)="validationForModule()"
        class="form-control select2"
        [(ngModel)]="ngmodulename"
        placeholder="aaa"
      >
        <option value="" selected>Module Name</option>
        <option *ngFor="let d of DropdownModule" [value]="d.moduleId">
          {{ d.module }}
        </option>
      </select>
      <span class="validation" *ngIf="validationSubmodule" >Please select a module.</span>
    </div>
    <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3">
      <select
      (click)="validationForSubmodule()"
        class="form-control select2"
        [(ngModel)]="ngsubmodulename"
        placeholder="aaa"
      >
        <option value="" selected>Sub-module Name</option>
        <option *ngFor="let d of DropdownSubModule" [value]="d.subModuleId">
          {{ d.subModule }}
        </option>
      </select>
    </div>

    <div class="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-2">
      <div id="datepicker1">
        <input
          type="text"
          class="form-control"
          placeholder="From"
          onfocus="(this.type='date')"
          [(ngModel)]="from"
        />
      </div>
      <!-- input-group -->
    </div>
    <div class="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-2">
      <div id="datepicker1">
        <input
          type="text"
          class="form-control"
          placeholder="To"
          onfocus="(this.type='date')"
          [(ngModel)]="to"
        />
      </div>
      <!-- input-group -->
    </div>
    <div class="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-2">
      <select
        class="form-control select2"
        [(ngModel)]="isStatus"
        placeholder="aaa"
      >
        <option value="" disabled selected>Status</option>
        <!-- <option value="" >None</option> -->
        <option  value="APPROVED">Approved</option>
        <option  value="DECLINED">Rejected</option>
      </select>
    </div>
    <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3">
      <button (click)="Filter()" type="button" class="btn btn-secondary w-100">
        <i class="mdi mdi-filter-outline align-middle"></i> Filter
      </button>
    </div>
    <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3">
      <button
        (click)="ClearFilter()"
        type="button"
        class="btn btn-secondary w-100"
      >
        <i class=""></i> Clear Filter
      </button>
    </div>
  </div>
  <div *ngIf="!emptyData" class="table-responsive">
    <br />
    <table class="table table-bordered align-middle nowrap">
      <thead>
        <tr class="text-center">
          <th style="width: 1%" scope="col">No.</th>
          <th style="width: 10%" scope="col">Employee Name</th>
          <th style="width: 20%" scope="col">Requested Date</th>
          <th style="width: 19%" scope="col">Reviewed Date</th>
          <th style="width: 5%" scope="col">Project</th>
          <th style="width: 5%" scope="col">Module</th>
          <th style="width: 5%" scope="col">Submodule</th>
          <th style="width: 5%" scope="col">Description</th>
          <th style="width: 5%" scope="col">Hours</th>
          <th style="width: 10%" scope="col">Billable</th>
          <th style="width: 10%" scope="col">Status</th>
          <th style="width: 10%" scope="col">Action</th>
          <!-- <th scope="col">Status</th>
          <th scope="col">Action</th> -->
        </tr>
      </thead>
      <tbody>
        
        <tr *ngFor="let data of filterTable
    index as i
  " class="text-center">
          <td>{{  ((this.pageSize*this.pageIndex)-this.pageSize)+i+1 }}</td>
          <td>{{ data.employeeName }}</td>
          <td>{{ data.dateOfTimeSheet | date : "dd-MMM-yyyy" }}</td>
          <td>{{ data.approvedDate | date : "dd-MMM-yyyy" }}</td>
          <td>
            <h5>
              <span class="badge badge-soft-info">{{ data.project }}</span>
            </h5>
          </td>
          <td>{{ data.module }}</td>
          <td>{{ data.submodule }}</td>
          <td>{{ data.details }}</td>
          <td>{{ data.hours }}</td>
          <td>
            <h5>
              <span
                class="badge"
                [ngClass]="{
                  'bg-success': data.isBillable === true,
                  'bg-info': data.isBillable === false
                }"
              >
                <span *ngIf="data.isBillable == true">Yes</span>
                <span *ngIf="data.isBillable == false">No</span>
              </span>
            </h5>
          </td>
          <td>
            <h5>
              <span
                class="badge"
                [ngClass]="{
                  'rounded-pill bg-info': data.is_Status === 'APPROVED',
                  'rounded-pill bg-danger': data.is_Status === 'DECLINED'
                }"
              >
                <span *ngIf="data.is_Status == 'APPROVED'">Approved</span>
                <span *ngIf="data.is_Status == 'DECLINED'">Rejected</span></span
              >
            </h5>
          </td>
          <td>
            <button
              type="button"
              class="btn btn-primary btn-sm btn-rounded"
              (click)="
              viewDetailsforFilterData(data.timeSheetId);
                popupViewDetails(viewDetailsContent)
              "
            >
              View Details
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    
  </div>
  <div *ngIf="emptyData" class="no-data-cell">No History to Show</div>
</div>
<div id="" *ngIf="loader">
  <div >
    <div class="" id="status">
      <div class="spinner-chase">
        <div class="chase-dot"></div>
        <div class="chase-dot"></div>
        <div class="chase-dot"></div>
        <div class="chase-dot"></div>
        <div class="chase-dot"></div>
        <div class="chase-dot"></div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!loader" class="d-flex justify-content-center mb-3">

  <div class="mr-4">
    <button *ngIf="!hidePreviousButton" (click)="previous()" class="btn btn-light btn-sm"><< Prev </button>
  </div>
  <div>  </div>
  <div>
    <button *ngIf="!hideNextButton" (click)="next()" class="btn btn-light btn-sm">Next >></button>
  </div>
</div>

<ng-template #viewDetailsContent role="document" let-modal>
  <div class="modal-header border-bottom-0">
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    ></button>
  </div>
  <div class="card">
    <div class="card-body">
      <h4 class="card-title text-center mb-3">TimeSheet Details</h4>
      <div class="table-responsive">
        <table class="table table-centered table-nowrap">
          <thead class="viewDetails">
            <tr>
              <td>TimeSheet ID</td>
              <td>{{ element.timeSheetId }}</td>
            </tr>
            <tr>
              <td>Reviewed by</td>
              <td *ngIf="element.approvedByName != null">
                {{ element.approvedByName }}
              </td>
              <td *ngIf="element.approvedByName == null">Not reviewed yet</td>
            </tr>
            <tr>
              <td>Date of TimeSheet</td>
              <td>{{ element.dateOfTimeSheet | date : "dd-MMM-yyyy" }}</td>
            </tr>
            <tr>
              <td>Reviewed Date</td>
              <td *ngIf="element.approvedDate != null">
                {{ element.approvedDate | date : "dd-MMM-yyyy" }}
              </td>
              <td *ngIf="element.approvedDate == null">Not reviewed yet</td>
            </tr>
            <tr>
              <td>Day</td>
              <td>{{ days[element.day] }}</td>
            </tr>
            <tr>
              <td>Project</td>
              <td>{{ element.project}}</td>
            </tr>
            <tr>
              <td>Module</td>
              <td>{{ element.module}}</td>
            </tr>
            <tr>
              <td>Submodule</td>
              <td>{{ element.submodule}}</td>
            </tr>
            <tr>
              <td>Status</td>
              <td
                *ngIf="
                  element.is_Status == 'APPROVED'
                "
              >
                Approved
              </td>
              <td
                *ngIf="
                  element.is_Status == 'PENDING'
                "
              >
                Pending
              </td>
              <td
                *ngIf="
                  element.is_Status == 'DECLINED'
                "
              >
                Rejected
              </td>
            </tr>
          </thead>
        </table>
      </div>
    </div>
  </div>
</ng-template>
