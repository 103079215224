import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "../Common/authentication/auth.service/auth.service";
import { SharedService } from "../Common/service/shared.service";
import { Qr2Component } from "../Pages/qr2/qr2.component";

@Injectable({
  providedIn: "root",
})
export class AuthGuard2Guard implements CanActivate 
// {
//   constructor(
//     private router: Router,
//     private sharedService: SharedService // private qr2componenet:Qr2Component
//   ) {}
//   canActivate() {
//     const role = this.sharedService.SampleMethod();
//     console.log(role, "role");
//     if (localStorage.getItem("AUTH_KEY")) {
//       if (localStorage.getItem("USER_ROLE") == "Admin") {
//         console.log("if user role");
//         this.router.navigate(["/home/project-manager-home"]);
//         return false;
//       } 
//       else if (localStorage.getItem("USER_ROLE") == "Employee") {
//         this.router.navigate(["/home/employee-home"]);
//         return false;
//       }
//     } 
//     else {
//       return true;
//     }
//   }

  
// }

{

  constructor(private sharedService:SharedService,private router:Router){}
  canActivate(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    let url: string = state.url;
    
    return this.checkUserLogin(route,url);
  }

  checkUserLogin(route: ActivatedRouteSnapshot, url: any): boolean {
    if (this.sharedService.IsLoggedIn()) {
      const userRole = this.sharedService.getRole();
      // console.log(route.data['role'].indexOf())
      if (route.data['role'] && route.data['role'].indexOf(userRole) === -1) {
        this.router.navigate(['/login']);
        return false;
      }
      return true;
    }

    this.router.navigate(['/login']);
    return false;
  }
  
}
