<div *ngIf="loader">
  <app-loader></app-loader>
</div>
<div class="container">
  <div class="modal-header">
    <div class="container rounded-5 bg-info">
      <!-- <div
        class="modal mt-5"
        tabindex="-1"
        role="dialog"
        [ngStyle]="{ display: displayStyle2 }"
      > -->
      <div class="modal mt-5"></div>

      <div class="modal-dialog" role="document">
        <div class="modal-content bg-color rounded-5">
          <div class="modal-header">
            <div class="mx-auto d-block p-4">
              <img
                class="img-fluid"
                src="/assets/images/brands/org-logos_orderstack.png"
                alt=""
                height="14"
              />
            </div>
          </div>
          <div class="">
            <div>
              <br />
              <h3>Two-step verification</h3>
              <br />
              <p>
                Enter the 6-digit code from the authenticator app on your phone
              </p>
            </div>
            <!-- <input
                  type="nu"
                  name="digit"
                  class="form-control input-lg"
                  placeholder="Enter 6 digit code"
                  ngModel
                /> -->

            <!-- <input
                id="partitioned"
                type=""
                [(ngModel)]="qrCode"
                name="qr"
                maxlength="6"
              />
              {{qrCode}} -->
            <div class="d-flex justify-content-center">
              <div class="container2">
                <form (ngSubmit)="onSubmit()">
                  <code-input
                    [(ngModel)]="qrCode"
                    [isCodeHidden]="false"
                    [codeLength]="6"
                    (codeChanged)="onCodeChanged($event)"
                    (codeCompleted)="onCodeCompleted($event)"
                    [isPrevFocusableAfterClearing]="false"
                    [isNextFocusableAfterFilling]="false"
                  >
                  </code-input>
                  <br />
                  <button
                    type="submit"
                    
                    
                    class="btn btn-primary mt-1"
                  >
                    Verify
                  </button>
                  <br />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- </div> -->
    </div>
  </div>
</div>

<!-- popup -->
<div class="container">
  <div class="overlay" *ngIf="popup">
    <div class="popup">
      <h2>Sorry, Invalid OTP</h2>
      <a class="close" (click)="popup = false">&times;</a>
      <!-- <div class="content">you have selected this event</div> -->
    </div>
  </div>
</div>
