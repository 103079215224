import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { an } from "@fullcalendar/core/internal-common";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AuthService } from "src/app/Common/authentication/auth.service/auth.service";
import { ChartType } from "./project.model";

@Component({
  selector: "app-pro-manager-home",
  templateUrl: "./pro-manager-home.component.html",
  styleUrls: ["./pro-manager-home.component.scss"],
})
export class ProManagerHomeComponent implements OnInit {
  data1: any;
  casualCount:any;
  sickCount:any;
  wfhCount:any;
  projectName: any;
  noOfProjects: number;
  reimbursementStatus: any;
  approved:any;
  pending:any;
  timesheetStatus: any;
  reviewed: any;
  overdue:any
  graphValues: any;
  graphArray :any = [];
  lineBarChart: any;
  loader=true;
  userName:any;
  userType:any;

  ngOnInit(): void {
    this.authService.getDisplayLeaveDetails().subscribe((data: any) => {
      this.data1 = data;
      if (this.data1 == null) {
        console.log("Iffffff");
        this.data1.totalLeave[0] = 0;
        this.data1.causualCount[0] = 0;
        this.data1.sickCount[0] = 0;
      } else {
        this.casualCount = data.causualCount;
        this.sickCount = data.sickCount;
        this.wfhCount = data.wfh;
        console.log("else");
      }
      console.log(this.data1);
    });

    this.authService.getProjectName().subscribe((data: any) => {
      this.projectName = data;
      console.log("this.projectName", this.projectName);
      this.noOfProjects = data.length;
      console.log("data.length", data.length);
    });

    this.authService.getReimbursementStatus().subscribe((data: any) => {
      this.approved=data.approved;
      this.pending=data.pending;
      this.reimbursementStatus = data;
      this.loader=false;
      console.log(this.reimbursementStatus, "reimbursement data");
    });

    this.authService.getTimesheetStatus().subscribe((data: any) => {
      this.timesheetStatus = data;
      console.log(this.timesheetStatus);
      console.log(this.timesheetStatus.approved);
      this.reviewed = this.timesheetStatus.approved + this.timesheetStatus.decline;
        this.overdue=this.timesheetStatus.overdue
    });

    this.authService.getUserDetails().subscribe((data: any) => {
      this.userName=data[0].username
      this.userType=data[0].userType
    });

    this.authService.getShowGraph().subscribe((data: any) => {
      console.log(data, "graph values");
      this.graphValues = data;
      console.log(this.graphValues);
      console.log(this.graphValues[0].hours1, "hours1");
      var numbers = new Array();
      console.log(numbers);
      var num = numbers.push(this.graphValues[0].hours1);
      num = numbers.push(this.graphValues[0].hours2);
      num = numbers.push(this.graphValues[0].hours3);
      num = numbers.push(this.graphValues[0].hours4);
      num = numbers.push(this.graphValues[0].hours5);
      num = numbers.push(this.graphValues[0].hours6);
      num = numbers.push(this.graphValues[0].hours7);
      console.log(numbers);
      console.log("numbers",numbers)
      numbers.forEach(element => {
        this.graphArray.push(element)
      });
      // this.graphArray = numbers;
      console.log(this.graphArray, "graph array");
      this.loader=false;
    });

    this.lineBarChart = {
      labels: [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ],
      datasets: [
        {
          label: "In Hours",
          backgroundColor: "rgba(0, 0, 213, 0.43)",
          borderColor: "rgba(52, 195, 143, 0.8)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(52, 195, 143, 0.9)",
          hoverBorderColor: "rgba(52, 195, 143, 0.9)",
          data: this.graphArray,
          barPercentage: 0.4,
        },
      ],
      options: {
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              gridLines: {
                color: "rgba(166, 176, 207, 0.1)",
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                color: "rgba(166, 176, 207, 0.1)",
              },
            },
          ],
        },
      },
    };
  }
  constructor(
    private router: Router,
    private modalService: NgbModal,
    private authService: AuthService
  ) {}

  submitted: boolean = true;
  popup = false;

  // BarChart

  //
}
