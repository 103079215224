<!-- <form [formGroup]="form">
  <div class="mb-3 row">
    <label for="example-date-input" class="col-md-2 col-form-label">Date</label>
    <div class="col-md-10">
      <input formControlName="name"   
        required
        class="form-control"
        type="date"
        value="2019-08-19"
        id="example-date-input"
        [(ngModel)]="date2"
      />
    </div>
  </div>
  <div class="mb-3 row d-flex">
    <label for="example-search-input" class="col-md-2 col-form-label"
      >Projects</label
    >
    <div class="col-md-10">
      <select class="form-select" [(ngModel)]="project">
        <option value="">projectname</option>
      </select>
    </div>
  </div>

  <div class="mb-3 row d-flex">
    <label for="example-search-input" class="col-md-2 col-form-label"
      >Module</label
    >
    <div class="col-md-10">
      <select class="form-select" [(ngModel)]="moduleId">
        <option value=""></option>
      </select>
    </div>
  </div>
  <div class="mb-3 row">
    <label for="example-search-input" class="col-md-2 col-form-label"
      >Sub-Module</label
    >
    <div class="col-md-10">
      <select class="form-select" [(ngModel)]="submoduleId">
        <option value=""></option>
      </select>
    </div>
  </div>
  <div class="mb-3 row">
    <label for="example-search-input" class="col-md-2 col-form-label"
      >Hours</label
    >
    <div class="col-md-10">
      <input class="form-control" type="number" value=" " [(ngModel)]="hours" />
    </div>
  </div>
  <div class="mb-3 row">
    <label class="col-md-2 col-form-label">Billable</label>
    <div class="col-md-10">
      <select class="form-select" [(ngModel)]="billable">
        <option>Yes</option>
        <option>No</option>
      </select>
    </div>
  </div>

  <div class="mb-3 row">
    <label for="example-email-input" class="col-md-2 col-form-label"
      >Any details?</label
    >
    <div class="col-md-10">
      <textarea
        class="form-control"
        type="textarea"
        value=" "
        id="example-email-input"
        [(ngModel)]="details"
      >
      </textarea>
    </div>
  </div>
  <div class="text-center mt-4">
    <button type="button" class="btn btn-primary">Add Time Sheet</button>
  </div>
</form> -->

<form #form="ngForm">
  <input type="text" [(ngModel)]="name" name="name" required>
  <button type="submit" [disabled]="form.invalid">Submit</button>
</form>