<!-- <div *ngIf="loader">
  <app-loader></app-loader>
</div> -->
<div class="container-fluid"></div>
<div class="card-body">
  <!-- begin -->
  <h3 class="text-center mb-3 mt-0">Reimbursement</h3>
  <div class="card-body border-bottom">
    <div class="row g-3">
      <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3">
        <select
          class="form-control select2"
          [(ngModel)]="projectId"
          placeholder="aaa"
        >
          <option value="" disabled selected>Project Name</option>
          <option *ngFor="let d of projectName" value="{{ d.projectId }}">
            {{ d.projectName }}
          </option>
        </select>
      </div>
      <div class="col-xxl-3 col-lg-3 col-md-3 col-sm-3">
        <select class="form-control select2" [(ngModel)]="reimbursemenType">
          <option value="" disabled selected>Type</option>
          <option value="Food">Food</option>
          <option value="Travel Allowance">Travel Allowance</option>
          <option value="Others">Others</option>
        </select>
      </div>
      <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3">
        <div id="datepicker1">
          <input
            type="text"
            class="form-control"
            placeholder="From"
            onfocus="(this.type='date')"
            [(ngModel)]="from"
          />
        </div>
        <!-- input-group -->
      </div>
      <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3">
        <div id="datepicker1">
          <input
            type="text"
            class="form-control"
            placeholder="To"
            onfocus="(this.type='date')"
            [(ngModel)]="to"
          />
        </div>
        <!-- input-group -->
      </div>
      <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3">
        <button
          (click)="Filter()"
          type="button"
          class="btn btn-secondary w-100"
        >
          <i class="mdi mdi-filter-outline align-middle"></i> Filter
        </button>
      </div>
      <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3">
        <button
          (click)="ClearFilter()"
          type="button"
          class="btn btn-secondary w-100"
        >
          <i class="mdi mdi-filter-outline align-middle"></i> Clear Filter
        </button>
      </div>
      <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 text-end">
        <button
          type="button"
          class="btn btn-success btn-rounded mb-2 me-2"
          (click)="addExpense(content)"
        >
          <i class="mdi mdi-plus mr-1"></i> Add Reimbursement
        </button>
      </div>
    </div>
  </div>
  <!-- ends  -->
  <div *ngIf="!emptyData" class="table-responsive">
    <br />
    <table *ngIf="!loader" class="table table-bordered align-middle nowrap">
      <thead>
        <tr class="text-center">
          <th style="width: 5%" scope="col">No</th>
          <th style="width: 15%" scope="col">Reimbursement Date</th>
          <th style="width: 15%" scope="col">Requested On</th>
          <th style="width: 10%" scope="col">Type</th>
          <th style="width: 5%" scope="col">Details</th>
          <th style="width: 10%" scope="col">Project</th>
          <th style="width: 10%" scope="col">Attachment</th>
          <th style="width: 10%" scope="col">Amount</th>
          <th style="width: 10%" scope="col">Status</th>
          <th style="width: 15%" scope="col">View Details</th>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let data of filterData; index as i" class="text-center">
          <td scope="row">
            {{ this.pageSize * this.pageIndex - this.pageSize + i + 1 }}
          </td>
          <td>{{ data.dateOfReimbursement | date : "dd-MMM-yyyy" }}</td>
          <td>{{ data.dateOfRequest | date : "dd-MMM-yyyy" }}</td>
          <td>
            <h5>
              <span
                class="badge"
                [ngClass]="{
                  'bg-success': data.reimbursementType === 'Travel Allowance',
                  'bg-info': data.reimbursementType === 'Food',
                  'bg-primary': data.reimbursementType === 'Others'
                }"
                >{{ data.reimbursementType }}</span
              >
            </h5>
          </td>
          <td>
            <span *ngIf="data.details != ''">{{ data.details }}</span>
            <span *ngIf="data.details == ''">-nil-</span>
            <span *ngIf="data.details == null">-nil-</span>
          </td>
          <td>{{ data.project }}</td>
          <td *ngIf="data.attachment != null">
            <a (click)="Docshow(data.reimbursmentId)"
              ><i class="bx bxs-file-pdf bx-md"></i
            ></a>
          </td>
          <td>{{ data.amount | currency : "INR" }}</td>
          <td>
            <h5>
              <span
                class="badge"
                [ngClass]="{
                  'rounded-pill bg-info': data.isStatus === 'APPROVED',
                  'rounded-pill bg-warning': data.isStatus === 'PENDING',
                  'rounded-pill bg-danger': data.isStatus === 'DECLINED'
                }"
              >
                <span *ngIf="data.isStatus == 'APPROVED'">Approved</span>
                <span *ngIf="data.isStatus == 'PENDING'">Pending</span>
                <span *ngIf="data.isStatus == 'DECLINED'">Rejected</span>
              </span>
            </h5>
          </td>
          <td>
            <button
              type="button"
              class="btn btn-primary btn-sm btn-rounded"
              (click)="
                viewDetailsforFilterData(data.reimbursmentId);
                popupViewDetails(viewDetailsContent)
              "
            >
              View Details
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div *ngIf="emptyData" class="no-data-cell">No Data Available</div>
  <div id="" *ngIf="loader">
    <div>
      <div class="" id="status">
        <div class="spinner-chase">
          <div class="chase-dot"></div>
          <div class="chase-dot"></div>
          <div class="chase-dot"></div>
          <div class="chase-dot"></div>
          <div class="chase-dot"></div>
          <div class="chase-dot"></div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!loader" class="d-flex justify-content-center mb-3">
    <div class="mr-4">
      <button
        *ngIf="!hidePreviousButton"
        (click)="previous()"
        class="btn btn-light btn-sm"
      >
        << Prev
      </button>
    </div>
    <div></div>
    <div>
      <button
        *ngIf="!hideNextButton"
        (click)="next()"
        class="btn btn-light btn-sm"
      >
        Next >>
      </button>
    </div>
  </div>
  <!--end row-->

  <!-- popup start-->
  <ng-template #content role="document" let-modal>
    <div class="modal-header border-bottom-0">
      <button
        type="button"
        class="btn-close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click'); close()"
      ></button>
    </div>
    <div class="card">
      <div class="card-body">
        <h4 class="card-title text-center mb-3">Reimbursement</h4>

        <form [formGroup]="form" (ngSubmit)="RequestReimbursement()">
          <!-- first -->
          <div class="mb-3 row">
            <label class="col-md-2 col-form-label asterisk">Type</label>
            <div class="col-md-10">
              <select class="form-select" formControlName="type">
                <option value="Food">Food</option>
                <option value="Travel Allowance">Travel Allowance</option>
                <option value="Others">Others</option>
              </select>
            </div>
          </div>
          <!-- second -->
          <div class="mb-3 row">
            <label
              for="example-date-input"
              class="col-md-2 col-form-label asterisk"
              >Date</label
            >
            <div class="col-md-10">
              <input
                class="form-control"
                type="date"
                value="2019-08-19"
                id="example-date-input"
                formControlName="date"
              />
            </div>
          </div>
          <!-- third -->
          <div class="mb-3 row">
            <label
              for="example-search-input"
              class="col-md-2 col-form-label asterisk"
              >Project</label
            >
            <div class="col-md-10">
              <select class="form-select" formControlName="projectid">
                <option value="" selected>Choose Project</option>
                <option
                  *ngFor="let data of ProjectName"
                  [value]="data.projectId"
                >
                  {{ data.projectName }}
                </option>
              </select>
            </div>
          </div>
          <!-- fourth -->
          <div class="mb-3 row">
            <label for="example-email-input" class="col-md-2 col-form-label"
              >Details</label
            >
            <div class="col-md-10">
              <input
                class="form-control"
                type=""
                value=" "
                id="example-email-input"
                formControlName="details"
              />
            </div>
          </div>
          <!-- fifth -->
          <div class="mb-3 row">
            <label
              for="example-url-input"
              class="col-md-2 col-form-label asterisk"
              >Amount</label
            >
            <div class="col-md-10 field-group">
              <input
                class="form-control input-field"
                type="number"
                value="&#8360; 1.00"
                id="example-url-input"
                step="any"
                formControlName="amount"
                min="1"
                oninput="validity.valid||(value='');"
              />
              <div class="icon">
                <div class="bx bx-rupee"></div>
              </div>
            </div>
          </div>
          <!-- sixth -->

          <div class="mb-3 row">
            <label
              for="example-url-input"
              class="col-md-2 col-form-label asterisk"
              >Upload file</label
            >
            <div class="col-md-10">
              <div class="file-input-wrapper">
                <input
                  type="file"
                  (change)="onFileChange($event)"
                  #fileInput
                  accept=".pdf"
                  formControlName="pdf"
                />
                <div class="file-input-name" *ngIf="fileNameShow">
                  {{ fileName }}
                </div>
                <a class="file-input-button" (click)="fileInput.click()"
                  >Choose File</a
                >
                <p>Only pdf files are allowed *</p>
              </div>
            </div>
          </div>

          <!-- button -->
          <div class="text-center mt-4">
            <button
              (click)="modal.close()"
              type="submit"
              class="btn btn-primary"
              [disabled]="!form.valid"
            >
              Request Reimbursement
            </button>
          </div>
        </form>
      </div>
    </div>
  </ng-template>
  <!-- popup ends-->
</div>

<ng-template #viewDetailsContent role="document" let-modal>
  <div class="modal-header border-bottom-0">
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="close(); modal.dismiss('Cross click')"
    ></button>
  </div>
  <div class="card">
    <div class="card-body">
      <h4 class="card-title text-center mb-3">Reimbursement Details</h4>
      <div class="table-responsive">
        <table class="table table-centered table-nowrap">
          <thead class="viewDetails">
            <tr>
              <td>Reimbursement ID</td>
              <td>{{ element.reimbursmentId }}</td>
            </tr>
            <tr>
              <td>Reviewed by</td>
              <td *ngIf="element.approvedByName != null">
                {{ element.approvedByName }}
              </td>
              <td *ngIf="element.approvedByName == null">Not reviewed yet</td>
            </tr>
            <tr>
              <td>Entered Date</td>
              <td>{{ element.dateOfRequest | date : "dd-MMM-yyyy" }}</td>
            </tr>
            <tr>
              <td>Reviewed Date</td>
              <td *ngIf="element.approvedDate != '0001-01-01T00:00:00'">
                {{ element.approvedDate | date : "dd-MMM-yyyy" }}
              </td>
              <td *ngIf="element.approvedDate == '0001-01-01T00:00:00'">
                Not reviewed yet
              </td>
            </tr>
            <tr>
              <td>Details</td>
              <td>
                <span *ngIf="element.details != ''">{{ element.details }}</span>
                <span *ngIf="element.details == ''">-nil-</span>
                <span *ngIf="element.details == null">-nil-</span>
              </td>
            </tr>
            <tr>
              <td>Project</td>
              <td>{{ element.project }}</td>
            </tr>
            <tr>
              <td>Amount</td>
              <td>{{ element.amount | currency : "INR" }}</td>
            </tr>
            <tr>
              <td>Status</td>
              <td *ngIf="element.isStatus == 'APPROVED'">Approved</td>
              <td *ngIf="element.isStatus == 'PENDING'">Pending</td>
              <td *ngIf="element.isStatus == 'DECLINED'">Rejected</td>
            </tr>
          </thead>
        </table>
      </div>
    </div>
  </div>
</ng-template>
<!-- View Details popup ends -->
