import { Component } from '@angular/core';

@Component({
  selector: 'app-invalid-otp',
  templateUrl: './invalid-otp.component.html',
  styleUrls: ['./invalid-otp.component.scss']
})
export class InvalidOtpComponent {

}
