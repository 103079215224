import { Component, OnInit ,ChangeDetectorRef } from "@angular/core";
import { TitleStrategy } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AuthService } from "src/app/Common/authentication/auth.service/auth.service";
import { BillableorNot , filterData, timesheet } from "./emp-timesheet-model";
import { DatePipe } from "@angular/common";
import Swal from "sweetalert2";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";


@Component({
  selector: "app-emp-time-sheet",
  templateUrl: "./emp-time-sheet.component.html",
  styleUrls: ["./emp-time-sheet.component.scss"],
})
export class EmpTimeSheetComponent implements OnInit {
  form: FormGroup;
  billableValue = false;
  date = new Date();
  constructor(
    private modalService: NgbModal,
    private authservice: AuthService,
    private datePipe: DatePipe,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef
  ) {
    this.form = this.fb.group({
      date: ["", Validators.required],
      projectname: ["", Validators.required],
      modulename: ["", Validators.required],
      submodulename: ["", Validators.required],
      hours: ["", Validators.required],
      billable: ["", Validators.required],
      details: ["", Validators.required],
    });
  }

  newdata: any;
  proname: any;
  projectName: any;
  emptyData = false;
  loader: boolean;

  ngOnInit() {
    console.log(this.form.value);

    this.TimesheetTable(this.pageIndex,this.pageSize);

    this.authservice.getProjectName().subscribe((data: any) => {
      console.log(data);
      this.projectName = data;
    });
  }

  addExpense(content: any) {
    this.modalService.open(content, { size: "lg", centered: true });

    //api for get project name
    this.authservice.getProjectName().subscribe((data: any) => {
      this.proname = data;
      console.log(data);
      console.log(this.proname[0].projectId);
    });
  }

  DropdownModule: any;
  ProjectOptionSelected(selectedValue: any) {
    console.log("modulename", this.ngmodulename);
    this.ngmodulename = "";
    console.log("modulename", this.ngmodulename);
    this.authservice.getModuleName(selectedValue).subscribe((data: any) => {
      this.DropdownModule = data;
      console.log(this.DropdownModule);
      console.log(selectedValue);
    });
  }

  DropdownSubModule: any;
  ModuleOptionSelected(selectedValue: any) {
    console.log("submodulename", this.ngsubmodulename);
    this.ngsubmodulename = "";
    console.log("submodulename", this.ngsubmodulename);
    this.authservice.getSubModule(selectedValue).subscribe((data: any) => {
      this.DropdownSubModule = data;
      console.log(this.DropdownSubModule);
    });
  }

  BillablorNot: BillableorNot;
  Billable: any;
  // HoursAndDate(hours: any, date: any) {
  //   this.BillablorNot = {
  //     DateOfTimeSheet: date,
  //     Hours: hours,
  //   };
  //   console.log(hours, date, "hours and date");
  //   this.authservice
  //     .getTimeSheetBillable(this.BillablorNot)
  //     .subscribe((data: any) => {
  //       this.Billable = data.isSuccess;
  //       console.log(this.Billable, "billable value");
  //       if (this.Billable == true) {
  //         this.billableValue = true;
  //         this.ngbillable = "";
  //       } else {
  //         this.billableValue = false;
  //         this.ngbillable = "no";
  //       }
  //     });
  // }

  ngdate = "";
  ngprojectname = "";
  ngsubmodulename = "";
  ngdetails = "";
  nghours = "";
  ngbillable = "";
  ngmodulename = "";
  isBilllable: boolean;
  timesheet: timesheet;
  hidePreviousButton: boolean;
  hideNextButton: boolean;

  addTimeSheet() {
    this.timesheet = {
      DateOfTimeSheet: this.ngdate,
      Details: this.ngdetails,
      Hours: this.nghours,
      IsBillable: this.ngbillable == "yes" ? true : false,
      ProjectId: this.ngprojectname,
      SubmoduleId: this.ngsubmodulename,
      ModuleId: this.ngmodulename,
    };

    console.log(this.timesheet, "timesheet daTA");
    this.authservice.getAddTimesheet(this.timesheet).subscribe((data: any) => {
      console.log(data.message);
      this.cdr.detectChanges();
      this.form.markAsPristine();
      Object.keys(this.form.controls).forEach((key) => {
        this.form.get(key).setValue("");
        this.form.get(key).markAsPristine();
      });

      if (data.message == "Limit 24hours") {
        Swal.fire({
          title: " ",
          text: "The permissible work hour for a business day is 24 ",
          icon: "warning",
          cancelButtonColor: "#f46a6a",
        });
      } else if (data.message == "You cannot enter a date after current date") {
        Swal.fire({
          title: "Enter a valid date",
          text: "Timesheet cannot be added before the actual business day ",
          icon: "warning",
          cancelButtonColor: "#f46a6a",
        });
      } else if (data.message == "You cannot add timesheet on leaveDate") {
        Swal.fire({
          title: "Check the date",
          text: "Timesheet cannot be added for an day on leave ",
          icon: "warning",
          cancelButtonColor: "#f46a6a",
        });
      } else if (data.message == "System Start Date is : 12/1/2022") {
        Swal.fire({
          title: "Check the date",
          text: "You can not add Timesheet for the business days before 1 December 2022",
          icon: "warning",
          cancelButtonColor: "#f46a6a",
        });
      } else if (data.message == "Timesheet Added") {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Timesheet Added",
          showConfirmButton: false,
          timer: 1000,
        });
        this.form.reset();
        this.form.markAsPristine();
        Object.keys(this.form.controls).forEach((key) => {
          // this.form.get(key).setValue("");
          this.form.get(key).markAsPristine();
        });
        this.emptyData = false;
        this.ngOnInit();
      }
    });

    // this.form.markAsPristine();

    console.log(this.form.value);
  }

  value: any;
  viewDetails(data) {
    console.log(data);
    console.log(data.timeSheetId);
    this.authservice
      .getTimeSheetDetails(data.timeSheetId)
      .subscribe((data: any) => {
        this.value = data;
        console.log(this.value);
        console.log(this.value[0].dateOfTimeSheet);
      });
    console.log(data);
  }

  popupViewDetails(content: any) {
    this.modalService.open(content, { size: "md", centered: true });
  }

  ProjectSelect() {}

  filterdata: filterData;
  from = "";
  from2 = "";
  to = "";
  to2 = "";
  projectId = "";
  data3: any;
  filterView = false;
  isStatus = "";
  pageIndex = 1;
  pageSize = 10;

  Filter() {
    this.loader = true;
    this.pageIndex=1;
    this.emptyData = false;
    console.log(this.from);

    this.filterView = true;
    this.filterdata = {
      From: this.from,
      To: this.to,
      ProjectId: this.projectId,
      Is_Status: this.isStatus,
      PageIndex: this.pageIndex,
      PageSize: this.pageSize,
    };
    console.log(this.projectId);
    console.log(this.filterdata, "filterdata");
    this.authservice
      .getEmpTimesheetFilter(this.filterdata)
      .subscribe((data: any) => {
        console.log(data);
        this.data3 = data;
        this.loader = false;
        console.log(this.data3, "filterdata");
        if (data == "") {
          console.log("if condition");
          Swal.fire({
            title: "No data available ",
            text: " ",
            icon: "warning",
            cancelButtonColor: "#f46a6a",
          });
          this.emptyData = true;
          this.hideNextButton = true;
          this.hidePreviousButton = true;
        } else if (data != "") {
          this.emptyData = false;
          this.hideNextButton = false;
          this.hidePreviousButton = false;
        }
        if (this.pageIndex == 1) {
          this.hidePreviousButton = true;
          this.hideNextButton = false;
        } else if (this.pageIndex != 1 && data == "") {
          this.hideNextButton = true;
          this.hidePreviousButton = false;
        } 
        // this.ngOnInit();
      });
  }

  TimesheetTable(pageIndex, pageSize) {
    this.loader = true;
    this.emptyData=false;
    console.log(this.from);

    this.filterView = true;
    this.filterdata = {
      From: this.from,
      To: this.to,
      ProjectId: this.projectId,
      Is_Status: this.isStatus,
      PageSize: pageSize,
      PageIndex: pageIndex,
    };

    console.log(this.projectId);
    console.log(this.filterdata, "filterdata");
    this.authservice
      .getEmpTimesheetFilter(this.filterdata)
      .subscribe((data: any) => {
        console.log(data);
        this.data3 = data;
        this.loader = false;
        console.log(this.data3, "filterdata");
        if (data == "") {
          console.log("if condition");
          this.emptyData = true;
          this.hideNextButton = true;
          this.hidePreviousButton = true;
        } else if (data != "") {
          this.emptyData = false;
          this.hideNextButton = false;
          this.hidePreviousButton = false;
        }
        if (this.pageIndex == 1  && data != "") {
          this.hidePreviousButton = true;
          this.hideNextButton = false;
        } else if (this.pageIndex != 1 && data == "") {
          this.hideNextButton = true;
          this.hidePreviousButton = false;
        } 
        // this.ngOnInit();
      });
  }

  next() {
    this.pageIndex = this.pageIndex + 1;
    this.TimesheetTable(this.pageIndex, this.pageSize);
  }

  previous() {
    this.pageIndex = this.pageIndex - 1;
    this.TimesheetTable(this.pageIndex, this.pageSize);
  }

  ClearFilter() {
    this.to = "";
    this.from = "";
    this.projectId = "";
    this.emptyData = false;
    this.isStatus = "";
    this.Filter();
    // this.page = 1;
    // this.filterView = false;
  }

  close() {
    this.ngOnInit();
    console.log("close fun");
    this.cdr.detectChanges();
    this.form.markAsPristine();
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).setValue("");
      this.form.get(key).markAsPristine();
    });
  }

  // page = 1;
  count = 0;
  tableSize = 5;
  tableSizes = [3, 6, 9, 12];

  onTableDataChange(event) {
    // this.page = event;
    // this.TimesheetTable();
  }

  // onTableSizeChange(event): void {
  //   this.tableSize = event.target.value;
  //   this.page = 1;
  //   this.TimesheetTable();
  // }
}
