import { Component, OnInit } from "@angular/core";
import { AuthService } from "./Common/authentication/auth.service/auth.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  constructor(private auth: AuthService) {}

  ngOnInit() {}
}
