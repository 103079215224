import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ConstantService {
  public readonly LOCAL_STORE_TOKEN = "PUB_KEY";
  public readonly LOCAL_AUTH_TOKEN = "AUTH_KEY";
  public readonly USER_ROLE="USER_ROLE";

  // public readonly api= "https://b.test.tngone.com/tng-opscape-svc/api"
  public readonly api= "https://opscape-api.orderstack.io/api"

  public readonly API_BASE_PATH_AUTH = `${this.api}/auth`;
   
  public readonly API_BASE_PATH_TEST = `${this.api}/Test`;
  public readonly API_BASE_PATH_REIMBURSEMENT = `${this.api}/Reimbursment`;
  public readonly API_BASE_PATH_LEAVE = `${this.api}/Leave`;
  public readonly API_BASE_PATH_TIMESHEET = `${this.api}/Timesheet`;
  public readonly API_BASE_PATH_USER = `${this.api}/User`;
  public readonly API_BASE_PATH_PROJECT = `${this.api}/Project`;

  public readonly POST_GET_QR= `${this.API_BASE_PATH_AUTH}/AuthenticateUser_V1`;

  public readonly API_GET_GET_AUTHTOKEN=`${this.API_BASE_PATH_AUTH}/GetAuthToken`

  public readonly API_GET_GET_TEST=`${this.API_BASE_PATH_TEST}/GetUser`

  public readonly API_POST_GET_TIMESHEET_ENTRY=`${this.API_BASE_PATH_TIMESHEET}/CreateTimeSheet`

  public readonly API_POST_GET_TIMESHEET_DISPLAY=`${this.API_BASE_PATH_TIMESHEET}/GetTimeSheet`

  public readonly API_GET_GET_PROJECT=`${this.API_BASE_PATH_PROJECT}/GetProject`

  public readonly API_GET_GET_ALLPROJECT=`${this.API_BASE_PATH_PROJECT}/GetAllProject`

  public readonly API_GET_GET_PROJECTId=`${this.API_BASE_PATH_TIMESHEET}/GetTimeSheetById`

  public readonly API_GET_GET_LEAVE_DISPLAY=`${this.API_BASE_PATH_LEAVE}/GetLeaveRequest`

  public readonly API_GET_GET_LEAVE_APPLY=`${this.API_BASE_PATH_LEAVE}/CreateLeaveRequest`

  public readonly API_GET_GET_LEAVE_DETAILS_TYPE=`${this.API_BASE_PATH_LEAVE}/GetLeaveDetailsType`

  public readonly API_GET_GET_LEAVE_REQUEST_SORT=`${this.API_BASE_PATH_LEAVE}/GetLeaveRequestSort`

  // rerimbursement  start
  public readonly API_GET_GET_REIMBURSEMENT_TABLE=`${this.API_BASE_PATH_REIMBURSEMENT}/GetReimbursmentDetails`

  public readonly API_GET_GET_REIMBURSEMENT_REQUEST=`${this.API_BASE_PATH_REIMBURSEMENT}/CreateReimbursment`
  
  public readonly API_GET_GET_REIMBURSEMENT_FILTER=`${this.API_BASE_PATH_REIMBURSEMENT}/GetReimbursmentDetailsFilter`

  // rerimbursement ends

  // dashboard start
  public readonly API_GET_GET_REIMBURSEMENT_STATUS=`${this.API_BASE_PATH_REIMBURSEMENT}/GetReimbursmentStatus`
  
  public readonly API_GET_GET_TIMESHEET_STATUS=`${this.API_BASE_PATH_TIMESHEET}/GetTimeSheetStatus`
   // dashboard ends

  // Pro manager timesheeet view
  public readonly API_GET_PRO_TIMESHEET_VIEW=`${this.API_BASE_PATH_TIMESHEET}/TimesheetApprovalView`

  // Pro manager timesheet approvel
  public readonly API_GET_PRO_TIMESHEET_APPROVAL=`${this.API_BASE_PATH_TIMESHEET}/TimesheetApproval`

  // Pro manager timesheet history
  public readonly API_GET_PRO_TIMESHEET_HISTORY=`${this.API_BASE_PATH_TIMESHEET}/TimesheetApprovalHistory`

  // pro manager leave approval 
  public readonly API_GET_PRO_LEAVE_APPROVAL=`${this.API_BASE_PATH_LEAVE}/LeaveRequestApproval`


    // Pro manager declined timesheet only
    public readonly API_GET_PRO_TIMESHEET_DECLINED=`${this.API_BASE_PATH_TIMESHEET}/TimesheetDeclined`

// get project module 
public readonly API_GET_PROJECT_MODULE=`${this.API_BASE_PATH_PROJECT}/GetModule`

// get module name 
public readonly API_GET_PROJECT_SUBMODULE=`${this.API_BASE_PATH_PROJECT}/GetSubModule`

// Pro manager reimbursement request view
 public readonly API_GET_PRO_REIMBURSEMENT_REQUEST_VIEW=`${this.API_BASE_PATH_REIMBURSEMENT}/ReimbursmentApprovalView`
 
 // Pro manager reimbursement history view
 public readonly API_GET_PRO_REIMBURSEMENT_HISTORY_VIEW=`${this.API_BASE_PATH_REIMBURSEMENT}/ReimbursmentHistory`

 // Pro manager Reimbursement approval 
 public readonly API_GET_PRO_REIMBURSEMENT_APPROVAL=`${this.API_BASE_PATH_REIMBURSEMENT}/ReimbursmentApproval`

//  Pro manager Timesheet View by id 
public readonly API_GET_PRO_TIMESHEET_VIEW_BY_ID=`${this.API_BASE_PATH_TIMESHEET}/TimesheetApprovalViewById`

//  Pro maanger Timesheet View Filter 
public readonly API_GET_PRO_TIMESHEET_FILTER=`${this.API_BASE_PATH_TIMESHEET}/TimesheetApprovalViewFilter`

// Employee timeshhet view filter 
public readonly API_GET_EMP_TIMESHEET_FILTER=`${this.API_BASE_PATH_TIMESHEET}/TimesheetViewFilter`

// pro manager timesheeet view filter 
public readonly API_GET_PRO_MANAGER_TIMESHEET_FILTER=`${this.API_BASE_PATH_TIMESHEET}/TimesheetApprovalViewFilter`


// user details 
public readonly API_GET_USER_DETAILS=`${this.API_BASE_PATH_USER}/GetUserDetails`

// project manager  timesheeet show 
public readonly API_GET_PRO_MAN_APPROVED_TIMESHEET=`${this.API_BASE_PATH_USER}/GetUserDetails`

// get all user name and user id 
public readonly API_GET_ALL_USERNAME_AND_USERID=`${this.API_BASE_PATH_USER}/GetUserToProjectManager`


// project manager approved reimbursement show 
public readonly API_GET_PRO_MAN_APPROVED_REIMBURSEMNET=`${this.API_BASE_PATH_USER}/GetUserDetails`

// project manager approved reimbursement show 
public readonly API_GET_PRO_MAN_DECLINED_REIMBURSEMNET=`${this.API_BASE_PATH_USER}/GetUserDetails`

// project manager approved Leave show 
public readonly API_GET_PRO_MAN_APPROVED_LEAVE=`${this.API_BASE_PATH_LEAVE}/LeaveRequestApproved`

// project manager approved declined show 
public readonly API_GET_PRO_MAN_DECLINED_LEAVE=`${this.API_BASE_PATH_LEAVE}/LeaveRequestDeclined`

// project manager leave request view 
public readonly API_GET_PRO_MAN_LEAVE_REQUEST_VIEW=`${this.API_BASE_PATH_LEAVE}/LeaveRequestApprovalView`

// project manager timesheet history filter 
public readonly API_GET_PRO_MAN_TIMESHEET_HISTORY_FILTER=`${this.API_BASE_PATH_TIMESHEET}/TimesheetApprovalHistoryFilter`

// project manager leave history filter 
public readonly API_GET_PRO_MAN_LEAVE_HISTORY_FILTER=`${this.API_BASE_PATH_LEAVE}/LeaveRequestHistoryFilter`

// project manager leave history filter 
public readonly API_GET_PRO_MAN_LEAVE_REQUEST_HISTORY=`${this.API_BASE_PATH_LEAVE}/LeaveRequestHistory`

// project manager Reimbursment History Filter
public readonly API_GET_PRO_MAN_REIMBURSEMENT_HISTORY_FILTER=`${this.API_BASE_PATH_REIMBURSEMENT}/ReimbursmentHistoryFilter`

// project manager Reimbursement request filter 
public readonly API_GET_PRO_MAN_REIMBURSEMENT_REQUEST_FILTER=`${this.API_BASE_PATH_REIMBURSEMENT}/ReimbursmentApprovalViewFilter`

// project manager leave request filter 
public readonly API_GET_PRO_MAN_LEAVE_REQUEST_FILTER=`${this.API_BASE_PATH_LEAVE}/LeaveRequestApprovalViewFilter`

// graph values
public readonly API_GET_GRAPH=`${this.API_BASE_PATH_TIMESHEET}/ShowGraph`

//hours and date for validate billable filed in timesheet
public readonly API_GET_HOURS_AND_DATE_VALIDATE_BILLABLE=`${this.API_BASE_PATH_TIMESHEET}/TimeSheetBillable`

//multiple approve and reject
public readonly API_GET_MULTIPLE_APPROVE_OR_REJECT=`${this.API_BASE_PATH_TIMESHEET}/SelectTimeSheetApproval`

//leave cancel feature
public readonly API_GET_LEAVE_CANCEL_FEATURE=`${this.API_BASE_PATH_LEAVE}/LeaveCancel`

//checking leave for nagative (lop)
public readonly API_GET_CHECK_LEAVE_BEFORE_LOP=`${this.API_BASE_PATH_LEAVE}/LeaveRequestApprovalCheck`

//checking leave for negative (lop)
public readonly API_GET_CHECK_LEAVE_LOP_WHILE_REQUESTING=`${this.API_BASE_PATH_LEAVE}/CreateLeaveRequestCheck`

//check lop before proceed leave-for employee
public readonly API_GET_CHECK_LOP_FOR_EMPLOYEE=`${this.API_BASE_PATH_LEAVE}/CreateLeaveRequestCheckLOP`

//after cheeck lop proveed to apply leave/wfh
public readonly API_GET_AFTER_CHECK_LOP_PROCEED_TO_APPLY_LEAVE_OR_WFH=`${this.API_BASE_PATH_LEAVE}/CreateLeaveRequestLOP`


 constructor() {}
}
