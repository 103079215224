import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
constructor(private msalService:MsalService,private router:Router){}

  logout() {
    this.msalService.logout();
    this.router.navigate(['/ ']);
  }
}
