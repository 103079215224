import { Component, OnInit } from "@angular/core";
import { an } from "@fullcalendar/core/internal-common";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
// import { Project } from './project.model';
// import { projectData } from './projectdata';

@Component({
  selector: "app-pro-manager-projects",
  templateUrl: "./pro-manager-projects.component.html",
  styleUrls: ["./pro-manager-projects.component.scss"],
})
export class ProManagerProjectsComponent implements OnInit {
  constructor(private modalService: NgbModal) {}
  // bread crumb items
  breadCrumbItems: Array<{}>;
  id: any;
  image: any;
  text: any;
  //  subtext:any
  users: any;
  status: any;
  date: any;
  comment: any;
  breadCrumbItems2: Array<{}>;

  ngOnInit() {
    this.breadCrumbItems = [
      { label: "Projects" },
      { label: "Projects List", active: true },
    ];

    breadCrumbItems2: Array<{}>;
  }
  projectData: any = [
    {
      id: 1,
      image: "assets/images/companies/122116-USMCA-Logo-small-633x154.webp",
      text: "USMCA",
      subtext: "Motorcycle coaching",
      users: [
        "assets/images/users/avatar-2.jpg",
        "assets/images/users/avatar-1.jpg",
      ],
      status: "Completed",
      date: "15 Oct, 19",
      comment: 214,
    },
    {
      id: 2,
      image: "assets/images/companies/MicrosoftTeams-image (9).png",
      text: "Green Print",
      subtext: "To achieve it would be necessary",
      users: ["assets/images/users/avatar-3.jpg"],
      status: "Pending",
      date: "22 Oct, 19",
      comment: 183,
    },
  ];

  projectArchive: any = [
    {
      id: 1,
      image: "assets/images/companies/122116-USMCA-Logo-small-633x154.webp",
      text: "USMCA",
      subtext: "Motorcycle coaching",
      users: [
        "assets/images/users/avatar-2.jpg",
        "assets/images/users/avatar-1.jpg",
      ],
      status: "Completed",
      date: "15 Oct, 19",
      comment: 214,
      unarchive:"yes"
    },
    {
      id: 2,
      image: "assets/images/companies/MicrosoftTeams-image (9).png",
      text: "Green Print",
      subtext: "Environmental, social, and governance ",
      users: ["assets/images/users/avatar-3.jpg"],
      status: "Pending",
      date: "22 Oct, 19",
      comment: 183,
      unarchive:"no"
    },
    {
      id: 3,
      image: "assets/images/companies/parkme.png",
      text: "Park Me",
      subtext: "Know the spot to park your vechicle ",
      users: ["assets/images/users/avatar-3.jpg"],
      status: "Pending",
      date: "22 Oct, 19",
      comment: 183,
      unarchive:"no"
    },
    {
      id: 4,
      image: "assets/images/companies/swiggy.png",
      text: "Restaurant",
      subtext: "Order your delicious meal ",
      users: ["assets/images/users/avatar-3.jpg"],
      status: "Pending",
      date: "22 Oct, 19",
      comment: 183,
      unarchive:"no"
    },
  ];
  addProject(content: any) {
    this.modalService.open(content, { size: "lg", centered: true });
  }

  archiveProject(contentArchive:any){
    this.modalService.open(contentArchive,{size:'lg',centered:true });
  }
}
