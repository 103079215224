<!-- <app-topbar _ngcontent-vpc-c135="" _nghost-vpc-c134=""></app-topbar> -->
<div class="container"></div>
<header id="page-topbar">
  <div class="navbar-header">
    <div class="d-flex">
      <div class="navbar-brand-box">
        <a class="logo logo-dark"
          ><span class="logo-sm"
            ><img src="assets/images/logo.svg" alt="" height="22" /></span
          ><span class="logo-lg"
            ><img
              src="../../../assets/images/org-logos_orderstack.png"
              alt=""
              height="27" /></span
        ></a>
      </div>
      <button
        (click)="sideNavToggle()"
        type="button"
        id="vertical-menu-btn"
        class="btn btn-sm px-3 font-size-16 header-item"
      >
        <i class="fa fa-fw fa-bars"></i>
      </button>

      <div
        ngbdropdown=""
        class="dropdown-mega d-none d-lg-block ms-2 dropdown"
      ></div>
    </div>

    <div class="d-flex">
      <div class="d-inline-block dropdown">
        <button
          type="button"
          ngbdropdowntoggle=""
          id="page-header-user-dropdown"
          class="dropdown-toggle btn header-item"
          aria-expanded="false"
        >
          <img
            _ngcontent-vpc-c134=""
            src="../../../assets/images/opscape_avatar.png"
            alt="Header Avatar"
            class="rounded-circle header-profile-user"
          /><span _ngcontent-vpc-c134="" class="d-none d-xl-inline-block ms-1"
            >Arjun</span
          >
        </button>
      </div>
      <div _ngcontent-vpc-c134="" class="dropdown d-inline-block">
        <!-- <button
          (click)="logout()"
          _ngcontent-vpc-c134=""
          type="button"
          class="btn header-item noti-icon right-bar-toggle"
        >
          logout
          <i _ngcontent-vpc-c134="" class="bx bx-cog bx-spin"></i>
        </button> -->
      </div>
    </div>
  </div>
</header>

<!-- sidebar start -->
<div class="main">
  <div class="vertical-menu">
    <div data-simplebar="init" class="h-100">
      <div class="simplebar-wrapper" style="margin: 0px">
        <div class="simplebar-height-auto-observer-wrapper">
          <div class="simplebar-height-auto-observer"></div>
        </div>
        <div class="simplebar-mask">
          <div class="simplebar-offset" style="right: 0px; bottom: 0px">
            <div
              class="simplebar-content-wrapper"
              style="height: 100%; overflow: hidden scroll"
            >
              <div class="simplebar-content" style="padding: 0px">
                <div>
                  <div id="sidebar-menu">
                    <ul class="metismenu list-unstyled">
                      <li class="menu-title h2">Menu</li>
                      <li>
                        <a class="is-parent has-arrow"
                          ><i class="bx bx-home-circle"></i
                          ><span> Dashboards</span></a
                        >
                      </li>

                      <br /><br />
                      <li class="menu-title">Employee</li>

                      <br />
                      <li>
                        <a [routerLink]="['/home/employee-attendence']"
                          ><i _ngcontent-lnk-c133="" class="bx bx-calendar"></i
                          ><span _ngcontent-lnk-c133=""> Attendence</span></a
                        >
                      </li>
                      <br />

                      <li>
                        <a class="side-nav-link-ref" href="/chat"
                          ><i class="bx bx-chat"></i
                          ><span> Reimburshment</span></a
                        >
                      </li>
                      <br />

                      <li>
                        <a class="side-nav-link-ref" href="/filemanager"
                          ><i class="bx bx-file"></i><span> Time Sheet</span></a
                        >
                      </li>
                      <br />

                      <li (click)="logout()" >
                        <a class="side-nav-link-ref" href="/"
                          ><i class="bx bx-file"></i><span> Logout</span></a
                        >
                      </li>
                      <br />
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="display-area">
    <router-outlet></router-outlet>
  </div>
</div>
