<div *ngIf="processing" class="loader">
  <div class="spinners"></div>
  <div class="text">Processing...</div>
</div>

<div class="container-fluid">
  <h3 class="text-center mb-3 mt-1">TimeSheet Requests</h3>
  <div class="row g-3">
    <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3">
      <select
        class="form-control select2"
        [(ngModel)]="userId"
        placeholder="aaa"
      >
        <option value="" selected>Employee Name</option>
        <option *ngFor="let d of allUser" value="{{ d.userID }}">
          {{ d.userName }}
        </option>
      </select>
    </div>
    <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3">
      <select
        (change)="ProjectOptionSelected($event.target.value)"
        class="form-control select2"
        [(ngModel)]="ngprojectname"
      >
        <option value="" selected>Project Name</option>
        <option *ngFor="let d of data2" value="{{ d.projectId }}">
          {{ d.projectName }}
        </option>
      </select>
      <span class="validation" *ngIf="validationModule"
        >Please select a project.</span
      >
    </div>
    <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3">
      <select
        (change)="ModuleOptionSelected($event.target.value)"
        (click)="validationForModule()"
        class="form-control select2"
        [(ngModel)]="ngmodulename"
        placeholder="aaa"
      >
        <option value="" selected>Module Name</option>
        <option *ngFor="let d of DropdownModule" [value]="d.moduleId">
          {{ d.module }}
        </option>
      </select>
      <span class="validation" *ngIf="validationSubmodule"
        >Please select a module.</span
      >
    </div>
    <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3">
      <select
        class="form-control select2"
        (click)="validationForSubmodule()"
        [(ngModel)]="ngsubmodulename"
        placeholder="aaa"
      >
        <option value="" selected>Sub-module Name</option>
        <option *ngFor="let d of DropdownSubModule" [value]="d.subModuleId">
          {{ d.subModule }}
        </option>
      </select>
    </div>

    <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3">
      <div id="datepicker1">
        <input
          type="text"
          class="form-control"
          placeholder="From"
          onfocus="(this.type='date')"
          [(ngModel)]="from"
        />
      </div>
      <!-- input-group -->
    </div>
    <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3">
      <div id="datepicker1">
        <input
          type="text"
          class="form-control"
          placeholder="To"
          onfocus="(this.type='date')"
          [(ngModel)]="to"
        />
      </div>
      <!-- input-group -->
    </div>

    <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3">
      <button (click)="Filter()" type="button" class="btn btn-secondary w-100">
        <i class="mdi mdi-filter-outline align-middle"></i> Filter
      </button>
    </div>
    <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3">
      <button
        (click)="ClearFilter()"
        type="button"
        class="btn btn-secondary w-100"
      >
        <i class=""></i> Clear Filter
      </button>
    </div>

    <div *ngIf="approveRejectShowButton" class="d-flex justify-content-between">
      <div class="col-xxl-1 col-xl-1 col-lg-1 col-md-1 col-sm-1">
        <button
          (click)="multipleApprove()"
          type="button"
          class="btn btn-success"
        >
          Approve
        </button>
      </div>
      <div class="col-xxl-1 col-xl-1 col-lg-1 col-md-1 col-sm-1">
        <button (click)="multipleReject()" type="button" class="btn btn-danger">
          Reject
        </button>
      </div>
      <div class="col-xxl-10 col-xl-8 col-lg-8 col-md-8 col-sm-8"></div>
    </div>
  </div>

  <div *ngIf="!emptyData" class="table-responsive">
    <br />
    <table *ngIf="!loader" class="table table-bordered align-middle nowrap">
      <thead>
        <tr class="text-center">
          <th style="width: 2%" scope="col">
            All
            <input
              class="form-check-input"
              [(ngModel)]="selectAllngModel"
              (change)="allSelectCheckbox($event.target.checked)"
              type="checkbox"
            />
          </th>
          <th style="width: 3%" scope="col">No.</th>
          <th style="width: 10%" scope="col">Employee Name</th>
          <th style="width: 15%" scope="col">Date</th>
          <th style="width: 10%" scope="col">Project</th>
          <th style="width: 10%" scope="col">Module</th>
          <th style="width: 10%" scope="col">Submodule</th>
          <th style="width: 10%" scope="col">Description</th>
          <th style="width: 5%" scope="col">Hours</th>
          <th style="width: 10%" scope="col">Billable</th>
          <th style="width: 10%" scope="col">Action</th>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let data of filterData; index as i" class="text-center trow" [ngClass]="{'alternativeColor': i % 2 === 1,'timeOff':data.onLeave===true}">
          <td>
            <input
              class="form-check-input"
              type="checkbox"
              [(ngModel)]="multipleValueCheckbox[i]"
              (change)="checkBox($event.target.checked, data.timeSheetId)"
            />{{ isChecked }}
          </td>
          <td>{{ ((this.pageSize*this.pageIndex)-this.pageSize)+i+1 }}</td>
          <!-- <td *ngIf="filterData == null">{{ i + 1 }}</td> -->
          <td >{{ data.employeeName }}</td>
          <td>{{ data.dateOfTimeSheet | date : "dd-MMM-yyyy" }}</td>
          <td>
            <h5>
              <span class="badge badge-soft-info">{{ data.project }}</span>
            </h5>
          </td>
          <td>{{ data.module }}</td>
          <td>{{ data.submodule }}</td>
          <td>{{ data.details }}</td>
          <td>{{ data.hours }}</td>
          <td>
            <h5>
              <span
                class="badge"
                [ngClass]="{
                  'bg-success': data.isBillable === true,
                  'bg-info': data.isBillable === false
                }"
              >
                <span *ngIf="data.isBillable == true">Yes</span>
                <span *ngIf="data.isBillable == false">No</span>
              </span>
            </h5>
          </td>
          <td class="d-flex justify-content-around">
            <div>
              <a
                (click)="approved(data.timeSheetId)"
                class="btn btn-sm btn-soft-info"
                ><i class="bx bx-check bx-xs"></i
              ></a>
            </div>
            <div>
              <a
                (click)="decline(data.timeSheetId)"
                data-bs-toggle="modal"
                class="btn btn-sm btn-soft-danger"
                ><i class="bx bx-x bx-xs"></i>
              </a>
            </div>
            <div>
              <a
                (click)="
                  popupViewDetails(viewDetailsContent);
                  viewDetails(data.timeSheetId)
                "
                data-bs-toggle="modal"
                class="btn btn-sm btn-soft-info"
                ><i class="bx bx-show bx-xs"></i>
              </a>
            </div>
          </td>
          <!-- <td>
              <button
                type="button"
                class="btn btn-primary btn-sm btn-rounded"
                (click)="popupViewDetails(viewDetailsContent)"
              >
                View Details
              </button>
            </td> -->
        </tr>
      </tbody>
    </table>

  </div>
  <div *ngIf="emptyData" class="no-data-cell">No Request to Show</div>
  <div id="" *ngIf="loader">
    <div>
      <div class="" id="status">
        <div class="spinner-chase">
          <div class="chase-dot"></div>
          <div class="chase-dot"></div>
          <div class="chase-dot"></div>
          <div class="chase-dot"></div>
          <div class="chase-dot"></div>
          <div class="chase-dot"></div>
        </div>
      </div>
    </div>
  </div>
  
  <div *ngIf="!loader" class="d-flex justify-content-center mb-3">
    <div class="mr-4">
      <button
        *ngIf="!hidePreviousButton"
        (click)="previous()"
        class="btn btn-light btn-sm"
      >
        << Prev
      </button>
    </div>
    <div></div>
    <div>
      <button
        *ngIf="!hideNextButton"
        (click)="next()"
        class="btn btn-light btn-sm"
      >
        Next >>
      </button>
    </div>
  </div>

  <ng-template #viewDetailsContent role="document" let-modal>
    <div class="modal-header border-bottom-0">
      <button
        type="button"
        class="btn-close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      ></button>
    </div>
    <div class="card">
      <div class="card-body">
        <h4 class="card-title text-center mb-3">Time Sheet Details</h4>
        <div class="table-responsive">
          <table class="table table-centered table-nowrap">
            <thead class="viewDetails">
              <tr class="text-center">
                <td>TimeSheet Id</td>
                <td>{{ value[0].timeSheetId }}</td>
              </tr>
              <tr>
                <td>Employee Name</td>
                <td>{{ value[0].employeeName }}</td>
              </tr>
              <tr>
                <td>Date</td>
                <td>{{ value[0].dateOfTimeSheet | date : "dd-MMM-yyyy" }}</td>
              </tr>
              <tr>
                <td>Day</td>
                <td>{{ days[value[0].day] }}</td>
              </tr>
              <tr>
                <td>Project</td>
                <td>{{ value[0].project }}</td>
              </tr>
              <tr>
                <td>Module</td>
                <td>{{ value[0].module }}</td>
              </tr>
              <tr>
                <td>SubModule</td>
                <td>{{ value[0].submodule }}</td>
              </tr>
              <tr>
                <td>Details</td>
                <td>{{ value[0].details }}</td>
              </tr>
              <tr>
                <td>Hours</td>
                <td>{{ value[0].hours }}</td>
              </tr>
              <tr>
                <td>Billable</td>
                <td *ngIf="value[0].isBillable == true">Yes</td>
                <td *ngIf="value[0].isBillable == false">No</td>
              </tr>
              <!-- <tr>
            <td>Status</td>
            <td *ngIf="value[0].isStatus==true">Yes</td>
            <td *ngIf="value[0].isStatus==false">No</td>
          </tr> -->
            </thead>
          </table>
        </div>

        <!-- first -->
      </div>
    </div>
  </ng-template>
</div>
