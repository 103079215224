<div class="container">
  <div class="row">
    <div class="col">
      <button
        type="button"
        class="btn btn-warning btn-rounded"
        (click)="archiveProject(contentArchive)"
      >
        <i class="bx bx-archive-in "></i>  Archived Project
      </button>
    </div>
    <div class="col text-end mr-4">
      <button
        type="button"
        class="btn btn-success btn-rounded"
        [routerLink]="['/home/promanprojectadd']"
      >
        <i class="mdi mdi-plus mr-1"></i> Add Project
      </button>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-12">
      <div class>
        <div class="table-responsive">
          <table
            class="table project-list-table table-nowrap align-middle table-borderless"
          >
            <thead>
              <tr class="text-center">
                <th scope="col" style="width: 100px">#</th>
                <th scope="col">Projects</th>
                <th scope="col">Due Date</th>
                <th scope="col">Status</th>
                <th scope="col">Team</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let project of projectArchive" class="text-center">
                <td>
                  <img src="{{ project.image }}" alt class="avatar-sm" />
                </td>
                <td>
                  <h5 class="text-truncate font-size-14">
                    <a href="javascript: void(0);" class="text-dark">{{
                      project.text
                    }}</a>
                  </h5>
                  <p class="text-muted mb-0">{{ project.subtext }}</p>
                </td>
                <td>{{ project.date }}</td>
                <td>
                  <span
                    class="badge"
                    [ngClass]="{
                      'bg-success': project.status === 'Completed',
                      'bg-warning': project.status === 'Pending',
                      'bg-danger': project.status === 'Delay'
                    }"
                    >{{ project.status }}</span
                  >
                </td>
                <td>
                  <div class="avatar-group">
                    <div class="avatar-group-item">
                      <a href="javascript: void(0);" class="d-inline-block">
                        <img
                          src="{{ project.users[0] }}"
                          class="rounded-circle avatar-xs"
                          alt
                        />
                      </a>
                    </div>
                    <div class="avatar-group-item" *ngIf="project.users[1]">
                      <a href="javascript: void(0);" class="d-inline-block">
                        <img
                          src="{{ project.users[1] }}"
                          class="rounded-circle avatar-xs"
                          alt
                        />
                      </a>
                    </div>
                  </div>
                </td>
                <td>
                  <a
                    routerLink="/jobs/details"
                    class="btn btn-md btn-soft-warning"
                    ><i class="bx bx-archive-in bx-sm"></i
                  ></a>
                  <a
                    routerLink="/jobs/details"
                    class="btn btn-md btn-soft-primary"
                    ><i class="bx bx-edit bx-sm"></i
                  ></a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <!-- end row -->

  <div class="row">
    <div class="col-12">
      <div class="text-center my-3">
        <a href="javascript:void(0);" class="text-success">
          <i class="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>
          Load more
        </a>
      </div>
    </div>
    <!-- end col-->
  </div>
  <!-- end row -->
</div>

<ng-template #contentArchive role="document" let-modal>
  <div class="table-responsive">
    <table
      class="table project-list-table table-nowrap align-middle table-borderless"
    >
      <thead>
        <tr class="text-center">
          <th scope="col" style="width: 100px">#</th>
          <th scope="col">Projects</th>
          <th scope="col">Due Date</th>
          <th scope="col">Status</th>
          <th scope="col">Team</th>
          <th scope="col">UnArchive</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let project of projectData" class="text-center">
          <td>
            <img src="{{ project.image }}" alt class="avatar-sm" />
          </td>
          <td>
            <h5 class="text-truncate font-size-14">
              <a href="javascript: void(0);" class="text-dark">{{
                project.text
              }}</a>
            </h5>
            <p class="text-muted mb-0">{{ project.subtext }}</p>
          </td>
          <td>{{ project.date }}</td>
          <td>
            <span
              class="badge"
              [ngClass]="{
                'bg-success': project.status === 'Completed',
                'bg-warning': project.status === 'Pending',
                'bg-danger': project.status === 'Delay'
              }"
              >{{ project.status }}</span
            >
          </td>
          <td>
            <div class="avatar-group">
              <div class="avatar-group-item">
                <a href="javascript: void(0);" class="d-inline-block">
                  <img
                    src="{{ project.users[0] }}"
                    class="rounded-circle avatar-xs"
                    alt
                  />
                </a>
              </div>
              <div class="avatar-group-item" *ngIf="project.users[1]">
                <a href="javascript: void(0);" class="d-inline-block">
                  <img
                    src="{{ project.users[1] }}"
                    class="rounded-circle avatar-xs"
                    alt
                  />
                </a>
              </div>
            </div>
          </td>
          <td>
            <a routerLink="/jobs/details" class="btn btn-md btn-soft-warning"
              ><i class="bx bx-archive-out bx-sm"></i
            ></a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</ng-template>

<ng-template #contentProject role="document" let-modal>
    <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4"> Title Wizard</h4>
            <aw-wizard [navBarLayout]="'large-empty-symbols'">
              <aw-wizard-step stepTitle="Seller Details">
                <ng-template awWizardStepSymbol>1</ng-template>
                <div class="row mt-3">
                  <div class="col-12">
                    <div class="row mb-3">
                      <label class="col-md-3 col-form-label">Contact Person</label>
                      <div class="col-md-9">
                        <input class="form-control" name="txtFirstNameBilling" type="text" id="txtFirstNameBilling">
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label class="col-md-3 col-form-label">Mobile No.</label>
                      <div class="col-md-9">
                        <input class="form-control" name="txtLastNameBilling" type="text" id="txtLastNameBilling">
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label class="col-md-3 col-form-label">Landline No.</label>
                      <div class="col-md-9">
                        <input class="form-control" name="txtCompanyBilling" type="text" id="txtCompanyBilling">
                      </div>
                    </div>
                  </div>
                </div>
                <ul class="list-inline wizard mb-0">
                  <li class="next list-inline-item float-end"><button class="btn btn-primary" awNextStep>Next</button>
                  </li>
                </ul>
              </aw-wizard-step>
  
              <aw-wizard-step stepTitle="Bank Details">
                <ng-template awWizardStepSymbol>2</ng-template>
  
                <div class="row mt-3">
                  <div class="col-12">
                    <div class="row mb-3">
                      <label class="col-md-3 col-form-label">Name on Card</label>
                      <div class="col-md-9">
                        <input class="form-control" name="txtNameCard" type="text">
                      </div>
                    </div>
                    <div class="row">
                      <label class="col-md-3 col-form-label" for="ddlCreditCardType">Credit Card Type</label>
                      <div class="col-md-9">
                        <select id="ddlCreditCardType" name="ddlCreditCardType" class="form-control">
                          <option value="">--Please Select--</option>
                          <option value="AE">American Express</option>
                          <option value="VI">Visa</option>
                          <option value="MC">MasterCard</option>
                          <option value="DI">Discover</option>
                        </select>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label class="col-md-3 col-form-label">Credit Card Number</label>
                      <div class="col-md-9">
                        <input id="txtCreditCardNumber" class="form-control" name="txtCreditCardNumber" type="text">
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label class="col-md-3 col-form-label">Expiration Date</label>
                      <div class="col-md-9">
                        <input id="txtExpirationDate" class="form-control" name="txtExpirationDate" type="text">
                      </div>
                    </div>
                  </div>
                </div>
  
                <ul class="list-inline wizard mb-0">
                  <li class="previous list-inline-item"><button class="btn btn-primary" awPreviousStep>Previous</button>
                  </li>
                  <li class="next list-inline-item float-end"><button class="btn btn-primary" awNextStep>Next</button>
                  </li>
                </ul>
              </aw-wizard-step>
  
              <aw-wizard-step stepTitle="Confirm Detail">
                <ng-template awWizardStepSymbol>3</ng-template>
                <div class="row justify-content-center">
                  <div class="col-lg-6">
                    <div class="text-center">
                      <div class="mb-4">
                        <i class="mdi mdi-check-circle-outline text-success display-4"></i>
                      </div>
                      <div>
                        <h5>Confirm Detail</h5>
                        <p class="text-muted">If several languages coalesce, the grammar of the resulting</p>
                      </div>
                    </div>
                  </div>
                </div>
                <ul class="list-inline wizard mb-0">
                  <li class="previous list-inline-item"><button class="btn btn-primary" awPreviousStep>Previous</button>
                  </li>
                </ul>
              </aw-wizard-step>
            </aw-wizard>
          </div>
        </div>
      </div>
</ng-template>
