import { Component } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AuthService } from "src/app/Common/authentication/auth.service/auth.service";
import Swal from "sweetalert2";
import { DatePipe } from "@angular/common";
import { filterData, statusModels } from "./model";
@Component({
  selector: "app-pro-man-leave-approved",
  templateUrl: "./pro-man-leave-approved.component.html",
  styleUrls: ["./pro-man-leave-approved.component.scss"],
})
export class ProManLeaveApprovedComponent {
  tabledata: any;
  data2: any;
  allUser: any;
  emptyData=false;
  loader:boolean;
  hidePreviousButton: boolean;
  hideNextButton: boolean;
  pageIndex = 1;
  pageSize = 10;

  ngOnInit(): void {
    // this.authService.getLeaveRequestHistory().subscribe((data: any) => {
    //   this.tabledata = data;
    //   console.log(this.tabledata);
    //   if (data.length == 0) {
    //     // Swal.fire("No More History", "", "warning");
    //   this.emptyData=true;
    // }
    // });
    this.TableShow(this.pageIndex,this.pageSize);

    this.authService.getProjectName().subscribe((data: any) => {
      console.log(data);
      this.data2 = data;
    });

    this.authService.getAllUser().subscribe((data: any) => {
      this.allUser = data;
      console.log(this.allUser, "all users");
    });
  }

  constructor(
    private authService: AuthService,
    private modalService: NgbModal,
    private datePipe: DatePipe
  ) {}
  
  statusmodel: statusModels;
  leaveRequestId: string;
 
  typeOnFilter = "";
  dateFrom = "";
  dateTo = "";
  userId = "";
  filterView = false;
  filterdata: filterData;
  filterValue: any;
  Filter() {
    this.loader=true;
    this.pageIndex=1;
    this.filterView = true;

    this.filterdata = {
      UserId: this.userId,
      From: this.dateFrom,
      To: this.dateTo,
      Type: this.typeOnFilter,
      PageIndex:this.pageIndex,
      PageSize:this.pageSize
    };
    this.authService
      .getLeaveRequestHistoryFilter(this.filterdata)
      .subscribe((data: any) => {
        this.loader=false;
        this.filterValue = data;
        if (data == "") {
          console.log("if condition");
          Swal.fire({
            title: "No data available ",
            text: "",
            icon: "warning",
            cancelButtonColor: "#f46a6a",
          });
          this.emptyData=true;
          this.hideNextButton = true;
          this.hidePreviousButton = true;
        } else if (data != "") {
          this.emptyData = false;
          this.hideNextButton = false;
          this.hidePreviousButton = false;
        }
        if (this.pageIndex == 1  && data != "") {
          this.hidePreviousButton = true;
          this.hideNextButton = false;
        } else if (this.pageIndex != 1 && data == "") {
          this.hideNextButton = true;
          this.hidePreviousButton = false;
        }
      });
  }

  TableShow(pageIndex, pageSize) {
    this.loader=true;
    this.filterView = true;
    this.emptyData=false;

    this.filterdata = {
      UserId: this.userId,
      From: this.dateFrom,
      To: this.dateTo,
      Type: this.typeOnFilter,
      PageIndex:pageIndex,
      PageSize:pageSize
    };
    this.authService
      .getLeaveRequestHistoryFilter(this.filterdata)
      .subscribe((data: any) => {
        this.filterValue = data;
        this.loader=false;
        if (data == "") {
          this.emptyData=true;
          this.hideNextButton = true;
          this.hidePreviousButton = true;
        } else if (data != "") {
          this.emptyData = false;
          this.hideNextButton = false;
          this.hidePreviousButton = false;
        }
        if (this.pageIndex == 1  && data != "") {
          this.hidePreviousButton = true;
          this.hideNextButton = false;
        } else if (this.pageIndex != 1 && data == "") {
          this.hideNextButton = true;
          this.hidePreviousButton = false;
        }
      });
  }
  next() {
    this.pageIndex = this.pageIndex + 1;
    this.TableShow(this.pageIndex, this.pageSize);
  }

  previous() {
    this.pageIndex = this.pageIndex - 1;
    this.TableShow(this.pageIndex, this.pageSize);
  }

  ClearFilter() {
    
    this.dateFrom = "";
    this.dateTo = "";
    this.userId = "";
    this.typeOnFilter="";
    this.emptyData=false
    this.Filter();
  }

  page = 1;
  count = 0;
  tableSize = 10;
  tableSizes = [3, 6, 9, 12];

  onTableDataChange(event) {
    this.page = event;
    // this.TimesheetTable();
  }
}
