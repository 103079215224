import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ConstantService } from "../../service/constant.service";
import { AuthInput } from "../models/AuthInput";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor(private constants: ConstantService, private http: HttpClient) {}

  // Get redemption list

  public getTokenV1(token: any) {
    const params = new HttpParams().set("pubKey", token);
    console.log(token);
    console.log(params);

    return this.http.get(this.constants.POST_GET_QR, { params: params });
    // return this.http.get(this.constants.POST_GET_QR+ '?pubKey=' +token);
  }

  public getAuthToken(authInput: AuthInput) {
    return this.http.post<any>(this.constants.API_GET_GET_AUTHTOKEN, authInput);
  }
  // public getSixdigit()

  public getUser() {
    return this.http.get(this.constants.API_GET_GET_TEST);
  }

  // Time sheet start

  servicetimesheet: any;

  public getAddTimesheet(timesheet: any) {
    this.servicetimesheet = timesheet;
    console.log(this.servicetimesheet);
    console.log(this.servicetimesheet.ModuleId);
    // let queryParams = new HttpParams();
    // queryParams = queryParams.append("date", "");
    // queryParams = queryParams.append("per_page", 1);
    return this.http.post(
      this.constants.API_POST_GET_TIMESHEET_ENTRY,
      this.servicetimesheet
    );
  }

  public getDisplayTimeSheet() {
    return this.http.get(this.constants.API_POST_GET_TIMESHEET_DISPLAY);
  }

  public getProjectName() {
    return this.http.get(this.constants.API_GET_GET_PROJECT);
  }

  public getAllProjectName() {
    return this.http.get(this.constants.API_GET_GET_ALLPROJECT);
  }

  public getTimeSheetDetails(id) {
    return this.http.get(this.constants.API_GET_GET_PROJECTId + "?Id=" + id);
  }
  // Time sheet ends

  // leave start

  public getDisplayLeaveTable() {
    return this.http.post(this.constants.API_GET_GET_LEAVE_DISPLAY, {});
  }

  serviceLeave: any;
  public getLeaveRequest(serviceleave: any) {
    this.serviceLeave = serviceleave;
    console.log(this.serviceLeave);
    return this.http.post(
      this.constants.API_GET_GET_LEAVE_APPLY,
      this.serviceLeave
    );
  }

  public getDisplayLeaveDetails() {
    return this.http.get(this.constants.API_GET_GET_LEAVE_DETAILS_TYPE);
  }

  filterDate: any;
  public getLeaveRequestSort(filterdate: any) {
    this.filterDate = filterdate;
    console.log(this.filterDate);

    return this.http.post(
      this.constants.API_GET_GET_LEAVE_REQUEST_SORT,
      this.filterDate
    );
  }
  // leave ends

  // reimbursement start
  public getReimbursementTable() {
    return this.http.get(this.constants.API_GET_GET_REIMBURSEMENT_TABLE);
  }

  serviceReimbursement: any;
  public getReimbursementRequest(reimbursement) {
    this.serviceReimbursement = reimbursement;
    console.log(this.serviceReimbursement);
    const response = this.http.post<any>(
      this.constants.API_GET_GET_REIMBURSEMENT_REQUEST,
      this.serviceReimbursement
    );
    return response;
  }

  serviceFilterData: any;
  public getReimbursementFilter(filterdata) {
    this.serviceFilterData = filterdata;
    console.log(this.serviceFilterData, "filter data");
    return this.http.post(
      this.constants.API_GET_GET_REIMBURSEMENT_FILTER,
      this.serviceFilterData
    );
  }
  // reimbursement ends

  // dashboard start
  public getReimbursementStatus() {
    return this.http.get(this.constants.API_GET_GET_REIMBURSEMENT_STATUS);
  }

  public getTimesheetStatus() {
    return this.http.get(this.constants.API_GET_GET_TIMESHEET_STATUS);
  }
  // dashboard ends

  // Pro manager timesheeet view
  public getTimesheetApprovalView() {
    return this.http.get(this.constants.API_GET_PRO_TIMESHEET_VIEW);
  }

  // Pro manager timesheeet approval
  serviceStatusModels: any;
  public getTimeSheetApproval(statusmodels) {
    this.serviceStatusModels = statusmodels;
    console.log(this.serviceStatusModels);
    return this.http.post(
      this.constants.API_GET_PRO_TIMESHEET_APPROVAL,
      this.serviceStatusModels
    );
  }

  // pro manager leave approval
  public getLeaveApproval(statusmodels) {
    this.serviceStatusModels = statusmodels;
    console.log(this.serviceStatusModels);
    return this.http.post(
      this.constants.API_GET_PRO_LEAVE_APPROVAL,
      this.serviceStatusModels
    );
  }

  // pro manager leave declined
  public getLeaveDeclined(statusmodels) {
    this.serviceStatusModels = statusmodels;
    console.log(this.serviceStatusModels);
    return this.http.post(
      this.constants.API_GET_PRO_LEAVE_APPROVAL,
      this.serviceStatusModels
    );
  }

  // get project module
  serviceProjectId: string;
  public getModuleName(projectId) {
    console.log(projectId);
    return this.http.get(
      this.constants.API_GET_PROJECT_MODULE + "?ProjectId=" + projectId
    );
  }
  // get project submodule
  serviceModuleId: string;
  public getSubModule(moduleId) {
    console.log(moduleId);
    return this.http.get(
      this.constants.API_GET_PROJECT_SUBMODULE + "?ModuleId=" + moduleId
    );
  }

  // Pro manager reimbursement request view
  public getReimbursementRequestView() {
    return this.http.get(this.constants.API_GET_PRO_REIMBURSEMENT_REQUEST_VIEW);
  }

  // Pro manager Reimbursement approval
  serviceReimbursementAppravalData: any;
  public getReimbursementApproval(ReimbursementId) {
    this.serviceReimbursementAppravalData = ReimbursementId;
    console.log(this.serviceReimbursementAppravalData);
    return this.http.post(
      this.constants.API_GET_PRO_REIMBURSEMENT_APPROVAL,
      this.serviceReimbursementAppravalData
    );
  }

  //  Pro manager Timesheet View by id
  serviceViewTimesheetById: string;
  public getProManagerTimesheetViewById(timeSheetId) {
    this.serviceViewTimesheetById = timeSheetId;
    console.log(this.serviceViewTimesheetById);
    return this.http.get(
      this.constants.API_GET_PRO_TIMESHEET_VIEW_BY_ID +
        "?Id=" +
        this.serviceViewTimesheetById
    );
  }

  // Pro manager timesheet filter
  serviceTimesheetFilterData: any;
  public getProTimesheetFilter(filterData) {
    this.serviceTimesheetFilterData = filterData;
    console.log(this.serviceTimesheetFilterData);
    return this.http.post(
      this.constants.API_GET_PRO_MANAGER_TIMESHEET_FILTER,
      this.serviceTimesheetFilterData
    );
  }

  // Emp timesheet filter
  serviceEmpTimesheetFilterData: any;
  public getEmpTimesheetFilter(filterData) {
    this.serviceEmpTimesheetFilterData = filterData;
    console.log(this.serviceEmpTimesheetFilterData);
    return this.http.post(
      this.constants.API_GET_EMP_TIMESHEET_FILTER,
      this.serviceEmpTimesheetFilterData
    );
  }

  // get user details
  public getUserDetails() {
    return this.http.get(this.constants.API_GET_USER_DETAILS);
  }

  // get all username and userid
  public getAllUser() {
    return this.http.get(this.constants.API_GET_ALL_USERNAME_AND_USERID);
  }
  // project manager approved timesheet show
  public getProManApprovedTimesheet() {
    return this.http.get(this.constants.API_GET_PRO_TIMESHEET_HISTORY);
  }

  // project manager declined timesheet show
  public getProManDeclinedTimesheet() {
    return this.http.get(this.constants.API_GET_PRO_TIMESHEET_DECLINED);
  }

  // project manager approved Leave show
  public getProManApprovedLeave() {
    return this.http.get(this.constants.API_GET_PRO_MAN_APPROVED_LEAVE);
  }

  // project manager approved Leave show
  public getProManDeclinedLeave() {
    return this.http.get(this.constants.API_GET_PRO_MAN_DECLINED_LEAVE);
  }

  // project manager leave request view
  public getProManLeaveRequestView() {
    return this.http.get(this.constants.API_GET_PRO_MAN_LEAVE_REQUEST_VIEW);
  }

  // project manager timesheet history filter
  serviceProManTimesheetHistoryFilter: any;
  public getProManTimesheetHistoryFilter(serviceProManTimesheetHistoryFilter) {
    this.serviceProManTimesheetHistoryFilter =
      serviceProManTimesheetHistoryFilter;
    return this.http.post(
      this.constants.API_GET_PRO_MAN_TIMESHEET_HISTORY_FILTER,
      this.serviceProManTimesheetHistoryFilter
    );
  }

  // project manager leave request filter
  servicefilterdata: any;
  public getLeaveRequestHistoryFilter(filterdata) {
    this.servicefilterdata = filterdata;
    return this.http.post(
      this.constants.API_GET_PRO_MAN_LEAVE_HISTORY_FILTER,
      this.servicefilterdata
    );
  }

  // project manager leave history filter
  public getLeaveRequestHistory() {
    return this.http.get(this.constants.API_GET_PRO_MAN_LEAVE_REQUEST_HISTORY);
  }

  // project manager Reimbursment History Filter
  serviceReimbursmentHistoryFilter: any;
  public getReimbursmentHistoryFilter(filterData) {
    this.serviceReimbursmentHistoryFilter = filterData;
    return this.http.post(
      this.constants.API_GET_PRO_MAN_REIMBURSEMENT_HISTORY_FILTER,
      this.serviceReimbursmentHistoryFilter
    );
  }

  // Pro manager reimbursement history view
  public getReimbursmentHistory() {
    return this.http.get(this.constants.API_GET_PRO_REIMBURSEMENT_HISTORY_VIEW);
  }

  // project manager Reimbursement request filter
  serviceReimbursmentApprovalViewFilter: any;
  public getReimbursmentApprovalViewFilter(filterData) {
    this.serviceReimbursmentApprovalViewFilter = filterData;
    return this.http.post(
      this.constants.API_GET_PRO_MAN_REIMBURSEMENT_REQUEST_FILTER,
      this.serviceReimbursmentApprovalViewFilter
    );
  }

  // project manager leave request filter
  serviceLeaveApprovalViewFilter: any;
  public getRequestLeaveApprovalViewFilter(filterData) {
    this.serviceLeaveApprovalViewFilter = filterData;
    return this.http.post(
      this.constants.API_GET_PRO_MAN_LEAVE_REQUEST_FILTER,
      this.serviceLeaveApprovalViewFilter
    );
  }

  //graph show
  public getShowGraph() {
    return this.http.get(this.constants.API_GET_GRAPH);
  }

  //hours and date for validate billable filed in timesheet
  serviceBillable: any;
  public getTimeSheetBillable(BillableorNot) {
    return this.http.post(
      this.constants.API_GET_HOURS_AND_DATE_VALIDATE_BILLABLE,
      BillableorNot
    );
  }
  //multiple approve and reject
  public getSelectTimeSheetApproval(multipleApproveAndReject){
    return this.http.post(this.constants.API_GET_MULTIPLE_APPROVE_OR_REJECT,multipleApproveAndReject)
  }

//leave cancel feature
public getLeaveCancel(leaveCancel){
  return this.http.post(this.constants.API_GET_LEAVE_CANCEL_FEATURE,leaveCancel)
}

//checking leave for nagative (lop)
public getLeaveRequestApprovalCheck(leaveCheck){
  return this.http.post(this.constants.API_GET_CHECK_LEAVE_BEFORE_LOP,leaveCheck)
}

//checking leave for negative (lop)
public getCreateLeaveRequestCheck(requestLeave){
  return this.http.post(this.constants.API_GET_CHECK_LEAVE_LOP_WHILE_REQUESTING,requestLeave)
}

//check lop before proceed leave-for employee
public getCreateLeaveRequestCheckLOP(requestLeave){
  return this.http.post(this.constants.API_GET_CHECK_LOP_FOR_EMPLOYEE,requestLeave)
}

//after cheeck lop proveed to apply leave/wfh
public getCreateLeaveRequestLOP(requestLeave){
  return this.http.post(this.constants.API_GET_AFTER_CHECK_LOP_PROCEED_TO_APPLY_LEAVE_OR_WFH,requestLeave)
}

}
