import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from "@angular/common/http";
import { catchError, Observable, throwError } from "rxjs";
import { AuthService } from "../auth.service/auth.service";
import { Router } from "@angular/router";
import { ConstantService } from "../../service/constant.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private router: Router,
    private constant: ConstantService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return this.doInterception(next, request);

    return next.handle(request).pipe(
      catchError((err) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 403) {
            this.router.navigate(["/qr2"]);
          }
        }

        return throwError(err);
      })
    );
  }

  private doInterception(
    next: HttpHandler,
    request: HttpRequest<any>
  ): Observable<HttpEvent<any>> {
    return next.handle(this.cloneWithHeaders(request)).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 412) {
          this.router.navigate(["/"]);
        }
        if (error.status === 403) {
          console.log('403')
          localStorage.clear();
          this.router.navigate(["/"]);
        }
        if (error.status === 401) {
          console.log('401')
          localStorage.clear();
          this.router.navigate(["/"]);
        }

        throw error;
      })
    );
  }

  /**
   * Clones the request
   * Add the headers
   * Replaces the URL to correct ones
   * @param request incoming request
   */
  private cloneWithHeaders(request: HttpRequest<any>) {
    let Pubtoken = localStorage.getItem(this.constant.LOCAL_STORE_TOKEN);
    let AuthToken = localStorage.getItem(this.constant.LOCAL_AUTH_TOKEN);

    //No need to attach headers on absence
    if (!(Pubtoken && AuthToken)) {
      return request;
    }

    return request.clone({
      headers: request.headers
        .set("OPSCAPEAUTHKEY", AuthToken)
        .set("OPSCAPEPUBKEY", Pubtoken),
    });
  }
}
