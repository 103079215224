<div class="container-fluid ">
  <div class="account-pages my-5 pt-md-5">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-8 col-lg-6 col-xl-5">
          <div class="card overflow-hidden">
            <div class="bg-soft bg-primary">
              <div class="row">
                <div class="col-7">
                  <div class="text-primary p-4">
                    <h5 class="text-primary">Welcome Back !</h5>
                    <p>Sign in to continue to Orderstack.</p>
                  </div>
                </div>
                <div class="col-5 align-self-end">
                  <img
                    src="assets/images/profile-img.png"
                    alt=""
                    class="img-fluid"
                  />
                </div>
              </div>
            </div>
            <div class="card-body pt-5 bg-info">
              <div class="mx-auto d-block">
                <img
                  class="img-fluid"
                  src="/assets/images/brands/org-logos_orderstack.png"
                  alt=""
                  height="14"
                />
              </div>
              <div class="p-2">
                <div class="mt-3 d-grid">
                  <button
                    (click)="logIn()"
                    class="btn btn-primary"
                    type="submit"
                  >
                    Log In with corporate account
                  </button>
                </div>

                <div class="mt-4 text-center">
                  <a (click)="ForgetPassword()" class="text-light"
                    ><i class="mdi mdi-lock mr-1"></i> Forgot your password?</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>

<!-- popup -->
