import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LayoutComponent } from "./Common/components/layout/layout.component";
import { VerticalComponent } from "./Common/components/layout/vertical/vertical.component";
import { EmpReimburshmentShowComponent } from "./Pages/emp-reimburshment-show/emp-reimburshment-show.component";
import { EmpReimburshmentComponent } from "./Pages/emp-reimburshment/emp-reimburshment.component";
import { EmpTimeSheetComponent } from "./Pages/emp-time-sheet/emp-time-sheet.component";
import { EmphomeComponent } from "./Pages/emphome/emphome.component";
import { EmployeehomeComponent } from "./Pages/employeehome/employeehome.component";
import { HomeComponent } from "./Pages/home/home.component";
import { InvalidOtpComponent } from "./Pages/invalid-otp/invalid-otp.component";
import { LoginComponent } from "./Pages/login/login.component";
import { QrComponent } from "./Pages/qr/qr.component";
import { Qr2Component } from "./Pages/qr2/qr2.component";
import { ProManagerHomeComponent } from "./Pages/project_manager/pro-manager-home/pro-manager-home.component";
import { ProManagerTimesheeetComponent } from "./Pages/project_manager/pro-manager-timesheeet/pro-manager-timesheeet.component";
import { ProManagerProjectsComponent } from "./Pages/project_manager/pro-manager-projects/pro-manager-projects.component";
import { ProManagerReimbursementComponent } from "./Pages/project_manager/pro-manager-reimbursement/pro-manager-reimbursement.component";
import { ProManagerLeaveComponent } from "./Pages/project_manager/pro-manager-leave/pro-manager-leave.component";
import { ProManagerTimeSheetRequestComponent } from "./Pages/project_manager/pro-manager-timesheeet/pro-manager-time-sheet-request/pro-manager-time-sheet-request.component";
import { ProManagerReimbursementRequestComponent } from "./Pages/project_manager/pro-manager-reimbursement/pro-manager-reimbursement-request/pro-manager-reimbursement-request.component";
import { ProManagerLeaveRequestComponent } from "./Pages/project_manager/pro-manager-leave/pro-manager-leave-request/pro-manager-leave-request.component";
import { ProManagerProjectAddComponent } from "./Pages/project_manager/pro-manager-projects/pro-manager-project-add/pro-manager-project-add.component";
import { ProManDeclinedShowComponent } from "./Pages/project_manager/pro-manager-timesheeet/pro-man-declined-show/pro-man-declined-show.component";
import { ProManApprovedShowComponent } from "./Pages/project_manager/pro-manager-timesheeet/pro-man-approved-show/pro-man-approved-show.component";
import { ProManReimbursementApprovedComponent } from "./Pages/project_manager/pro-manager-reimbursement/pro-man-reimbursement-approved/pro-man-reimbursement-approved.component";
import { ProManReimbursementDeclinedComponent } from "./Pages/project_manager/pro-manager-reimbursement/pro-man-reimbursement-declined/pro-man-reimbursement-declined.component";
import { ProManLeaveApprovedComponent } from "./Pages/project_manager/pro-manager-leave/pro-man-leave-approved/pro-man-leave-approved.component";
import { ProManLeaveDeclinedComponent } from "./Pages/project_manager/pro-manager-leave/pro-man-leave-declined/pro-man-leave-declined.component";
import { EmpLeaveComponent } from "./Pages/employee-leave/employee-leave.component";
import { AuthGuardGuard} from '../app/guards/auth-guard.guard';
import { AuthGuard2Guard} from '../app/guards/auth-guard2.guard';
import { EmployeeGuard} from '../app/guards/auth-guard.guard';

const routes: Routes = [
  { path: "", component: LoginComponent,pathMatch:"full",canActivate: [AuthGuard2Guard] },
  { path: "setup-2fa", component: QrComponent },
  { path: "2fa-roadblock", component: Qr2Component },
  { path: "home",component: LayoutComponent,
    children: [
      { path: "employee-home", component: EmphomeComponent,canActivate: [AuthGuard2Guard], data: { role: 'Employee' }},
      { path: "employee-reimburshment", component: EmpReimburshmentShowComponent,canActivate: [AuthGuard2Guard], data: { role: 'Employee' } },
      { path: "employee-timesheet", component: EmpTimeSheetComponent,canActivate: [AuthGuard2Guard], data: { role: 'Employee' } },
      { path: "employee-leave", component: EmpLeaveComponent,canActivate: [AuthGuard2Guard], data: { role: 'Employee' } },
      
      // { path: "project-manager-home", component: ProManagerHomeComponent },
      // { path: "project-manager-timesheeet", component: ProManagerTimesheeetComponent },
      // {path:"project-manager-timesheeet-request",component:ProManagerTimeSheetRequestComponent},
      // { path: "projectmanagerdeclinedtimesheetshow", component: ProManDeclinedShowComponent },
      // { path: "project-manager-timesheet-history", component: ProManApprovedShowComponent }, 
      // { path: "projectmanagerproject", component: ProManagerProjectsComponent },
      // { path: "promanprojectadd", component: ProManagerProjectAddComponent },
      // { path: "project-manager-reimbursement", component: ProManagerReimbursementComponent },
      // { path: "project-manager-reimbursement-request", component: ProManagerReimbursementRequestComponent },
      // { path: "project-manager-reimbursement-history", component: ProManReimbursementApprovedComponent },
      // { path: "project-manager-leave", component: ProManagerLeaveComponent },
      // { path: "project-manager-leave-request", component: ProManagerLeaveRequestComponent },
     
      // { path: "project-manager-leave-history", component: ProManLeaveApprovedComponent },
      // { path: "projectmanagerleavedeclined", component: ProManLeaveDeclinedComponent },
    ],
    
  },

  { path: "home",component: LayoutComponent,
    children: [    
      { path: "project-manager-home", component: ProManagerHomeComponent,canActivate: [AuthGuard2Guard], data: { role: 'Admin' } },
      { path: "project-manager-timesheeet", component: ProManagerTimesheeetComponent,canActivate: [AuthGuard2Guard], data: { role: 'Admin' } },
      {path:"project-manager-timesheeet-request",component:ProManagerTimeSheetRequestComponent,canActivate: [AuthGuard2Guard], data: { role: 'Admin' }},
      { path: "projectmanagerdeclinedtimesheetshow", component: ProManDeclinedShowComponent },
      { path: "project-manager-timesheet-history", component: ProManApprovedShowComponent,canActivate: [AuthGuard2Guard], data: { role: 'Admin' } }, 
      { path: "projectmanagerproject", component: ProManagerProjectsComponent },
      { path: "promanprojectadd", component: ProManagerProjectAddComponent },
      { path: "project-manager-reimbursement", component: ProManagerReimbursementComponent,canActivate: [AuthGuard2Guard], data: { role: 'Admin' } },
      { path: "project-manager-reimbursement-request", component: ProManagerReimbursementRequestComponent,canActivate: [AuthGuard2Guard], data: { role: 'Admin' } },
      { path: "project-manager-reimbursement-history", component: ProManReimbursementApprovedComponent,canActivate: [AuthGuard2Guard], data: { role: 'Admin' } },
      { path: "project-manager-leave", component: ProManagerLeaveComponent,canActivate: [AuthGuard2Guard], data: { role: 'Admin' } },
      { path: "project-manager-leave-request", component: ProManagerLeaveRequestComponent,canActivate: [AuthGuard2Guard], data: { role: 'Admin' } },
     
      { path: "project-manager-leave-history", component: ProManLeaveApprovedComponent,canActivate: [AuthGuard2Guard], data: { role: 'Admin' } },
      { path: "projectmanagerleavedeclined", component: ProManLeaveDeclinedComponent },
    ],
    
  },
  { path: "**", component: LoginComponent },
 
  { path: "invalid", component: InvalidOtpComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: "top" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
