import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Route,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "../Common/authentication/auth.service/auth.service";
import { SharedService } from "../Common/service/shared.service";
import { Qr2Component } from "../Pages/qr2/qr2.component";

@Injectable({
  providedIn: "root",
})
export class AuthGuardGuard implements CanActivateChild {
  constructor(private router: Router, private authService: AuthService) {}
  userType: any;
  canActivateChild() {
    console.log("project manager guard");
    if (localStorage.getItem("AUTH_KEY")) {
      this.authService.getUserDetails().subscribe((data: any) => {
        if (data[0].userType == "Admin") {
          console.log(data[0].userType, "userrr admin");
          this.userType = true;
        } else this.userType = false;
      });
    }
    if (this.userType == true) return true;
    else {
      this.router.navigate(["/login"]);
      return false;
    }
  }
}

@Injectable({
  providedIn: "root",
})
export class EmployeeGuard implements CanActivateChild {
  constructor(private router: Router, private authService: AuthService) {}
  userType: any;
  canActivateChild() {
    console.log("employeeguard");
    if (localStorage.getItem("AUTH_KEY")) {
      this.authService.getUserDetails().subscribe((data: any) => {
        if (data[0].userType == "Employee") {
          console.log(data[0].userType, "userrr employee");
          this.userType = true;
        } else this.userType = false;
      });
    }
    if (this.userType == true) return true;
    else {
      console.log("else in emp guard");
      this.router.navigate(["/login"]);
      return false;
    }
  }
}
