import { Component, ViewChild, OnInit, ElementRef } from "@angular/core";
import { B } from "@fullcalendar/core/internal-common";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AuthService } from "src/app/Common/authentication/auth.service/auth.service";
import { filterData, reimbursement } from "./emp-reim-models";
import Swal from "sweetalert2";
import { DomSanitizer } from "@angular/platform-browser";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ConsoleService } from "@ng-select/ng-select/lib/console.service";


@Component({
  selector: "app-emp-reimburshment-show",
  templateUrl: "./emp-reimburshment-show.component.html",
  styleUrls: ["./emp-reimburshment-show.component.scss"],
})
export class EmpReimburshmentShowComponent implements OnInit {
  reimbursementData: any;
  projectName: any;
  form: FormGroup;
  base64String: any;
  emptyData = false;
  hidePreviousButton: boolean;
  hideNextButton: boolean;
  pageIndex = 1;
  pageSize = 10;

  ngOnInit() {
       this.TableShow(this.pageIndex,this.pageSize);

    this.authService.getProjectName().subscribe((data: any) => {
      console.log(data);
      this.projectName = data;
    });
  }
  constructor(
    private fb: FormBuilder,
    private modalService: NgbModal,
    private authService: AuthService,
    private sanitizer: DomSanitizer
  ) {
    this.form = this.fb.group({
      type: ["", Validators.required],
      date: ["", Validators.required],
      projectid: ["", Validators.required],
      details: [""],
      amount: ["", Validators.required],
      pdf: ["", Validators.required],
    });
  }

  base64: any;

  reimbursement: reimbursement;

  fileName: string;
  fileNameShow = false;
  file: any;
  onFileChange(event) {
    this.file = event.target.files[0];
    this.fileNameShow = true;
    this.fileName = this.file.name;
    const reader = new FileReader();
    reader.readAsDataURL(this.file);
    reader.onload = () => {
      console.log(reader.result);
      this.base64 = reader.result;
    };
    if (this.file.type !== "application/pdf") {
      // Show error message or reset the file input
      return false;
    }
    // Do something with the file, for example, upload it to the server
  }

  @ViewChild("fileInput")
  myInputVariable: ElementRef;

  InputVar: ElementRef;
  RequestReimbursement() {
    this.reimbursement = {
      DateOfReimbursement: this.form.value.date,
      ReimbursementType: this.form.value.type,
      ProjectId: this.form.value.projectid,
      Details: this.form.value.details,
      Attachment: this.base64,
      Amount: this.form.value.amount,
    };
    console.log(this.reimbursement);
    this.authService
      .getReimbursementRequest(this.reimbursement)
      .subscribe((data: any) => {
        console.log(data);

        if (data.message == "You cannot add future Reimbursement") {
          try {
            Swal.fire({
              title: "Enter a valid date",
              text: "You can only add reimbursement upto today",
              icon: "warning",
              cancelButtonColor: "#f46a6a",
            });
          } finally {
            this.close();
          }
        } else if (data.message == "Reimbursement Added")
          try {
            {
              Swal.fire({
                position: "center",
                icon: "success",
                title: "Reimbursement Requested",
                showConfirmButton: false,
                timer: 1000,
              });
              this.form.reset();
              this.myInputVariable.nativeElement.value = "";
              // this.fileNameShow = false;
              // this.ngOnInit();
            }
          } finally {
            this.fileNameShow = false;
            this.emptyData = false;
            this.ngOnInit();
            this.close();
          }
      });
    // this.fileNameShow = false;
    // this.ngOnInit();
  }

  submitted: boolean = true;
  ProjectName: any;

  addExpense(content: any) {
    this.submitted = false;
    this.modalService.open(content, { size: "lg", centered: true });
    // api for projectId
    this.authService.getProjectName().subscribe((data: any) => {
      this.ProjectName = data;
      console.log(this.ProjectName);
    });
  }

  from = "";
  to = "";
  projectId = "";
  reimbursemenType = "";
  filterdata: filterData;
  filterView: boolean = false;
  filterData: any;
  loader:boolean;
  Filter() {
    this.loader=true;
    this.pageIndex=1;
    this.filterView = true;

    this.filterdata = {
      From: this.from,
      To: this.to,
      ProjectId: this.projectId,
      ReimbursementType: this.reimbursemenType,      
      PageIndex:this.pageIndex,
      PageSize:this.pageSize
    };
    console.log(this.filterdata, "filterdata");
    console.log(this.from, "from");
    this.authService
      .getReimbursementFilter(this.filterdata)
      .subscribe((data: any) => {
        console.log(data);
        this.filterData = data;
        this.loader=false;        
        if (data == "") {
          console.log("if condition");
          Swal.fire({
            title: "No data available ",
            text: " ",
            icon: "warning",
            cancelButtonColor: "#f46a6a",
          });
          this.emptyData = true;
          this.hideNextButton = true;
          this.hidePreviousButton = true;
        } else if (data != "") {
          this.emptyData = false;
          this.hideNextButton = false;
          this.hidePreviousButton = false;
        }
        if (this.pageIndex == 1  && data != "") {
          this.hidePreviousButton = true;
          this.hideNextButton = false;
        } else if (this.pageIndex != 1 && data == "") {
          this.hideNextButton = true;
          this.hidePreviousButton = false;
        }
      });
  }

  TableShow(pageIndex, pageSize) {
    this.loader=true;
    this.filterView = true;
    this.emptyData=false;

    this.filterdata = {
      From: this.from,
      To: this.to,
      ProjectId: this.projectId,
      ReimbursementType: this.reimbursemenType,      
      PageIndex:pageIndex,
      PageSize:pageSize
    };
    console.log(this.filterdata, "filterdata");
    console.log(this.from, "from");
    this.authService
      .getReimbursementFilter(this.filterdata)
      .subscribe((data: any) => {
        console.log(data);
        this.filterData = data;
        this.loader=false;        
        if (data == "") {
          console.log("if condition");
          this.emptyData = true;
          this.hideNextButton = true;
          this.hidePreviousButton = true;
        } else if (data != "") {
          this.emptyData = false;
          this.hideNextButton = false;
          this.hidePreviousButton = false;
        }
        if (this.pageIndex == 1  && data != "") {
          this.hidePreviousButton = true;
          this.hideNextButton = false;
        } else if (this.pageIndex != 1 && data == "") {
          this.hideNextButton = true;
          this.hidePreviousButton = false;
        }
      });
  }

  next() {
    this.pageIndex = this.pageIndex + 1;
    this.TableShow(this.pageIndex, this.pageSize);
  }

  previous() {
    this.pageIndex = this.pageIndex - 1;
    this.TableShow(this.pageIndex, this.pageSize);
  }

  baseFile: any;

  ClearFilter() {
    this.emptyData = false;
    this.to = "";
    this.from = "";
    this.projectId = "";
    this.reimbursemenType = "";
    this.Filter();
  }

  close() {
    this.form.reset();
    this.fileNameShow = false;
  }

  Document: any;
  Docshow(id) {
    console.log(id, "parameter value");
    // for (var i = 0; i < reimbursementData.length; i++) {
    //   if (id == this.reimbursementData[i].reimbursmentId) {
    //     this.Document = this.reimbursementData[i].attachment;
    //   }
    // }
    console.log(this.filterData)
    this.filterData.forEach((element) => {
      if (id == element.reimbursmentId) {
        
        this.Document = element.attachment;
      }
    });
    console.log(this.Document);

    this.base64String = this.Document.split(",");
    this.base64String = this.base64String[1];
    console.log("this.base^$", this.base64String);
    var blob = this.b64toBlob(this.base64String, "application/pdf");
    console.log("blob", blob);
    let a = document.createElement("a");
    document.body.appendChild(a);
    // var url = window.URL.createObjectURL(blob);
    var url = URL.createObjectURL(
      new Blob([blob], { type: "application/pdf" })
    );
    a.href = url;
    a.download = String("Download.pdf");
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }
  public b64toBlob(b64Data, contentType) {
    contentType = contentType || "";
    let sliceSize = 512;
    console.log(b64Data);
    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  Id: any;
  element: any;
  viewDetails(id) {
    this.reimbursementData.forEach((elements) => {
      if (id == elements.reimbursmentId) {
        this.element = elements;
        console.log(this.element, "reimbursement details");
      }
    });
  }

  viewDetailsforFilterData(id) {
    console.log(id, "filter id");
    this.filterData.forEach((elements) => {
      if (id == elements.reimbursmentId) {
        this.element = elements;
        console.log(this.element, "reimbursement details for filter data");
      }
    });
  }

  popupViewDetails(content: any) {
    this.modalService.open(content, { size: "md", centered: true });
  }

  page = 1;
  count = 0;
  tableSize = 10;
  tableSizes = [3, 6, 9, 12];

  onTableDataChange(event) {
    this.page = event;
    // this.TimesheetTable();
  }
}
