import { Component, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AuthService } from "src/app/Common/authentication/auth.service/auth.service";
import { filterdate, leave,leaveCancelOption } from "./model";
import Swal from "sweetalert2";
import { DatePipe } from "@angular/common";
import { FormGroup,Validators,FormControl, FormBuilder } from "@angular/forms";;



@Component({
  selector: 'app-emp-leave',
  templateUrl: './employee-leave.component.html',
  styleUrls: ['./employee-leave.component.scss']
})
export class EmpLeaveComponent implements OnInit {
  form: FormGroup;
  constructor(
    private modalService: NgbModal,
    private authService: AuthService,
    private datePipe: DatePipe,
    private fb: FormBuilder
  ) {
    this.form = this.fb.group({
      date: ["", Validators.required],
      type: ["", Validators.required],
      remarks: [""],
    });
  }

  LeaveTable: any;
  data2: any;
  emptyData = false;
  loader :boolean;
  casualCount: any;
  sickCount: any;
  wfhCount: any;
  casualLimit: any;
  sickLimit: any;
  wfhLimit: any;
  hidePreviousButton: boolean;
  hideNextButton: boolean;
  pageIndex = 1;
  pageSize = 10;

  ngOnInit() {
    // this.authService.getDisplayLeaveTable().subscribe((data: any) => {
    //   if (data.length == 0) {
    //     // Swal.fire("Empty!", '',"warning");
    //     this.emptyData = true;
    //   }
    //   this.LeaveTable = data;
    //   console.log(this.LeaveTable, "leave table");
    // });
    this.TableShow(this.pageIndex,this.pageSize);

    console.log(this.LeaveTable, "leavetablessssssssssss");

    // api for view leave remaining
    this.authService.getDisplayLeaveDetails().subscribe((data: any) => {
      console.log(data);
      this.casualCount = data.causualCount;
      this.sickCount = data.sickCount;
      this.wfhCount = data.wfh;
      this.casualLimit = data.causalLimit;
      this.sickLimit = data.sickLimit;
      this.wfhLimit = data.wfhLimit;
      // this.data2 = data;
      console.log(
        this.casualCount,
        this.casualLimit,
        this.wfhCount,
        this.wfhLimit,
        this.sickCount,
        this.sickLimit
      );
    });
  }

  leavePopup(content: any) {
    this.modalService.open(content, { size: "md", centered: true });
  }

  date = "";
  date2: any;
  type = "";
  remarks = "";
  leave: leave;
  a = 10;
  lopShow: boolean;

  Requestleave() {
    // this.date2 = this.datePipe.transform(new Date(this.date), "dd-MMM-yyyy");

    this.leave = {
      DateOfLeave: this.form.value.date,
      TypeOfLeave: this.form.value.type,
      Remarks: this.form.value.remarks,
    };
      this.authService
        .getCreateLeaveRequestCheckLOP(this.leave)
        .subscribe((data: any) => {
          try {
            if (data.message == "Leave Already applied for current date") {
              Swal.fire({
                title: "",
                text: "Leave/WFH already applied for this date",
                icon: "warning",
                cancelButtonColor: "#f46a6a",
              });
            } else if (
              data.message == "You have taken maximum number of sick leaves"
            ) {
              Swal.fire({
                title: "",
                text: "You have taken maximum number of sick leaves",
                icon: "warning",
                cancelButtonColor: "#f46a6a",
              });
            } else if (
              data.message ==
              "Mininum working hours required to apply a half day leave is 4 hours"
            ) {
              Swal.fire({
                title: "",
                text: "Mininum working hours required to apply a half day leave is 4 hours",
                icon: "warning",
                cancelButtonColor: "#f46a6a",
              });
            } else if (
              data.message == "You have already added timesheet for this date"
            ) {
              Swal.fire({
                title: "",
                text: "You have already added timesheet for this date",
                icon: "warning",
                cancelButtonColor: "#f46a6a",
              });
            } else if (data.message == "WFH LIMIT EXCEEDED") {
              //
              const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                  confirmButton: "btn btn-success",
                  cancelButton: "btn btn-danger ms-2",
                },
                buttonsStyling: false,
              });

              swalWithBootstrapButtons
                .fire({
                  title: "Limit Exceeded",
                  text: "Your Work From Home limit is exceeded! Do you want to proceed it?",
                  icon: "warning",
                  confirmButtonText: "Yes, Proceed it!",
                  cancelButtonText: "No, Cancel!",
                  showCancelButton: true,
                })
                .then((result) => {
                  if (result.value) {
                    this.authService
                      .getCreateLeaveRequestLOP(this.leave)
                      .subscribe((data: any) => {
                        try {
                          if (data.message == "Leave Requested") {
                            Swal.fire({
                              position: "center",
                              icon: "success",
                              title: "Applied",
                              showConfirmButton: false,
                              timer: 1000,
                            });
                          }
                        } finally {
                          this.form.reset();
                          this.emptyData = false;
                          this.ngOnInit();
                        }
                      });
                  }
                });
              
            } else if (data.message == "LOP") {
              const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                  confirmButton: "btn btn-success",
                  cancelButton: "btn btn-danger ms-2",
                },
                buttonsStyling: false,
              });

              swalWithBootstrapButtons
                .fire({
                  title: "Leave Request - Loss of Pay",
                  text: "You have no more paid leave, your requested time off will be treated as LOP",
                  icon: "warning",
                  confirmButtonText: "Yes, Proceed it!",
                  cancelButtonText: "No, Cancel!",
                  showCancelButton: true,
                })
                .then((result) => {
                  if (result.value) {
                    this.authService
                      .getCreateLeaveRequestLOP(this.leave)
                      .subscribe((data: any) => {
                        console.log(data, "leave added");
                        try {
                          if (data.message == 'Leave Requested') {
                            Swal.fire({
                              position: "center",
                              icon: "success",
                              title: "Applied",
                              showConfirmButton: false,
                              timer: 1000,
                            });
                          }
                        } finally {
                          this.form.reset();
                          this.emptyData = false;
                          this.ngOnInit();
                        }
                      });
                  }
                });
            }
            else if (data.message == "Leave Requested"){
              Swal.fire({
                position: "center",
                icon: "success",
                title: "Applied",
                showConfirmButton: false,
                timer: 1000,
              });
            }
          } finally {
            this.form.reset();
            this.emptyData = false;
            this.ngOnInit();
          }
        })
  }

  ApplyLeaveOrWfh() {
    // this.date2 = this.datePipe.transform(new Date(this.date), "dd-MMM-yyyy");

    this.leave = {
      DateOfLeave: this.form.value.date,
      TypeOfLeave: this.form.value.type,
      Remarks: this.form.value.remarks,
    };
    this.authService
      .getCreateLeaveRequestCheck(this.leave)
      .subscribe((data: any) => {
        if (data.isSuccess == false) {
          this.lopShow = true;
        } else if (data.isSuccess == true) {
          this.lopShow = false;
        }
        if (this.lopShow == false) {
          this.authService
            .getLeaveRequest(this.leave)
            .subscribe((data: any) => {
              console.log(data, "leave added");
              try {
                if (data.message == "Leave Already applied for current date") {
                  Swal.fire({
                    title: "",
                    text: "Leave already applied for this date",
                    icon: "warning",
                    cancelButtonColor: "#f46a6a",
                  });
                } else if (
                  data.message == "You have taken maximum number of sick leaves"
                ) {
                  Swal.fire({
                    title: "",
                    text: "You have taken maximum number of sick leaves",
                    icon: "warning",
                    cancelButtonColor: "#f46a6a",
                  });
                } else if (
                  data.message ==
                  "Mininum working hours required to apply a half day leave is 4 hours"
                ) {
                  Swal.fire({
                    title: "",
                    text: "Mininum working hours required to apply a half day leave is 4 hours",
                    icon: "warning",
                    cancelButtonColor: "#f46a6a",
                  });
                } else if (
                  data.message ==
                  "You have already added timesheet for this date"
                ) {
                  Swal.fire({
                    title: "",
                    text: "You have already added timesheet for this date",
                    icon: "warning",
                    cancelButtonColor: "#f46a6a",
                  });
                } else if (data.isSuccess == true) {
                  Swal.fire({
                    position: "center",
                    icon: "success",
                    title: "Applied",
                    showConfirmButton: false,
                    timer: 1000,
                  });
                }
              } finally {
                this.form.reset();
                this.emptyData = false;
                this.ngOnInit();
              }
            });
        } else if (this.lopShow == true) {
          //
          const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
              confirmButton: "btn btn-success",
              cancelButton: "btn btn-danger ms-2",
            },
            buttonsStyling: false,
          });

          swalWithBootstrapButtons
            .fire({
              title: "Leave Request - Loss of Pay",
              text: "You have no more paid leave, your requested time off will be treated as LOP",
              icon: "warning",
              confirmButtonText: "Yes, Apply it!",
              cancelButtonText: "No, Cancel!",
              showCancelButton: true,
            })
            .then((result) => {
              if (result.value) {
                this.authService
                  .getLeaveRequest(this.leave)
                  .subscribe((data: any) => {
                    console.log(data, "leave added");
                    try {
                      if (
                        data.message == "Leave Already applied for current date"
                      ) {
                        Swal.fire({
                          title: "",
                          text: "Leave already applied for this date",
                          icon: "warning",
                          cancelButtonColor: "#f46a6a",
                        });
                      } else if (
                        data.message ==
                        "You have taken maximum number of sick leaves"
                      ) {
                        Swal.fire({
                          title: "",
                          text: "You have taken maximum number of sick leaves",
                          icon: "warning",
                          cancelButtonColor: "#f46a6a",
                        });
                      } else if (
                        data.message ==
                        "Mininum working hours required to apply a half day leave is 4 hours"
                      ) {
                        Swal.fire({
                          title: "",
                          text: "Mininum working hours required to apply a half day leave is 4 hours",
                          icon: "warning",
                          cancelButtonColor: "#f46a6a",
                        });
                      } else if (
                        data.message ==
                        "You have already added timesheet for this date"
                      ) {
                        Swal.fire({
                          title: "",
                          text: "You have already added timesheet for this date",
                          icon: "warning",
                          cancelButtonColor: "#f46a6a",
                        });
                      } else if (data.isSuccess == true) {
                        Swal.fire({
                          position: "center",
                          icon: "success",
                          title: "Applied",
                          showConfirmButton: false,
                          timer: 1000,
                        });
                      }
                    } finally {
                      this.form.reset();
                      this.emptyData = false;
                      this.ngOnInit();
                    }
                  });
              }
            });
          //end
        }
      });
  }

  typeOnFilter = "";
  dateFrom = "";
  dateTo = "";
  filtervalue: boolean = false;
  filterdate: filterdate;
  filterData: any;
  Filter() {
    // this.datefrom = this.datePipe.transform(new Date(this.dateFrom), "dd-MMM-yyyy");
    // this.dateto = this.datePipe.transform(new Date(this.dateTo), "dd-MMM-yyyy");
    this.loader=true;
    this.pageIndex = 1;
    this.filtervalue = true;

    this.filterdate = {
      From: this.dateFrom,
      To: this.dateTo,
      Type: this.typeOnFilter,
      PageIndex:this.pageIndex,
      PageSize:this.pageSize
    };
    console.log(this.filterdate.From.toString());
    console.log(this.filterdate.From);
    this.authService
      .getLeaveRequestSort(this.filterdate)
      .subscribe((data: any) => {
        this.filterData = data;
        this.loader = false;
        if (data == "") {
          console.log("if condition");
          Swal.fire({
            title: "No data available",
            text: " ",
            icon: "warning",
            cancelButtonColor: "#f46a6a",
          });
          this.emptyData = true;
          this.hideNextButton = true;
          this.hidePreviousButton = true;
        } else if (data != "") {
          this.emptyData = false;
          this.hideNextButton = false;
          this.hidePreviousButton = false;
        }
        if (this.pageIndex == 1  && data != "") {
          this.hidePreviousButton = true;
          this.hideNextButton = false;
        } else if (this.pageIndex != 1 && data == "") {
          this.hideNextButton = true;
          this.hidePreviousButton = false;
        }
      });
  }

  TableShow(pageIndex, pageSize) {
    // this.datefrom = this.datePipe.transform(new Date(this.dateFrom), "dd-MMM-yyyy");
    // this.dateto = this.datePipe.transform(new Date(this.dateTo), "dd-MMM-yyyy");
    this.loader=true;
    this.filtervalue = true;
    this.emptyData=false;

    this.filterdate = {
      From: this.dateFrom,
      To: this.dateTo,
      Type: this.typeOnFilter,
      PageIndex:pageIndex,
      PageSize:pageSize
    };
    console.log(this.filterdate.From.toString());
    console.log(this.filterdate.From);
    this.authService
      .getLeaveRequestSort(this.filterdate)
      .subscribe((data: any) => {
        console.log(data,'474')
        this.filterData = data;
        this.loader = false;
        if (data == "") {
          this.emptyData = true;
          this.hideNextButton = true;
          this.hidePreviousButton = true;
        } else if (data != "") {
          console.log('else if')
          this.emptyData = false;
          this.hideNextButton = false;
          this.hidePreviousButton = false;
        }
        if (this.pageIndex == 1  && data != "") {
          this.hidePreviousButton = true;
          this.hideNextButton = false;
        } else if (this.pageIndex != 1 && data == "") {
          this.hideNextButton = true;
          this.hidePreviousButton = false;
        }
        console.log(this.emptyData,'last')

      });
  }

  next() {
    this.pageIndex = this.pageIndex + 1;
    this.TableShow(this.pageIndex, this.pageSize);
  }

  previous() {
    this.pageIndex = this.pageIndex - 1;
    this.TableShow(this.pageIndex, this.pageSize);
  }
  close() {
    this.form.reset();
  }

  ClearFilter() {
    this.dateFrom = "";
    this.dateTo = "";
    this.typeOnFilter = "";
    this.emptyData = false;
    this.Filter();
  }

  page = 1;
  count = 0;
  tableSize = 10;
  tableSizes = [3, 6, 9, 12];

  onTableDataChange(event) {
    this.page = event;
    // this.TimesheetTable();
  }

  leaveCancelId: any;
  leaveCancelOption: leaveCancelOption;

  cancelOption(leaveCancelId: string) {
    console.log(leaveCancelId, "leave cancel id");
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger ms-2",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Cancel Request",
        text: "Are you sure to want to cancel this request",
        icon: "warning",
        confirmButtonText: "Yes, Cancel it!",
        cancelButtonText: "No ",
        showCancelButton: true,
      })
      .then((result) => {
        if (result.value) {
          // this.leaveCancelId = leaveCancelId;

          this.leaveCancelOption = {
            LeaveRequestId: leaveCancelId,
            Cancel: true,
          };
          console.log(this.leaveCancelOption);
          this.authService
            .getLeaveCancel(this.leaveCancelOption)
            .subscribe((data: any) => {
              if (data.message == "You cannot cancel leave") {
                Swal.fire({
                  title: "",
                  text: "You cannot cancel leave on past date",
                  icon: "warning",
                  cancelButtonColor: "#f46a6a",
                });
              } else if (data.message == "Operation failed") {
                Swal.fire({
                  title: "",
                  text: "You cannot cancel a reviewed leave",
                  icon: "warning",
                  cancelButtonColor: "#f46a6a",
                });
              }
              console.log(data);
              this.Filter();
              // this.FilterforApprovedorRejected();
            });
        }
      });
  }
}
