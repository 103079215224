<!-- <div *ngIf="loader">
<app-loader></app-loader>
</div> -->
<div class="container-fluid">
  <h3 class="text-center mb-3 mt-1">Leave / WFH History</h3>
  <div class="row d-flex justify-content-evenly mb-4 g-3">
    <div class="col-xxl-2 col-lg-2 col-md-3 col-sm-3">
      <select
        class="form-control select2"
        [(ngModel)]="userId"
        placeholder="aaa"
      >
        <option value="" disabled selected>Employee Name</option>
        <option *ngFor="let d of allUser" value="{{ d.userID }}">
          {{ d.userName }}
        </option>
      </select>
    </div>
    <div class="col-xxl-2 col-lg-2 col-md-3 col-sm-3">
      <select class="form-control select2" [(ngModel)]="typeOnFilter">
        <option value="" disabled selected>Type</option>
        <option [ngStyle]="{ color: 'red' }" value="Casual">Casual Leave</option>
        <option [ngStyle]="{ color: 'red' }" value="Sickleave">Sick Leave</option>
        <option [ngStyle]="{ color: 'red' }" value="HalfDaySick">Half-day Sick Leave</option>
        <option [ngStyle]="{ color: 'red' }" value="HalfDay">
          Half-day Casual Leave
        </option>
        <option [ngStyle]="{ color: 'green' }" value="WFH">
          Work From Home
        </option>
      </select>
    </div>
    <div class="col-xxl-2 col-lg-2 col-md-3 col-sm-3">
      <div id="datepicker1">
        <input
          class="form-control"
          type="text"
          id="example-date-input"
          placeholder="From"
          onfocus="(this.type='date')"
          [(ngModel)]="dateFrom"
        />
      </div>
    </div>
    <div class="col-xxl-2 col-lg-2 col-md-3 col-sm-3">
      <div id="datepicker1">
        <input
          class="form-control"
          type="text"
          id="example-date-input"
          placeholder="To"
          onfocus="(this.type='date')"
          [(ngModel)]="dateTo"
          onfocus="(this.type='date')"
        />
      </div>
      <!-- input-group -->
    </div>
    <div class="col-xxl-2 col-lg-2 col-md-6 col-sm-6">
      <button (click)="Filter()" type="button" class="btn btn-secondary w-100">
        <i class="mdi mdi-filter-outline align-middle"></i> Filter
      </button>
    </div>
    <div class="col-xxl-2 col-lg-2 col-md-6 col-sm-6">
      <button
        (click)="ClearFilter()"
        type="button"
        class="btn btn-secondary w-100"
      >
        Clear Filter
      </button>
    </div>
  </div>

  <div *ngIf="!emptyData" class="table-responsive">
    <table *ngIf="!loader" class="table table-bordered align-middle nowrap">
      <thead>
        <tr class="text-center">
          <th scope="col">No.</th>
          <th scope="col">Name</th>
          <th scope="col">Onleave Date</th>
          <th scope="col">Requested On</th>
          <th scope="col">Type</th>
          <th scope="col">Remarks</th>
          <th scope="col">Status</th>
        </tr>
      </thead>

      <tbody>
       
        <tr
          *ngFor="
            let data of filterValue
            index as i
          "
          class="text-center"
        >
          <td scope="row">{{  ((this.pageSize*this.pageIndex)-this.pageSize)+i+1  }}</td>
          <td scope="row">{{ data.userName }}</td>
          <td>
            <h5>
              <span class="badge bg-warning">{{
                data.dateOfLeave | date : "dd-MMM-yyyy"
              }}</span>
            </h5>
          </td>
          <td>{{ data.dateOfRequest | date : "dd-MMM-yyyy" }}</td>

          <td>
            <h5>
              <span
                class="badge badge-pill-badge"
                [ngClass]="{
                  'bg-success': data.typeOfLeave == 'WFH',
                  'bg-danger':
                    data.typeOfLeave == 'Sickleave' ||
                    data.typeOfLeave == 'HalfDaySick' ||
                    data.typeOfLeave == 'Casual' ||
                    data.typeOfLeave == 'HalfDay'
                }"
              >
                <span *ngIf="data.typeOfLeave == 'Sickleave'"> Sick Leave</span>
                <span *ngIf="data.typeOfLeave == 'HalfDaySick'"> Half-day Sick Leave</span>
                <span *ngIf="data.typeOfLeave == 'HalfDay'">
                  Half-day Casual Leave</span
                >
                <span *ngIf="data.typeOfLeave == 'Casual'"> Casual Leave</span>
                <span *ngIf="data.typeOfLeave == 'WFH'"> Work From Home</span>
              </span>
            </h5>
          </td>
          <td>
            <span *ngIf="data.remarks != ''">{{ data.remarks }}</span>
            <span *ngIf="data.remarks == ''">-nil-</span>
            <span *ngIf="data.remarks == null">-nil-</span>
          </td>
          <td>
            <h5>
              <span
                class="badge"
                [ngClass]="{
                  'rounded-pill bg-info': data.isStatus === 'APPROVED',
                  'rounded-pill bg-danger': data.isStatus === 'DECLINED'
                }"
              >
                <span *ngIf="data.isStatus == 'APPROVED'">Approved</span>
                <span *ngIf="data.isStatus == 'DECLINED'">Rejected</span></span
              >
            </h5>
          </td>
        </tr>
      </tbody>
    </table>
    
  </div>
  <div *ngIf="emptyData" class="no-data-cell">No History to Show</div>
  <div  id="" *ngIf="loader">
    <div class="loader" id="status">
      <div class="spinner-chase">
        <div class="chase-dot"></div>
        <div class="chase-dot"></div>
        <div class="chase-dot"></div>
        <div class="chase-dot"></div>
        <div class="chase-dot"></div>
        <div class="chase-dot"></div>
      </div>
    </div>
  </div>
  <div *ngIf="!loader" class="d-flex justify-content-center mb-3">
    <div class="mr-4">
      <button *ngIf="!hidePreviousButton" (click)="previous()" class="btn btn-light btn-sm"><< Prev </button>
    </div>
    <div>  </div>
    <div>
      <button *ngIf="!hideNextButton" (click)="next()" class="btn btn-light btn-sm">Next >></button>
    </div>
  </div>
</div>
