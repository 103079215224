import { Component } from '@angular/core';

@Component({
  selector: 'app-pro-man-reimbursement-declined',
  templateUrl: './pro-man-reimbursement-declined.component.html',
  styleUrls: ['./pro-man-reimbursement-declined.component.scss']
})
export class ProManReimbursementDeclinedComponent {

}
