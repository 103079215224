<div *ngIf="loader">
  <app-loader></app-loader>
</div>

<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <div
        class="page-title-box d-flex align-items-center justify-content-between"
      >
        <h4 class="mb-0 font-size-18">
          {{ userType }} Dashboard
        </h4>
        <div class="page-title-right">
          <ol class="breadcrumb m-0"></ol>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-xl-4">
      <div _ngcontent-qlf-c154="" class="card overflow-hidden">
        <div _ngcontent-qlf-c154="" class="bg-primary bg-soft">
          <div _ngcontent-qlf-c154="" class="row">
            <div _ngcontent-qlf-c154="" class="col-7">
              <div _ngcontent-qlf-c154="" class="text-primary p-3">
                <h5 _ngcontent-qlf-c154="" class="text-primary">
                  Welcome Back !
                </h5>
                <p _ngcontent-qlf-c154=""></p>
              </div>
            </div>
            <div _ngcontent-qlf-c154="" class="col-5 align-self-end">
              <img
                _ngcontent-qlf-c154=""
                src="assets/images/profile-img.png"
                alt=""
                class="img-fluid"
              />
            </div>
          </div>
        </div>
        <div class="card-body pt-0">
          <div class="row">
            <div class="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
              <div class="avatar-md profile-user-wid mb-4">
                <img
                  _ngcontent-qlf-c154=""
                  src="../../../assets/images/opscape_avatar.png"
                  alt=""
                  class="img-thumbnail rounded-circle"
                />
              </div>
              <h5 _ngcontent-qlf-c154="" class="font-size-15 text-truncate">
                {{ userName }}
              </h5>
              <p
                _ngcontent-qlf-c154=""
                class="text-muted mb-0 text-truncate"
              ></p>
            </div>
            <div class="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
              <div _ngcontent-qlf-c154="" class="mb-4">
                <div _ngcontent-qlf-c154="" class="row">
                  <div _ngcontent-qlf-c154="" class="col-2">
                    <h5 _ngcontent-qlf-c154="" class="font-size-15"></h5>
                    <p _ngcontent-qlf-c154="" class="text-muted mb-0"></p>
                  </div>
                  <div _ngcontent-qlf-c154="" class="col-10 text-center mt-5">
                    <h5 _ngcontent-qlf-c154="" class="font-size-15"></h5>
                    <p
                      _ngcontent-qlf-c154=""
                      class="text-muted mb-0 projectMargin"
                    >
                      Projects : {{ noOfProjects }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />

      <div class="card mt-4">
        <div class="card-body">
          <h4 class="card-title mb-4">Projects</h4>
          <div class="row">
            <div class="col-sm-6">
              <h6 *ngFor="let p of projectName">{{ p.projectName }}</h6>

              <div class="mt-4">
                <a
                  [routerLink]="['../employee-timesheet']"
                  class="btn btn-primary btn-sm"
                >
                  Time Sheet <i class="mdi mdi-arrow-right ms-1"></i
                ></a>
              </div>
            </div>
            <div class="col-sm-6"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-xl-8">
      <div class="row">
        <div class="col-md-4">
          <div class="card mini-stats-wid">
            <div class="card-body">
              <a [routerLink]="['../project-manager-leave']">
                <h4 class="">Leave / WFH</h4>
                <div class="d-flex justify-content-between">
                  <div>
                    <p class="text-muted fw-medium mb-2">Casual</p>
                    <h6 class="mb-0 text-center">{{ casualCount }}</h6>
                  </div>
                  <div>
                    <p class="text-muted fw-medium mb-2">Sick</p>
                    <h6 class="mb-0 text-center">{{ sickCount }}</h6>
                  </div>
                  <div>
                    <p class="text-muted fw-medium mb-2">WFH</p>
                    <h6 class="mb-0 text-center">{{ wfhCount }}</h6>
                  </div>
                  <div class="flex-shrink-0 align-self-center">
                    <div
                      class="mini-stat-icon avatar-sm rounded-circle bg-primary"
                    >
                      <span class="avatar-title">
                        <i type="solid" class="bx bx-user-minus bx-sm"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>

        <div class="col-md-4">
          <div class="card mini-stats-wid">
            <div class="card-body">
              <a [routerLink]="['../project-manager-reimbursement']">
                <h4 class="">Reimbursement</h4>
                <div class="d-flex justify-content-between">
                  <div class="">
                    <p class="text-muted fw-medium mb-2">Approved</p>
                    <h6 class="mb-0 text-center">
                      {{ approved }}
                    </h6>
                  </div>
                  <div class="">
                    <p class="text-muted fw-medium mb-2">Pending</p>
                    <h6 class="mb-0 text-center">
                      {{ pending }}
                    </h6>
                  </div>
                  <div class="flex-shrink-0 align-self-center">
                    <div
                      class="mini-stat-icon avatar-sm rounded-circle bg-primary"
                    >
                      <span class="avatar-title">
                        <i class="bx bx-rupee bx-sm"></i>
                      </span>
                    </div>
                  </div></div
              ></a>
            </div>
          </div>
        </div>

        <div class="col-md-4">
          <div class="card mini-stats-wid">
            <div class="card-body">
              <a [routerLink]="['../project-manager-timesheeet']"
                ><h4 class="">Time sheet</h4>
                <div class="d-flex justify-content-between">
                  <div class="">
                    <p class="text-muted fw-medium mb-2">Reviewed</p>
                    <h6 class="mb-0 text-center">{{ reviewed }}</h6>
                  </div>
                  <div class="">
                    <p class="text-danger fw-medium mb-2">Overdue</p>
                    <h6 class="mb-0 text-center text-danger">
                      {{ overdue }}
                    </h6>
                  </div>
                  <div class="flex-shrink-0 align-self-center">
                    <div
                      class="mini-stat-icon avatar-sm rounded-circle bg-primary"
                    >
                      <span class="avatar-title">
                        <i class="bx bx-calendar-event bx-sm"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title mb-4">Time Sheet</h4>
              <div class="row text-center">
                <div class="col-4">
                  <h5 class="mb-0"></h5>
                  <p class="text-muted text-truncate"></p>
                </div>
                <div class="col-4">
                  <h5 class="mb-0">Working Hours</h5>
                  <p class="text-muted text-truncate">Last Week</p>
                </div>
                <div class="col-4">
                  <h5 class="mb-0"></h5>
                  <p class="text-muted text-truncate"></p>
                </div>
              </div>
              <div class="chartjs-chart">
                <canvas
                  baseChart
                  [datasets]="lineBarChart.datasets"
                  chartType="bar"
                  [options]="lineBarChart.options"
                  height="300"
                  [labels]="lineBarChart.labels"
                >
                </canvas>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #content role="document" let-modal>
  <div class="modal-header text-center">
    <h5 class="modal-title text-center" id="exampleModalLabel">Apply Leave</h5>
    <button
      type="button"
      class="btn-close"
      data-bs-dismiss="modal"
      aria-label="Close"
      id="close-modal"
      (click)="modal.dismiss('Cross click')"
    ></button>
  </div>
  <form action="">
    <div class="modal-body">
      <div class="mb-3">
        <label for="example-date-input" class="col-md-2 col-form-label"
          >Date</label
        >
        <input
          class="form-control"
          type="date"
          value="2019-08-19"
          id="example-date-input"
        />
      </div>

      <div class="mb-3">
        <label for="payment-field" class="form-label">Type</label>
        <select
          class="form-control"
          data-trigger
          name="payment-method"
          id="payment-field"
        >
          <option value="">Type</option>
          <option value="Casual Leave">Casual Leave</option>
          <option value="Sick Leave">Sick Leave</option>
        </select>
      </div>

      <div class="">
        <label for="customername-field" class="form-label">Remarks</label>
        <input
          type="text"
          id="customername-field"
          class="form-control"
          placeholder="Remarks"
          required
        />
      </div>
    </div>

    <div class="text-center mb-3">
      <button type="button" class="btn btn-primary">Request Leave</button>
    </div>
  </form>
</ng-template>
