<footer class="footer ">
    <div class="container-fluid ">
        <div class="row">
            <div class="col-sm-6">
                {{year}} © OrderStack.
            </div>
            <div class="col-sm-6">
                <div class="text-sm-end d-none d-sm-block">
                    <img
                    src="../../../assets/images/org-logos_orderstack.png"
                    alt=""
                    height="27" />
                </div>
            </div>
        </div>
    </div>
</footer>