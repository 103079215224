import { Component } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AuthService } from "src/app/Common/authentication/auth.service/auth.service";
import Swal from "sweetalert2";
import { DatePipe } from "@angular/common";
import { filterData, statusModels } from "./model";
@Component({
  selector: "app-pro-manager-leave-request",
  templateUrl: "./pro-manager-leave-request.component.html",
  styleUrls: ["./pro-manager-leave-request.component.scss"],
})
export class ProManagerLeaveRequestComponent {
  tabledata: any;
  data2: any;
  allUser: any;
  emptyData = false;
  loader:boolean;
  hidePreviousButton: boolean;
  hideNextButton: boolean;
  pageIndex = 1;
  pageSize = 10;

  ngOnInit(): void {
    // this.authService.getProManLeaveRequestView().subscribe((data: any) => {
    //   this.tabledata = data;
    //   console.log(this.tabledata);
    //   if (data.length == 0) {
    //     // Swal.fire("No more Request", "", "warning");
    //     this.emptyData = true;
    //   }
    // });
    this.TableShow(this.pageIndex,this.pageSize);

    this.authService.getProjectName().subscribe((data: any) => {
      console.log(data);
      this.data2 = data;
    });

    this.authService.getAllUser().subscribe((data: any) => {
      this.allUser = data;
      console.log(this.allUser, "all users");
    });
  }

  constructor(
    private authService: AuthService,
    private modalService: NgbModal,
    private datePipe: DatePipe
  ) {}
  viewDetails(timeSheetId) {}

  statusmodel: statusModels;
  leaveRequestId: string;

  approved(leaveRequestId: string, userid: string) {
    this.leaveRequestId = leaveRequestId;

    this.statusmodel = {
      IsStatus: "APPROVED",
      LeaveRequestId: this.leaveRequestId,
      UserId: userid,
    };
    console.log(this.statusmodel);
    this.authService
      .getLeaveRequestApprovalCheck(this.statusmodel)
      .subscribe((data: any) => {
        if (data.isSuccess == false) {
          //start
          const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
              confirmButton: "btn btn-success",
              cancelButton: "btn btn-danger ms-2",
            },
            buttonsStyling: false,
          });

          swalWithBootstrapButtons
            .fire({
              title: " Leave Request - Loss of Pay",
              text: "Employee have no more paid leave, this requested time off will be treated as LOP",
              icon: "warning",
              confirmButtonText: "Yes, Approve it!",
              cancelButtonText: "No, Cancel!",
              showCancelButton: true,
            })
            .then((result) => {
              if (result.value) {
                this.leaveRequestId = leaveRequestId;

                this.statusmodel = {
                  IsStatus: "APPROVED",
                  LeaveRequestId: this.leaveRequestId,
                  UserId: userid,
                };
                console.log(this.statusmodel);
                this.authService
                  .getLeaveApproval(this.statusmodel)
                  .subscribe((data: any) => {
                    if (
                      data.message ==
                      "Already approved timesheet for current date"
                    ) {
                      Swal.fire({
                        title: "Already approved timesheet for current date",
                        text: " ",
                        icon: "warning",
                        cancelButtonColor: "#f46a6a",
                      });
                    } else if (data.message == "Leave Approved") {
                      console.log(data);
                      // this.LeaveRequestTable();
                      this.TableShow(this.pageIndex,this.pageSize);
                      Swal.fire({
                        position: "center",
                        icon: "success",
                        title: "Approved",
                        showConfirmButton: false,
                        timer: 1300,
                      });
                    }
                  });
              }
            });

          //end
        } else if (data.isSuccess == true) {
          this.authService
            .getLeaveApproval(this.statusmodel)
            .subscribe((data: any) => {
              if (
                data.message == "Already approved timesheet for current date"
              ) {
                Swal.fire({
                  title: "Already approved timesheet for current date",
                  text: " ",
                  icon: "warning",
                  cancelButtonColor: "#f46a6a",
                });
              } else if (data.message == "Leave Approved") {
                console.log(data);
                // this.LeaveRequestTable();
                this.TableShow(this.pageIndex,this.pageSize);
                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: "Approved",
                  showConfirmButton: false,
                  timer: 1300,
                });
              }
            });
        }
      });
  }
  popupViewDetails(viewDetailsContent) {}

  decline(leaveRequestId: string, userid: string) {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger ms-2",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        confirmButtonText: "Yes, reject it!",
        cancelButtonText: "No, cancel!",
        showCancelButton: true,
      })
      .then((result) => {
        if (result.value) {
          this.leaveRequestId = leaveRequestId;

          this.statusmodel = {
            IsStatus: "DECLINED",
            LeaveRequestId: this.leaveRequestId,
            UserId: userid,
          };
          console.log(this.statusmodel);
          this.authService
            .getLeaveApproval(this.statusmodel)
            .subscribe((data: any) => {
              console.log(data);
              // this.LeaveRequestTable();
              if(data.message=='Leave Declined'){
                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: "Rejected",
                  showConfirmButton: false,
                  timer: 1300,
                });
              }
              this.TableShow(this.pageIndex,this.pageSize);
            });
        }
      });
  }
  typeOnFilter = "";
  dateFrom = "";
  dateTo = "";
  userId = "";
  filterView = false;
  filterdata: filterData;
  filterTable: any;
  Filter() {
    this.loader=true;
    this.pageIndex=1;
    this.filterView = true;

    this.filterdata = {
      UserId: this.userId,
      From: this.dateFrom,
      To: this.dateTo,
      Type: this.typeOnFilter,
      PageIndex:this.pageIndex,
      PageSize:this.pageSize
    };
    console.log(this.filterdata);

    this.authService
      .getRequestLeaveApprovalViewFilter(this.filterdata)
      .subscribe((data: any) => {
        this.loader=false;
        this.filterTable = data;
        console.log(data);
        if (data == "") {
          console.log("if condition");
          Swal.fire({
            title: "No Data Available",
            text: "",
            icon: "warning",
            cancelButtonColor: "#f46a6a",
          });
          this.emptyData = true;
          this.hideNextButton = true;
          this.hidePreviousButton = true;
        } else if (data != "") {
          this.emptyData = false;
          this.hideNextButton = false;
          this.hidePreviousButton = false;
        }
        if (this.pageIndex == 1  && data != "") {
          this.hidePreviousButton = true;
          this.hideNextButton = false;
        } else if (this.pageIndex != 1 && data == "") {
          this.hideNextButton = true;
          this.hidePreviousButton = false;
        }
      });
  }

  TableShow(pageIndex, pageSize) {
    this.loader=true;
    this.filterView = true;
    this.emptyData=false

    this.filterdata = {
      UserId: this.userId,
      From: this.dateFrom,
      To: this.dateTo,
      Type: this.typeOnFilter,
      PageIndex:pageIndex,
      PageSize:pageSize
    };
    console.log(this.filterdata);

    this.authService
      .getRequestLeaveApprovalViewFilter(this.filterdata)
      .subscribe((data: any) => {
        this.filterTable = data;
        this.loader = false;
        if (data == "") {
          console.log("if condition");
          this.emptyData = true;
          this.hideNextButton = true;
          this.hidePreviousButton = true;
        } else if (data != "") {
          this.emptyData = false;
          this.hideNextButton = false;
          this.hidePreviousButton = false;
        }
        if (this.pageIndex == 1  && data != "") {
          this.hidePreviousButton = true;
          this.hideNextButton = false;
        } else if (this.pageIndex != 1 && data == "") {
          this.hideNextButton = true;
          this.hidePreviousButton = false;
        }
      });
  }

  next() {
    this.pageIndex = this.pageIndex + 1;
    this.TableShow(this.pageIndex, this.pageSize);
  }

  previous() {
    this.pageIndex = this.pageIndex - 1;
    this.TableShow(this.pageIndex, this.pageSize);
  }

  ClearFilter() {
    this.dateFrom = "";
    this.dateTo = "";
    this.userId = "";
    this.typeOnFilter = "";
    this.emptyData = false;
    this.Filter();
  }

  // LeaveRequestTable(){
  //   this.authService.getProManLeaveRequestView().subscribe((data: any) => {
  //     this.filterTable = data;
  //     console.log(this.tabledata);
  //     if (data.length == 0) {
  //       // Swal.fire("No more Request", "", "warning");
  //       this.emptyData = true;
  //     }
  //   });
  // }

  page = 1;
  count = 0;
  tableSize = 10;
  tableSizes = [3, 6, 9, 12];

  onTableDataChange(event) {
    this.page = event;
    // this.TimesheetTable();
  }
}
