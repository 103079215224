import { Component ,OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { ThrottlingConstants } from '@azure/msal-common';
import { AuthService } from 'src/app/Common/authentication/auth.service/auth.service';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {
constructor(private msalService:MsalService,private router:Router,private auth:AuthService){}
ngOnInit(){

this.auth.getUser().subscribe((data: any) => {
  // console.log(data);
  if (data) {

    console.log("sgggggggggggggggggggggggggggggggggggggggggggg");
    console.log(data);
  }
});





}
  logout() {
    this.msalService.logout();
    this.router.navigate(['/']);
  }
  sideNavToggle(){

  }
}
